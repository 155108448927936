import React from 'react';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import Input from '../input';
import InputError from '../inputError';
import FormItem from '../FormItem';

const GeoInput = (props: IFormFieldProps<undefined>): JSX.Element => {
	const { error, name, value, setField, touched = false } = props;

	return (
		<FormItem className="geo-input">
			<div className="geo-input__wrapper">
				<label className="geo-input__latitude">
					<span>Широта</span>
					<Input
						name="lat"
						value={value?.lat}
						onChange={(e) => {
							setField(`${name}.lat`, e.target.value, true);
						}}
						error={error?.lat}
						touched={true}
						type="number"
						setField={setField}
						className="geo-input__field"
					/>
				</label>
				<label className="geo-input__longitude">
					<span>Довгота</span>
					<Input
						name="lng"
						value={value?.lng}
						onChange={(e) => {
							setField(`${name}.lng`, e.target.value, true);
						}}
						error={error?.lng}
						touched={true}
						type="number"
						setField={setField}
						className="geo-input__field"
					/>
				</label>
			</div>
			<InputError
				condition={typeof error === 'string' && touched}
				error={error}
			/>
		</FormItem>
	);
};

export default GeoInput;
