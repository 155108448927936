import React from 'react';
import Page from '../categoryCountyMixin/page';

const CategoryPage = (): JSX.Element => {
	return (
		<Page
			deleteText="Видалити категорію"
			headerTitle="Додати категорію"
			editText="Редагувати категорію"
			name="Категорія"
			routeName="category"
			createText="Зберегти"
		/>
	);
};

export default CategoryPage;
