import {TTableEl} from '../components/tableInput';

export const keyValueToTable = (data: any | undefined | null): TTableEl[] | null => {
	if (!data) {
		return null;
	}
	let initArray: { key: any; value: any; id: any; }[] | null = []
	 data.map((el: { key: any; value: any; id: any; }) => {
		initArray?.push({
			key: el?.key,
			value: el?.value,
			id: el?.id
		})
	})
	return initArray

};
