import React, { useEffect, useState } from 'react';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import EyeIcon from '../../public/svgs/EyeIcon';
import InputLabel from '../inputLabel';
import InputError from '../inputError';
import FormItem from '../FormItem';

const Input = (props: IFormFieldProps<undefined>): JSX.Element => {
	const {
		keyOfField,
		label,
		labelVariant,
		error,
		name,
		value,
		type: initialType,
		onBlur,
		onChange,
		placeholder = '',
		className = '',
		touched = false,
	} = props;

	const [type, setType] = useState(initialType);
	const [isShowPassword, setIsShowPassword] = useState(false);
	const [isError, setIsError] = useState('');

	const addInputErrorClass = () => {
		if (error && touched) {
			setIsError('input--error');
		} else {
			setIsError('');
		}
	};

	useEffect(() => {
		addInputErrorClass();
	}, [addInputErrorClass]);

	return (
		(
			<FormItem key={keyOfField}>
				{label && <InputLabel labelVariant={labelVariant} label={label} />}
				{type !== 'textarea' && (
					<input
						className={
							initialType === 'password'
								? `input ${isError} input--password ${className}`
								: `input ${isError} ${className}`
						}
						key={name}
						id={name}
						type={type}
						name={name}
						value={value?.toString()}
						onBlur={onBlur}
						onChange={(event) => {
							onChange(event);
						}}
						placeholder={placeholder}
					/>
				)}

				{initialType === 'password' && (
					<EyeIcon
						show={isShowPassword}
						className="show-password"
						onClick={() => {
							setType((state) => (state === 'password' ? 'text' : 'password'));
							isShowPassword
								? setIsShowPassword(false)
								: setIsShowPassword(true);
						}}
					/>
				)}

				{type === 'textarea' && (
					<textarea
						className={`textarea ${className}`}
						key={name}
						id={name}
						name={name}
						value={value?.toString()}
						onBlur={onBlur}
						onChange={(event) => {
							onChange(event);
						}}
						placeholder={placeholder}
					/>
				)}
				<InputError
					error={error}
					condition={!!(error && touched)}
					className={`
					${className === 'table-input__field' ? 'input-error--table-field' : ''}
					${className === 'geo-input__field' ? 'input-error--geo-field' : ''}
				`}
				/>
			</FormItem>
		) || className === 'geo-input__field'
	);
};

export default Input;
