import { ApiService } from '../services/ApiService';
import { TPagination } from '../interfaces/TPagination';
import { createQueryParams } from '../helpers/createQueryParams';
import { HttpMethods } from '../enums/HttpMethods';
import { TCreateNews, TEditNews } from './types';
import { addObjectToFormData } from '../helpers/formData/FormData';
import { getLanguage, getSiteId } from '../store/common/selectors.common';
import store from '../store/store.main';
import { workspace } from '../consts/workspace';

export class NewsRepository {
	private readonly apiService: ApiService;
	private readonly routeName: string = '/news';
	workspace: string;
	constructor() {
		this.apiService = new ApiService();
		this.workspace =  workspace[getSiteId(store.getState()) - 1]
	}

	async getAll(language='ua', pagination: TPagination) {
		const response = await this.apiService.request(
			`/admin/article/all` 
			+ createQueryParams(pagination as { [key: string]: any }),
			{
				method: HttpMethods.GET,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${language}`,
				}
			}
		);

		return response.data;
	}

	async edit(id: string, data: TEditNews) {
		if (typeof data.image === 'string') {
			delete data.image;
		}

		const language = getLanguage(store.getState());
		const formData = addObjectToFormData(data);

		await this.apiService.request(
			`/admin/article/translation/${id}/update`,
			{
				method: HttpMethods.POST,
				data: formData,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${language}`,
				}
			}
		);
	}

	async delete(id: string) {
		await this.apiService.request(`/admin/article/${id}/delete`, {
			method: HttpMethods.DELETE,
			headers: {
				'X-Workspace': `${this.workspace}`,
			}
		});
	}

	async create(data: TCreateNews) {
		const formData = addObjectToFormData(data);
		const language = getLanguage(store.getState());
		const q = await this.apiService.request('/admin/article/create', {
			method: HttpMethods.POST,
			data: formData,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});
		return q
	}

	async createTranslation(language:string, id : number | string, data: TCreateNews) {
		const formData = addObjectToFormData(data);
		await this.apiService.request(`/admin/article/${id}/translation/create`, {
			method: HttpMethods.POST,
			data: formData,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});
	}

	async pin(id: string) {
		await this.apiService.request(`/admin/article/${id}/toggle-pin`, {
			method: HttpMethods.POST,
			headers: {
				'X-Workspace': `${this.workspace}`,
			}
		});
	}

	async getById(id: string) {
		const response = await this.apiService.request(
			`/admin/article/translation/${id}/get`,
			{
				method: HttpMethods.GET,
				headers: {
					'X-Workspace': `${this.workspace}`,
					
				}
			}
		);

		return response.data;
	}
}
