import React, { useEffect } from 'react';
import { PinAngle, PinFill, Trash } from 'react-bootstrap-icons';
import { newsService } from '../news.service';
import EditableImage from '../../components/editableImage';
import { useNavigate } from 'react-router-dom';
import { createPathToEditRoute } from '../../helpers/createRoute';
import { NEWS_ROUTE } from '../../consts/routes';
import ShadowWrapper from '../../components/ShadowWrapper';
import ButtonLink from '../../components/ButtonLink';
import Title from '../../components/Title';
import { ArticleTranslation } from '../types';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../store/common/selectors.common';

type TNewsPreview = {
	image: {
		large:{
			path: string;
		}
	};
	id: string;
	title: string;
	content: string;
	idPinned: string;
	is_pinned: string;
	onDeleteItem: (id: string) => void;
	onEditItem: () => void;
	onIdPinned: (id: string) => void;
	articleId: string;
	article: {
		createdAt: string;
		updatedAt: string;
		id: string;
		isEnabled: string;
		isPinned: string;
		priority: string;
		translations: ArticleTranslation[];
	}
};

const NewsPreview = (props: TNewsPreview): JSX.Element => {
	const {
		image,
		id,
		onDeleteItem,
		onEditItem,
		onIdPinned,
		content,
		title,
		idPinned,
		is_pinned,
		articleId,
		article
	} = props;
	const navigate = useNavigate();
	useEffect(() => {
		if (is_pinned === "true") {
			onIdPinned(articleId);
		}
	}, []);
	const language = useSelector(getLanguage);
	const baseUrl = `${process.env.REACT_APP_API_URL}/storage/`
	let checkLng = (lng:string) => article.translations.some(el => el.language.code === lng)
	let ruId =  article.translations.find(el =>  el.language.code === 'ru')
	async function changeImage (language: string, image: File) {
		let data
		let idForImgRequest:any
		if(language === 'ua') {
			idForImgRequest = id
			data = await newsService.getById(id)
		}
		if(language === 'ru' && checkLng('ru')) {
			let ruTranslation =  article.translations.find(el =>  el.language.code === 'ru')
			idForImgRequest = ruTranslation?.id
			data = await newsService.getById(idForImgRequest as any as string)
		}
		if(language === 'en' && checkLng('en')) {
			let enTranslation =  article.translations.find(el =>  el.language.code === 'en')
			idForImgRequest = enTranslation?.id
			data = await newsService.getById(idForImgRequest as any as string)
		}
			const inputDate = new Date(data.article.updatedAt);
			let day = inputDate.getDate();
			let month = inputDate.getMonth() + 1; // Месяцы в объекте Date нумеруются с 0
			if (month < 10) {
				month = "0" + (inputDate.getMonth() + 1) as any as number
			}
			if (day < 10) {
				day = "0" + (inputDate.getMonth() + 1) as any as number
			}
			const year = inputDate.getFullYear();

			const formattedDate = `${day}/${month}/${year}`
			newsService.edit(idForImgRequest, {
				title: data.title,
				body: data.body,
				image: image,
				slug: data.slug,
				isEnabled: '1',
				isPinned: data.article.isPinned === 'true' ? '1' : '0',
				priority: data.article.priority,
				updatedAt: formattedDate,
			}).then(() => {
				onEditItem();
			});
	}
	return (
		<li className="news-preview">
			<ShadowWrapper variant="content" className="news-preview__body">
				<EditableImage
					src={`${baseUrl}${image?.large?.path}`}
					onChange={async (image: File) => {
						if(language === 'ua') {
							changeImage('ua', image)
						}
						if(language === 'ru' && checkLng('ru') ) {
							changeImage('ru', image)
						}

						if(language === 'en' && checkLng('en') ) {
							changeImage('en', image)
						}
					}}
					alt={title}
					className="news-preview__image"
				/>
				<div className="news-preview__info">
					<Title type="h4" size="middle" className="news-preview__title">
						{title}
					</Title>
					<div
						className="Container news-preview__content"
						dangerouslySetInnerHTML={{ __html: content }}
					/>
					<div className="news-preview__control">
						<ButtonLink
							variant="small"
							className="news-preview__button-edit"
							onClick={() => {
								navigate(createPathToEditRoute(id, NEWS_ROUTE));
							}}
						>
							Редагувати
						</ButtonLink>
						<button
							type="button"
							className="news-preview__button-trash"
							onClick={() => {
								newsService.delete(articleId).then(() => onDeleteItem(articleId));
							}}
						>
							<Trash color="red" />
						</button>
						<button
							type="button"
							className="news-preview__button-pin"
							onClick={() => {
								newsService.pin(articleId);
								onIdPinned(articleId);
							}}
						>
							{idPinned === articleId ? <PinFill fill="black" /> : <PinAngle />}
						</button>
					</div>
				</div>
			</ShadowWrapper>
		</li>
	);
};

export default NewsPreview;
