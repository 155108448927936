/* eslint-disable indent */
import { Repository } from './repository';
import {
	TMixinCategoryCountry,
	TCreateMixinCountryCategory,
	TEditMixinCountryCategory,
} from './types';
import { setToast } from '../helpers/setToast';
import {
	CREATE_ERROR_TEXT,
	DELETE_ERROR_TEXT,
	EDIT_ERROR_TEXT,
	FETCH_ERROR_TEXT,
} from '../consts/errorMessages';
import { TPagination, TPaginationResponse } from '../interfaces/TPagination';

export class Service {
	repository: Repository;
	readableEntityName: string;
	constructor(routeName: string) {
		this.readableEntityName = this.getEntityName(routeName);
		this.repository = new Repository(routeName);
	}

	private getEntityName(routeName: string) {
		switch (routeName) {
			case 'categories':
				return 'Категорія';
			case 'countries':
				return 'Країна';
			default:
				return '';
		}
	}

	async create(language: string, data: TCreateMixinCountryCategory, cb:any) {
		const q = await this.repository
			.create(language, data)
			.then((data) => {
				cb(data)
				setToast(this.readableEntityName + ' успішно створена', 'info');
			})
			.catch((error) => {
				setToast(error.message.name[0] || error.message || CREATE_ERROR_TEXT , 'error');
				throw error;
			});
		return q
	}

	async getAll(language: string, pagination: TPagination, project = false) {
		const response = await this.repository.getAll(language, pagination, project).catch((error) => {
			setToast(error.message || FETCH_ERROR_TEXT, 'info');
			throw error;
		});

		return {
			entities: response.entities as TMixinCategoryCountry[],
			pagination: response.pagination as TPaginationResponse,
		};
	}

	async edit(id: string, data: TEditMixinCountryCategory, isNotification = false) {
		await this.repository
			.edit(id, data)
			.then(() => {
				if (isNotification) {
					setToast(this.readableEntityName + ' успішно оновлена', 'info');
				}
			})
			.catch((error) => {
				setToast(error.message || EDIT_ERROR_TEXT, 'error');
				throw error;
			});
	}


	async createTranslation(language: string, id: string, data: TCreateMixinCountryCategory) {
		await this.repository
			.createTranslation(language,id, data)
			.then(() => {
				setToast(this.readableEntityName + ' успішно створена переклад', 'info');
			})
			.catch((error) => {
				setToast(error.message || CREATE_ERROR_TEXT, 'error');
				throw error;
			});
	}

	async getOne(id: string) {
		const response = await this.repository
			.getOne(id)
			.catch((error) => {
				setToast(error.message || EDIT_ERROR_TEXT, 'error');
				throw error;
			});

		return response
	}

	async delete(id: string) {
		await this.repository
			.delete(id)
			.then(() => {
				setToast(this.readableEntityName + ' успішно видалена', 'info');
			})
			.catch((error) => {
				setToast(error.message || DELETE_ERROR_TEXT, 'error');
				throw error;
			});
	}
}

export const categoryService = new Service('categories');
