import React, { useEffect, useMemo, useState } from 'react';
import { TMixinCategoryCountry } from '../types';
import { IListItem } from '../../interfaces/IListItem';
import Preview from './preview';
import EditForm from './editForm';
import { Service } from '../service';
import ShadowWrapper from '../../components/ShadowWrapper';
import Loader from '../../components/loader';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../store/common/selectors.common';

type TAdditionalProps = {
	deleteText: string;
	routeName: string;
	editText: string;
}

type TCategoryListItemProps = TMixinCategoryCountry & IListItem<TMixinCategoryCountry> & TAdditionalProps;

const ListItem = (props: TCategoryListItemProps): JSX.Element => {
	const {
		name_en,
		name_ru,
		name_ua,
		onDeleteItem,
		deleteText,
		onEditItem,
		id,
		routeName,
		editText,
		name
	} = props;

	let serviceID:any
	if (routeName === 'country') {
		serviceID = props['country']?.id
	} else {
		serviceID = props['category']?.id
	}

	const [editMode, setEditMode] = useState(false);
	const service = useMemo(() => {
		return new Service(routeName);
	}, [routeName]);

	const handleCloseEditForm = () => {
		setEditMode(false);
	};
	const [serviceData, setServiceData] = useState<any | {}>({})
	const language = useSelector(getLanguage);
	const [serviceDataRu, setServiceDataRu] = useState<any | {}>({})
	const [serviceDataEn, setServiceDataEn] = useState<any | {}>({})
	const [loading, setLoading] = useState(false)

	const promise = useMemo(async () => {
		if(editMode) {
			setLoading(true)
			let dataUa
			let data
			if(language === 'ua') {
				dataUa = await service.getOne(id)
				setServiceData(dataUa)
			} else {
				data = await service.getOne(id)
				let uaInfo = data[routeName]?.translations.find((el: { language: { code: string; }; }) => el.language.code === 'ua')
				dataUa = await service.getOne(uaInfo?.id)
				setServiceData(dataUa)
			}

				
				if(dataUa[routeName]?.translations?.length > 1) {
					
					let ruInfo = dataUa[routeName]?.translations.find((el: { language: { code: string; }; }) => el.language.code === 'ru')
					let enInfo = dataUa[routeName]?.translations.find((el: { language: { code: string; }; }) => el.language.code === 'en')	

					if(ruInfo?.id) {
						const dataRu = await service.getOne(ruInfo.id)
						setServiceDataRu(dataRu)
					}

					if(enInfo?.id) {
						const dataEn = await service.getOne(enInfo.id)
						setServiceDataEn(dataEn)
					}
					setLoading(false)

				} else {
					setLoading(false)
				}
			}
	}, [editMode]);

	return (
		<>
			<li className={`category-preview ${routeName}`}>
				<ShadowWrapper
					variant="content"
					className="category-preview__body"
				>
					<Preview
						id={id}
						name={name}
						editText={editText}
						setEditMode={setEditMode}
						onDelete={() => {
							setEditMode(false);
							onDeleteItem(serviceID);
						}}
						service={service}
						serviceID={serviceID}
					/>
				</ShadowWrapper>
			</li>
			{editMode && !loading ? (
				<EditForm
					service={service}
					deleteText={deleteText}
					id={id}
					onEdit={() => {
						setEditMode(false);
						onEditItem();
					} }
					onDelete={() => {
						setEditMode(false);
						onDeleteItem(serviceData[routeName]?.id);
					} }
					onClose={() => handleCloseEditForm()}
					name_en={serviceDataEn?.name}
					name_ua={serviceData?.name}
					name_ru={serviceDataRu?.name}
					editMode={editMode}
					loading={promise}
					isLoading={loading}
					idRu={serviceDataRu?.id}
					idEn={serviceDataEn?.id}
					entityId={serviceData[routeName]?.id}		
					priority={serviceData[routeName]?.priority}		
					/>
			) : (<Loader condition={editMode && loading} center ></Loader>)}
		</>
	);
};

export default ListItem;
