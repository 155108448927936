import UHP from '../img/otherLogoCompany/UHP.svg';
import UAEP from '../img/otherLogoCompany/UAEP.svg';
import ICER from '../img/otherLogoCompany/ICER.svg';
import HTP from '../img/otherLogoCompany/HTP.svg';

const companies = [
	{
		path: '/',
		image: UHP,
		type: 'ПРАТ',
		name: '«УКРГІДРОПРОЕКТ»',
		id: 1,
	},
	{
		path: '/',
		image: HTP,
		type: 'ТОВ',
		name: '«ГІДРОТЕХПРОЕКТ»',
		id: 3,
	},
	{
		path: '/',
		image: UAEP,
		type: 'ТОВ',
		name: '«УКРАТОМЕНЕРГОПРОЕКТ»',
		id: 2,
	},
	{
		path: '/',
		image: ICER,
		type: 'ТОВ',
		name: '«МІЖВІДОМЧИЙ ЦЕНТР ІНЖЕНЕРНИХ ДОСЛІДЖЕНЬ»',
		id: 4,
	},
];

export default companies;
