import React, { useMemo } from 'react';
import PageContainer from '../components/PageContainer';
import PageHeader from '../components/pageHeader';
import CreateForm from './components/createForm';
import ListWithPagination from '../components/listWithPagination';
import ListItem from './components/listItem';
import { Service } from './service';
import { TPaginationResponse } from '../interfaces/TPagination';

type TPageMixinProps = {
	headerTitle: string;
	routeName: string;
	deleteText: string;
	createText: string;
	editText: string;
	name: string;
};

const Page = (props: TPageMixinProps): JSX.Element => {
	const { headerTitle, routeName, deleteText, createText, editText, name } =
		props;

	const service = useMemo(() => {
		return new Service(routeName);
	}, [routeName]);

	return (
		<PageContainer>
			<PageHeader
				additionalProps={{ createText, name }}
				FormElement={CreateForm}
				title={headerTitle}
				service={service}
			/>
			<ListWithPagination
				elementProps={{
					deleteText,
					routeName,
					editText,
				}}
				Element={ListItem}
				endpoint={service.getAll.bind(service) as unknown as any}
				type="row"
				title=""
				categoryCountry
			/>
		</PageContainer>
	);
};

export default Page;
