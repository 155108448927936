import React from 'react';

type propsInputLabel = {
	label?: string,
	className?: string,
	labelVariant?: string,
}

const InputLabel = ({ label, className, labelVariant }: propsInputLabel) => {
	return (
		<label className={`label label--${labelVariant} ${className}`}>
			{label}
		</label>
	);
};

export default InputLabel;
