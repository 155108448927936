import React, { useState } from 'react';
import Yup from '../../entities/Yup';
import { useFormik } from 'formik';
import { defaultFormikConfig } from '../../components/form/config';
import createInput from '../../helpers/createInput';
import Button from '../../components/button';
import { TEditVacancy } from '../types';
import { vacancyService } from '../vacancy.service';
import { useNavigate } from 'react-router-dom';
import { createPathToListRoute } from '../../helpers/createRoute';
import { VACANCY_ROUTE } from '../../consts/routes';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../store/common/selectors.common';

const validationSchema = Yup.object().shape({
	title: Yup.string()
		.required('Вкажіть назву вакансії')
		.max(200, 'Макс. кількість 200 символи'),
	content: Yup.string()
		// .required('Вкажіть опис вакансії')
		.max(15000, 'Макс. кількість 15000 символи'),
	slug: Yup.string().matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link').required('Введіть slug'),

});

type TEditVacancyFormProps = {
	title: string;
	content: string;
	id: number | undefined;
	onSubmit: () => void;
	slug?: string | null;
	vacancyId: string | undefined;
};

const EditVacancyForm = (props: TEditVacancyFormProps): JSX.Element => {
	const { title, content, onSubmit, id, slug, vacancyId } = props;
	const navigate = useNavigate();
	const language = useSelector(getLanguage);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const form = useFormik<TEditVacancy>({
		...defaultFormikConfig,
		validationSchema,
		initialValues: {
			title,
			content,
			slug,
			body: undefined,
			isEnabled: 0,
			priority: 0
		},
		onSubmit: (values, { setErrors, resetForm }) => {
			setIsDisabled(true);
			if(id) {
				vacancyService
				.edit(id, {
					body: values.content,
					title: values.title,
					isEnabled: 1,
					priority: 0,
					slug: values.slug,
				})
				.then(() => {
					resetForm();
					navigate(createPathToListRoute(VACANCY_ROUTE));
				})
				.catch((error) => {
					setErrors({
						...error.fields,
					});
				})
				.finally(() => {
					setIsDisabled(false);
				});
			} else {
				vacancyService
				.createTranslation(language, vacancyId as string, {
					body: values.content,
					title: values.title,
					isEnabled: 1,
					priority: 0,
					slug: values.slug,
				})
				.then(() => {
					resetForm();
					navigate(createPathToListRoute(VACANCY_ROUTE));
				})
				.catch((error) => {
					setErrors({
						...error.fields,
					});
				})
				.finally(() => {
					setIsDisabled(false);
				});
			}
			
		},
	});

	return (
		<div>
			{createInput(form, {
				key: 'title',
				label: 'Назва вакансії*',
				labelVariant: 'bold',
				type: 'text,',
			})}
			{createInput(form, {
				key: 'content',
				label: 'Текст вакансії*',
				labelVariant: 'bold',
				type: 'editor',
			})}
			{createInput(form, {
				key: 'slug',
				label: 'slug*',
				labelVariant: 'bold',
				type: 'text,',
			})}
			<div className="vacancy-form__footer">
				<Button isDisabled={isDisabled} onClick={form.submitForm}>
					Зберегти
				</Button>
			</div>
		</div>
	);
};

export default EditVacancyForm;
