import { VacancyRepository } from './vacancy.repository';
import { TCreateVacancy, TEditVacancy, TVacancy } from './types';
import { setToast } from '../helpers/setToast';
import {
	CREATE_ERROR_TEXT,
	DELETE_ERROR_TEXT,
	EDIT_ERROR_TEXT,
	FETCH_ERROR_TEXT,
} from '../consts/errorMessages';
import { TPagination, TPaginationResponse } from '../interfaces/TPagination';

export class VacancyService {
	private readonly repository: VacancyRepository;
	constructor() {
		this.repository = new VacancyRepository();
	}

	async create(language: string,data: TCreateVacancy, cb:any) {
		const q = await this.repository
			.create(language, data)
			.then((data) => {
				cb(data)
				setToast('Вакансія успішно створена', 'info');
			})
			.catch((error) => {
				if(error?.fields) {
					Object.keys(error?.fields).map(el => {
						if(el === 'images.0.image') {
							'images.0.image'
							setToast(`${error?.message[el]} - головне фото проекту`, 'error');
						} else {
							setToast(`${error?.message[el]} - ${el}`, 'error');
						}
					})
				}
				setToast(error.message || CREATE_ERROR_TEXT, 'error');
				throw error;
			});
		return q
	}

	async createTranslation(language: string, id: string, data: TCreateVacancy) {
		const q = await this.repository
			.createTranslation(language,id,data)
			.then(() => {
				setToast('Вакансія успішно створена', 'info');
			})
			.catch((error) => {
				if(error?.fields) {
					Object.keys(error?.fields).map(el => {
						if(el === 'images.0.image') {
							'images.0.image'
							setToast(`${error?.message[el]} - головне фото проекту`, 'error');
						} else {
							setToast(`${error?.message[el]} - ${el}`, 'error');
						}
					})
				}
				setToast(error.message || CREATE_ERROR_TEXT, 'error');
				throw error;
			});
		return q
	}

	async getAll(language = 'ua', pagination: TPagination) {
		const response = await this.repository.getAll(language, pagination).catch((error) => {
			setToast(error.message || FETCH_ERROR_TEXT, 'error');
			throw error;
		});

		return {
			pagination: response.pagination as TPaginationResponse,
			entities: response.vacancies as TVacancy[],
		};
	}

	async delete(id: string) {
		await this.repository
			.delete(id)
			.then(() => {
				setToast('Вакансія успішно видалена', 'info');
			})
			.catch((error) => {
				setToast(error.message || DELETE_ERROR_TEXT, 'error');
				throw error;
			});
	}

	async edit(id: number, data: TEditVacancy) {
		await this.repository
			.edit(id, data)
			.then(() => {
				setToast('Вакансія успішно відредагована', 'info');
			})
			.catch((error) => {
				if(error?.fields) {
					Object.keys(error?.fields).map(el => {
						if(el === 'images.0.image') {
							'images.0.image'
							setToast(`${error?.message[el]} - головне фото проекту`, 'error');
						} else {
							setToast(`${error?.message[el]} - ${el}`, 'error');
						}
					})
				}
				setToast(error.message || EDIT_ERROR_TEXT, 'error');
				throw error;
			});
	}

	async getById(id: string) {
		return this.repository.getById(id).catch((error) => {
			setToast(error.message, 'error');
			throw error;
		});
	}
}

export const vacancyService = new VacancyService();
