import React, { useCallback, useEffect, useState } from 'react';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import { Trash } from 'react-bootstrap-icons';
import { DateEntity } from '../../entities/Date';
import InputLabel from '../inputLabel';
import InputError from '../inputError';
import Input from '../input';
import { DragContainer } from '../dragAndDropElement';
import update from 'immutability-helper';
import ButtonLink from '../ButtonLink';

export type TTableEl = {
	key: string;
	value: string;
	id: string;
};

const TableInput = (props: IFormFieldProps<undefined>): JSX.Element => {
	const {
		label,
		labelVariant,
		options: initialOptions,
		error,
		name,
		value,
		setField,
		className = '',
		touched = false,
	} = props;

	const [items, setItems] = useState(value);

	useEffect(() => {
		setItems(value);
	}, [value]);

	const move = useCallback((dragIndex: number, hoverIndex: number) => {
		setItems((state: TTableEl[]) => {
			const result = update(state, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, state[dragIndex] as TTableEl],
				],
			});
			setTimeout(() => setField(name, result));
			return result;
		});
	}, []);

	const renderTableEl = useCallback(
		(id: string, el: JSX.Element, index: number) => {
			return (
				<DragContainer key={id} index={index} id={id} move={move}>
					<>{el}</>
				</DragContainer>
			);
		},
		[]
	);

	return (
		<div className={className + ' table-input'}>
			{label && <InputLabel labelVariant={labelVariant} label={label} />}
			{value.map((el: TTableEl, index: number) =>
				renderTableEl(
					el.id,
					<div key={el.id} className="table-input__body">
						<Input
							name={el.id}
							value={el.key}
							onChange={(e) => {
								setField(`${name}[${index}]key`, e.target.value, true);
							}}
							type="text"
							error={error?.[index]?.key}
							touched={error?.[index]?.key}
							setField={setField}
							className="table-input__field"
						/>
						<Input
							name={el.id + '1'}
							value={el.value}
							onChange={(e) => {
								setField(`${name}[${index}]value`, e.target.value, true);
							}}
							error={error?.[index]?.value}
							touched={error?.[index]?.value}
							type="text"
							setField={setField}
							className="table-input__field"
						/>
						<button
							onClick={() => {
								setField(
									name,
									value.filter((tableEl: TTableEl) => tableEl.id !== el.id),
									true
								);
							}}
							className="table-input__button-trash"
						>
							<Trash color="red" />
						</button>
					</div>,
					index
				)
			)}
			<ButtonLink
				variant="big"
				onClick={() => {
					setField(
						name,
						[
							...value,
							{
								key: '',
								value: '',
								id: new DateEntity().dateInMilliseconds.toString(),
							},
						],
						true
					);
				}}
				className="table-input__button-add"
			>
				Додати поле в таблицю
				<InputError
					className="table-input__button-add-error"
					condition={typeof error === 'string'}
					error={error}
				/>
			</ButtonLink>
		</div>
	);
};

export default TableInput;
