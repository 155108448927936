import {RELOAD_LIST_WITH_PAGINATION, RESET_DELETED_IMAGES, SET_DELETED_IMAGE, SET_FORM, SET_LANG, SET_SITE} from './types.common';
import {TLang} from '../../interfaces/TLang';

export const setLanguage = (language: TLang) => ({
	type: SET_LANG,
	language,
});

export const setForm= (form: any) => ({
	type: SET_FORM,
	form,
});


export const setSite = (id: number) => ({
	type: SET_SITE,
	id,
});

export const setSliderDeleteImage = (image: string) => ({
	type: SET_DELETED_IMAGE,
	image,
});

export const resetSliderDeleteImages = () => ({
	type: RESET_DELETED_IMAGES,
});

export const reloadListWithPagination = () => ({
	type: RELOAD_LIST_WITH_PAGINATION,
});
