import Yup from '../entities/Yup';

export const validationSchema = Yup.object().shape({
	name_en: Yup.string()
		.max(60, 'Макс. кількість 60 символи'),
	name_ru: Yup.string()
		.max(60, 'Макс. кількість 60 символи'),
	name_ua: Yup.string()
		.required('Заповніть поле')
		.max(60, 'Макс. кількість 60 символи'),
});
