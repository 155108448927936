/* eslint-disable indent */
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { vacancyService } from './vacancy.service';
import { TVacancy } from './types';
import { VACANCY_ROUTE } from '../consts/routes';
import { createPathToListRoute } from '../helpers/createRoute';
import PageContainer from '../components/PageContainer';
import Loader from '../components/loader';
import EditVacancyForm from './components/editVacancyForm';
import { useSelector } from 'react-redux';
import { getLanguage } from '../store/common/selectors.common';
import ShadowWrapper from '../components/ShadowWrapper';
import ButtonClose from '../components/ButtonClose';

const EditVacancyPage = () => {
	const { id } = useParams();
	const [vacancy, setVacancy] = useState<TVacancy | null>(null);
	const [vacancyRu, setVacancyRu] = useState<TVacancy | null>(null);
	const [vacancyEn, setVacancyEn] = useState<TVacancy | null>(null);
	const [reload, setReload] = useState(false);
	const navigate = useNavigate();
	const language = useSelector(getLanguage);

	const promise = useMemo(async () => {
		let dataUa = await vacancyService.getById(id!)
		setVacancy(dataUa)

		
		
		if(dataUa?.vacancy?.translations.length > 1) {
			let ruInfo = dataUa?.vacancy?.translations?.find((el: { language: { code: string; }; }) => el.language.code === 'ru')
			let enInfo = dataUa?.vacancy?.translations?.find((el: { language: { code: string; }; }) => el.language.code === 'en')	

			if(ruInfo?.id) {
				const dataRu = await vacancyService.getById(ruInfo.id)
				setVacancyRu(dataRu)
			}

			if(enInfo?.id) {
				const dataEn = await vacancyService.getById(enInfo.id)
				setVacancyEn(dataEn)
			}
		}
	}, [reload]);

	const getForm = () => {
		if (!vacancy) {
			return '';
		}
		let common = (vacancyTranslation: TVacancy) =>  {
			return {
				id: vacancyTranslation?.id,
				vacancyId: vacancy?.vacancy?.id,
				onSubmit: () => {
					setReload((state) => !state);
				},
			}

			

		};

		switch (language) {
			case 'ua':
				return (
					<EditVacancyForm
						key="ua"
						title={vacancy.title || ''}
						content={vacancy.body || ''}
						slug={vacancy.slug || ''}
						{...common(vacancy as TVacancy)}
					/>
				);
			case 'ru':
				return (
					<EditVacancyForm
						key="ru"
						title={vacancyRu?.title || ''}
						content={vacancyRu?.body || ''}
						slug={vacancyRu?.slug || ''}
						{...common(vacancyRu as TVacancy)}
					/>
				);
			case 'en':
				return (
					<EditVacancyForm
						key="en"
						title={vacancyEn?.title || ''}
						content={vacancyEn?.body || ''}
						slug={vacancyEn?.slug || ''}
						{...common(vacancyEn as TVacancy)}
					/>
				);
		}
	};

	return (
		<PageContainer className="vacancy-form">
			<ShadowWrapper variant="content" className="vacancy-form__body">
				<ButtonClose align="right" onClick={() => navigate(-1)} />
				<Loader condition={promise}>
					{vacancy ? <>{getForm()}</> : <span>Не знайдено</span>}
				</Loader>
			</ShadowWrapper>
		</PageContainer>
	);
};

export default EditVacancyPage;
