import {RootState} from '../store.main';

export const getSiteId = (store: RootState) => store.commonReducer.siteId;

export const getLanguage = (store: RootState) => store.commonReducer.language;

export const getDeletedSliderImages = (store: RootState) => store.commonReducer.sliderDeleteImages;

export const getReloadListWithPagination = (store: RootState) => store.commonReducer.reloadListWithPagination;

export const getForm = (store: RootState) => store.commonReducer.form;
