import { ApiService } from '../services/ApiService';
import { addObjectToFormData } from '../helpers/formData/FormData';
import { HttpMethods } from '../enums/HttpMethods';
import { TPagination } from '../interfaces/TPagination';
import { createQueryParams } from '../helpers/createQueryParams';
import { getLanguage, getSiteId } from '../store/common/selectors.common';
import store from '../store/store.main';
import { TDocumentCreate, TEditDocument } from './types';
import { workspace } from '../consts/workspace';

export class DocumentRepository {
	private readonly apiService: ApiService;
	private readonly routeName: string = '/documents';
	workspace: string;
	language: string;
	constructor() {
		this.apiService = new ApiService('ua');
		this.workspace =  workspace[getSiteId(store.getState()) - 1]
		this.language = getLanguage(store.getState())
	}
	
	async create(data: TDocumentCreate) {
		
		const siteId = getSiteId(store.getState());
		const formData = addObjectToFormData(data);

		const q = await this.apiService.request('/admin/document/create', {
			method: HttpMethods.POST,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `ua`,
			},
			data: formData,
		});
		return q
	}

	async createTranslation(language : string, id : string | number, data: TDocumentCreate) {
		let formData = new FormData();
		formData.set('file', data.file);
		formData.set('name', data.name);
		formData.set('isEnabled', '1');	
		await this.apiService.request(`/admin/document/${id}/translation/create`, {
			method: HttpMethods.POST,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			},
			data: formData,
		});
	}

	async delete(id: string) {
		await this.apiService.request(`/admin/document/${id}/delete`, {
			method: HttpMethods.DELETE,
			headers: {
				'X-Workspace': `${this.workspace}`,
			},
		});
	}

	async getById(id: string) {
		const response = await this.apiService.request(
			`/admin/document/translation/${id}/get`,
			{
				method: HttpMethods.GET,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${this.language}`,
				}
			}
		);

		return response.data;
	}

	async edit(language : string,id: string, data: TEditDocument) {
		let filteredData = {...data, isEnabled: '1'}
		const formData = addObjectToFormData(filteredData);
		await this.apiService.request(
			`admin/document/translation/${id}/update`,
			{
				method: HttpMethods.POST,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${language}`,
				},
				data: formData,
			}
		);
	}

	async getAll(language: string, pagination: TPagination) {
		const response = await this.apiService.request(
			'/admin/document/all'  + createQueryParams(pagination) + '&type=downloadable' ,
			{
				method: HttpMethods.GET,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${language}`,
				}
			}
		);
		const filteredResponse = response.data.documents.filter((el: { document: { type: string; }; }) => el.document.type === "downloadable")
		return { 'pagination': {...response.data.pagination}, 'documents' : [...filteredResponse]};
	}

	async getAllRegular(language: string,pagination: TPagination) {
		const response = await this.apiService.request(
			'/admin/document/all' + createQueryParams(pagination) + '&type=regular_information',
			{
				method: HttpMethods.GET,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${language}`,
				}
			}
		);
		const filteredResponse = response.data.documents.filter((el: { document: { type: string; }; }) => el.document.type === "regular_information")

		return { 'pagination': {...response.data.pagination}, 'documents' : [...filteredResponse]};
	}

	async getMainDoc() {
		const response = await this.apiService.request(
			'/admin/document/all?perPage=100&type=organizational_structure',
			{
				method: HttpMethods.GET,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${this.language}`,
				}
			}
		);

		const filteredResponse = response.data.documents.filter((el: { document: { type: string; }; }) => el.document.type === "organizational_structure")[0]
		return filteredResponse;
	}
}
