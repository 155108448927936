import {storage} from '../../entities/Storage';
import {ACCESS_TOKEN, REFRESH_TOKEN} from '../../consts/storageKeys';
import {SET_ACCESS_TOKEN, SET_REFRESH_TOKEN} from './user.types';


interface IUserReducerState {
	accessToken: string;
	refreshToken: string;
}

const initialState: IUserReducerState = {
	accessToken: storage.get(ACCESS_TOKEN),
	refreshToken: storage.get(REFRESH_TOKEN),
};

export const userReducer = (state = initialState, action: any) => {
	switch (action.type) {
	case SET_ACCESS_TOKEN:
		storage.set(ACCESS_TOKEN, action.accessToken);
		return {...state, accessToken: action.accessToken};
	case SET_REFRESH_TOKEN:
		storage.set(REFRESH_TOKEN, action.refreshToken);
		return {...state, refreshToken: action.refreshToken};
	default:
		return state;
	}
};
