import React, { useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
import ButtonLink from '../../components/ButtonLink';
import Button from '../../components/button';
import { createInputs, IField } from '../../helpers/createInput';
import { authService } from '../auth.service';
import { TApiError } from '../../helpers/convertApiError';
import { DOCUMENT_ROUTE } from '../../consts/routes';
import ResetPasswordPage from '../resetPassword';
import { defaultFormikConfig } from '../../components/form/config';
import { createPathToListRoute } from '../../helpers/createRoute';
import Title from '../../components/Title';
import ShadowWrapper from '../../components/ShadowWrapper';
import { setToast } from '../../helpers/setToast';

const defaultInitialValues = {
	email: '',
	password: '',
};

const fields: IField[] = [
	{
		key: 'email',
		type: 'email',
		label: 'E-mail',
		labelVariant: 'light'
	},
	{
		key: 'password',
		type: 'password',
		label: 'Пароль',
		labelVariant: 'light'
	}
];

const validationSchema = object({
	email: string(),
	password: string().required('Вкажіть пароль'),
});

const LoginPage = (): JSX.Element => {
	const navigate = useNavigate();
	const [showResetPassword, setShowResetPassword] = useState(false);
	const form = useFormik({
		...defaultFormikConfig,
		initialValues: defaultInitialValues,
		validationSchema: validationSchema,
		onSubmit: async (values, { setErrors }) => {
			await authService.login(values.email, values.password)
				.then(() => {
					navigate(createPathToListRoute(DOCUMENT_ROUTE));
				})
				.catch((error: TApiError) => {
					if( error.fields?.username) {
						setToast(`Username - ${error.fields?.username}`, 'error');
					}
					if(error.fields?.code) {
						setToast(`Code - ${error.fields?.code} `, 'error');
					}
					
					setErrors({
						email: error.fields?.username,
						password: error.fields?.code,
					});
				});
		}
	});

	return (
		<section className="login">
			{!showResetPassword && (
				<ShadowWrapper
					variant="login"
					className="login__body"
				>
					<Title
						type="h2"
						size="middle"
						className="login__title"
					>
						Панель керування
					</Title>
					{createInputs(form, fields)}
					{/* <ButtonLink
						variant="login"
						onClick={() => setShowResetPassword(true)}
						className="login__button-link"
					>
						Забули пароль?
					</ButtonLink> */}
					<Button
						length="full-length"
						className="login__button"
						onClick={form.handleSubmit}
					>
						Увійти
					</Button>
				</ShadowWrapper>
			)}
			{showResetPassword && (
				<ResetPasswordPage
					show={showResetPassword}
					onChange={(value) => setShowResetPassword(value)}
				/>
			)}
		</section>
	);
};

export default LoginPage;
