import React, { FC } from 'react';

interface propsShadowWrapper {
	className?: string,
	variant: 'login' | 'content',
}

const ShadowWrapper: FC<propsShadowWrapper> = ({ children, className, variant }) => {
	return (
		<div className={`shadow-wrapper shadow-wrapper--${variant} ${className}`}>
			{children}
		</div>
	);
};

export default ShadowWrapper;
