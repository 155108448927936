import React, { useEffect, useState, ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';

type TLoaderProps = {
	condition: Promise<any> | boolean;
	onChange?: (loading: boolean) => void;
	children?: ReactNode;
	initialLoading?: boolean;
	center?: boolean;
}

const Loader = (props: TLoaderProps): JSX.Element => {
	const {
		condition,
		onChange,
		children,
		initialLoading = false,
		center=false
	} = props;
	const [loading, setLoading] = useState(initialLoading);

	useEffect(() => {
		onChange?.(loading);
	}, [loading]);

	useEffect(() => {
		if (typeof condition !== 'boolean') {
			setLoading(true);
			condition.finally(() => setLoading(false));
		} else {
			setLoading(condition);
		}
	}, [condition]);

	return loading ? (
		<Spinner animation="border"  className={center ? `loader` : ''}/>
	) : (
		<>
			{children}
		</>
	);
};

export default Loader;
