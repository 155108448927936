import { DocumentRepository } from './document.repository';
import { TPagination, TPaginationResponse } from '../interfaces/TPagination';
import { setToast } from '../helpers/setToast';
import {
	CREATE_ERROR_TEXT,
	DELETE_ERROR_TEXT,
	EDIT_ERROR_TEXT,
	FETCH_ERROR_TEXT,
} from '../consts/errorMessages';
import { TDocumentCreate, TEditDocument } from './types';

class DocumentService {
	private readonly repository: DocumentRepository;
	constructor() {
		this.repository = new DocumentRepository();
	}

	async getAll(language : string, pagination: TPagination) {
		const response = await this.repository.getAll(language, pagination).catch((error) => {
			setToast(error.message || FETCH_ERROR_TEXT, 'error');
			throw error;
		});

		return {
			entities: response.documents,
			pagination: response.pagination as TPaginationResponse,
		};
	}

	async getAllRegular(language : string, pagination: TPagination) {
		const response = await this.repository
			.getAllRegular(language, pagination)
			.catch((error) => {
				setToast(error.message || FETCH_ERROR_TEXT, 'error');
				throw error;
			});
		return {
			entities: response.documents,
			pagination: response.pagination as TPaginationResponse,
		};
	}

	async getById(id: string) {
		return this.repository.getById(id).catch((error) => {
			setToast(error.message, 'error');
		});
	}

	async getMainDoc() {
		return this.repository.getMainDoc().catch((error) => {
			setToast(error.message, 'error');
		});
	}

	async create(data: TDocumentCreate, cb:any) {
		const q = await this.repository
			.create(data)
			.then((data) => {
				cb(data)
				setToast('Документ успішно створено', 'info');
			})
			.catch((error) => {
				setToast(error.message?.name|| error.message || CREATE_ERROR_TEXT, 'error');
				throw error;
			});

		return q
	}

	async createTranslation(language : string, id : string | number, data: TDocumentCreate) {
		await this.repository
		.createTranslation(language, id, data)
		.then(() => {
			setToast('Переклад успішно додано', 'info');
		})
		.catch((error) => {
			setToast(error.message?.name || error.message || CREATE_ERROR_TEXT, 'error');
			throw error;
		});

		
	}

	async edit(language : string, id: string, data: TEditDocument) {
		await this.repository
			.edit(language, id, data)
			.then(() => {
				setToast('Документ відредаговано', 'info');
			})
			.catch((error) => {
				setToast(error.message?.name || error.message || CREATE_ERROR_TEXT, 'error');
				throw error;
			});
	}

	async delete(id: string) {
		await this.repository
			.delete(id)
			.then(() => {
				setToast('Документ успішно видалено', 'info');
			})
			.catch((error) => {
				setToast(error.message || DELETE_ERROR_TEXT, 'error');
				throw error;
			});
	}
}

export const documentService = new DocumentService();
