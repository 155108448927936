import Yup from '../entities/Yup';

export const validationSchema = Yup.object().shape({
	title_ua: Yup.string()
		.requiredByLang('ua', 'Вкажіть заголовок')
		.min(3, 'мінімум 3 символи')
		.max(200, 'Макс. кількість 200 символи'),
	content_ua: Yup.string()
		.requiredByLang('ua', 'Вкажіть текст новини').
		max(15000, 'Макс. кількість 15000 символи'),
	slug_ua: Yup.string().requiredByLang('ua', 'Вкажіть slug').min(3, 'мінімум 3 символи').max(15000, 'Макс. кількість 15000 символи').matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link'),
	slug_ru: Yup.string().min(3, 'мінімум 3 символи').max(15000, 'Макс. кількість 15000 символи').matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link'),
	slug_en: Yup.string().min(3, 'мінімум 3 символи').max(15000, 'Макс. кількість 15000 символи').matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link'),
	title_ru: Yup.string()
		.when('content_ru', {
			is: (field: string) => field,
			then: Yup.string().required('Вкажіть заголовок'),
			otherwise: Yup.string(),
		})
		.min(3, 'мінімум 3 символи')
		.max(200, 'Макс. кількість 200 символи'),
	content_ru: Yup.string()
		.when('title_ru', {
			is: (field: string) => field,
			then: Yup.string().required('Вкажіть текст новини').
				max(15000, 'Макс. кількість 15000 символи'),
			otherwise: Yup.string(),
		}),
	title_en: Yup.string()
		.when('content_en', {
			is: (field: string) => field,
			then: Yup.string().required('Вкажіть заголовок'),
			otherwise: Yup.string(),
		})
		.min(3, 'мінімум 3 символи')
		.max(200, 'Макс. кількість 200 символи'),
	content_en: Yup.string()
		.when('title_en', {
			is: (field: string) => field,
			then: Yup.string().required('Вкажіть текст новини').
				max(15000, 'Макс. кількість 15000 символи'),
			otherwise: Yup.string(),
		}),
	image_ua: Yup.mixed().required('Завантажте зображення'),
	image_ru: Yup.mixed(),
	image_en: Yup.mixed(),
	date: Yup.string(),
}, [
	['content_en', 'title_en'],
	['content_ru', 'title_ru'],
]);
