/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import EditDocumentForm from './components/editDocumentForm';
import { getLanguage } from '../store/common/selectors.common';
import { TDocument } from './types';
import { DOCUMENT_ROUTE, REGULAR_INFO_ROUTE } from '../consts/routes';
import { createPathToListRoute } from '../helpers/createRoute';
import { documentService } from './document.service';
import PageContainer from '../components/PageContainer';
import Loader from '../components/loader';
import ShadowWrapper from '../components/ShadowWrapper';
import ButtonClose from '../components/ButtonClose';

const DocumentEditPage = (): JSX.Element => {
	const { id } = useParams();
	
	const language = useSelector(getLanguage);
	const [document, setDocument] = useState<null | TDocument>(null);
	const [documentRu, setDocumentRu] = useState<null | TDocument>(null);
	const [documentEn, setDocumentEn] = useState<null | TDocument>(null);
	const [reload, setReload] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const promise = useMemo(async () => {
		const data = await documentService.getById(id!);
		setDocument(data);
		if(data?.document?.translations?.length > 1) {
			let ruInfo = data?.document?.translations.find((el: { language: { code: string; }; }) => el.language.code === 'ru'
			)
			let enInfo = data?.document?.translations.find((el: { language: { code: string; }; }) => el.language.code === 'en'
			)
			if(ruInfo?.id) {
				const dataRu = await documentService.getById(ruInfo?.id)
				setDocumentRu(dataRu)
			}

			if(enInfo?.id) {
				const dataEn = await documentService.getById(enInfo?.id)
				setDocumentEn(dataEn)
			}

		}
	}, [reload, language]);
	const getForm = () => {
		if (!document) {
			return '';
		}
		const common = {
			onSubmit: () => {
				navigate(
					createPathToListRoute(
						location.pathname.indexOf(REGULAR_INFO_ROUTE) === -1
							? DOCUMENT_ROUTE
							: REGULAR_INFO_ROUTE
					)
				);
			},
		};

		switch (language) {
			case 'ru':
				return (
					<EditDocumentForm
						key="ru"
						name={documentRu?.name || ''}
						path={documentRu?.file?.path || ''}
						type={documentRu?.document?.type || ''}
						id={documentRu?.id || ''}
						priority={documentRu?.document?.priority || ''}
						documentId = {document?.document?.id as string}
						{...common}
					/>
				);
			case 'ua':
				return (
					<EditDocumentForm
						key="ua"
						name={document.name || ''}
						path={document.file.path || ''}
						type={document.document.type || ''}
						id={document?.id || ''}
						priority={document?.document?.priority || ''}
						documentId = {document?.document?.id || ''}
						{...common}
					/>
				);
			case 'en':
				return (
					<EditDocumentForm
						key="en"
						name={documentEn?.name || ''}
						path={documentEn?.file?.path || ''}
						type={document.document.type || ''}
						id={documentEn?.id || ''}
						priority={documentEn?.document?.priority || ''}
						documentId = {document.document.id || ''}
						{...common}
					/>
				);
		}
	};

	return (
		<PageContainer className="document-edit">
			<ShadowWrapper variant="content" className="document-edit__body">
				<ButtonClose align="right" onClick={() => navigate(-1)} />
				<Loader condition={promise}>
					{document ? <>{getForm()}</> : <span>Не знайдено</span>}
				</Loader>
			</ShadowWrapper>
		</PageContainer>
	);
};

export default DocumentEditPage;
