import React, { useEffect } from 'react';
import PageContainer from '../components/PageContainer';
import CreateNewsForm from './components/createNewsForm';
import { useNavigate } from 'react-router-dom';
import { NEWS_ROUTE } from '../consts/routes';
import { createPathToListRoute } from '../helpers/createRoute';
import ShadowWrapper from '../components/ShadowWrapper';
import ButtonClose from '../components/ButtonClose';

const NewsCreatePage = () => {
	const navigate = useNavigate();
	useEffect(() => {
		const handlePopState = (event: any) => {
			const confirmed = window.confirm(
				'Ви впевнені що хочете покинути сторінку?'
			);
			if (!confirmed) {
				// Push the current state back to the history stack
				window.history.pushState(
					null,
					null as any as string,
					window.location.href
				);
			} else {
				navigate(-1);
			}
		};

		// Add the event listener for popstate
		window.addEventListener('popstate', handlePopState);

		// Push the initial state to the history stack
		window.history.pushState(null, null as any as string, window.location.href);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [navigate]);

	useEffect(() => {
		const handleBeforeUnload = (event: any) => {
			event.preventDefault();
			event.returnValue = ''; // Сучасні браузери показують стандартне повідомлення
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	
	return (
		<PageContainer className="news-create">
			<ShadowWrapper
				variant="content"
				className="news-create__body"
			>
				<ButtonClose align="right" onClick={() => navigate(-1)} />
				<CreateNewsForm
					onSuccess={() => {
						navigate(createPathToListRoute(NEWS_ROUTE));
					}}
				/>
			</ShadowWrapper>
		</PageContainer>
	);
};

export default NewsCreatePage;
