import {
	isArray,
	isBoolean,
	isDate,
	isFile,
	isNull,
	isObject,
	isUndefined,
} from '../typeChecks';
import { Config } from './Config';
import {
	ArrayField,
	BooleanField,
	DateField,
	DefaultField,
	FileField,
	NullField,
	ObjectField,
	UndefinedField,
} from './fields';

export type TFormDataExporterConfig = {
	nullAsUndefined: boolean;
	booleanAsInteger: boolean;
	useIndices: boolean;
	allowEmptyArray: boolean;
	undefinedAsNull: boolean;
	stringifyObjects: boolean;
};

const defaultConfig = {
	nullAsUndefined: true,
	booleanAsInteger: true,
	useIndices: true,
	allowEmptyArray: false,
	undefinedAsNull: false,
	stringifyObjects: true,
};

export const addObjectToFormData = (
	object: { [key: string]: any },
	cfg?: TFormDataExporterConfig,
	formData?: FormData
): FormData => {
	const result = formData || createFormData();
	Object.keys(object).forEach((key: string) =>
		appendToFormData(new Config(key, object[key], cfg || defaultConfig, result))
	);
	return result;
};

export const appendToFormData = (config: Config<any>) => {
	const field = createField(config.value);
	field.execute(config);
};

export const createFormData = (): FormData => {
	return new FormData();
};

const createField = (field: any) => {
	if (isUndefined(field)) {
		return new UndefinedField();
	}
	if (isFile(field)) {
		return new FileField();
	}
	if (isNull(field)) {
		return new NullField();
	}
	if (isDate(field)) {
		return new DateField();
	}
	if (isArray(field)) {
		return new ArrayField();
	}
	if (isObject(field)) {
		return new ObjectField();
	}
	if (isBoolean(field)) {
		return new BooleanField();
	}
	return new DefaultField();
};
