import React from 'react';
import { Trash } from 'react-bootstrap-icons';
import ButtonLink from '../../components/ButtonLink';
import Title from '../../components/Title';
import { Service } from '../service';


type TCategoryPreview = {
	id: string;
	name: string;
	editText: string;
	setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	onDelete: (id: number) => void;
	service: Service;
	serviceID: string;
}

const Preview = (props: TCategoryPreview): JSX.Element => {
	const {
		id,
		name,
		editText,
		setEditMode,
		service,
		onDelete,
		serviceID
	} = props;

	return (
		<div className="category-preview-body">
			<Title
				type="h4"
				size="small"
				className="category-preview-body__title"
			>
				{name}
			</Title>
			<div className="category-preview-body__control">
				<ButtonLink
					variant="small"
					onClick={() => setEditMode(true)}
					className="category-preview-body__button-edit"
				>
					{editText}
				</ButtonLink>
				<button
					type="button"
					className="category-preview-body__button-trash"
					onClick={() => {
						service.delete(serviceID).then(() => {
							onDelete(serviceID as any);
						});
					}}
				>
					<Trash color="red" />
				</button>
			</div>
		</div>
	);
};

export default Preview;
