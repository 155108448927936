import React, { useEffect, useState } from 'react';
import PageContainer from '../components/PageContainer';
import { useSelector } from 'react-redux';
import { getLanguage } from '../store/common/selectors.common';
import { useFormik } from 'formik';
import { defaultFormikConfig } from '../components/form/config';
import createInput from '../helpers/createInput';
import { createPathToListRoute } from '../helpers/createRoute';
import { PROJECT_ROUTE } from '../consts/routes';
import { TCreateProjectForm } from './types';
import { DEFAULT_PROJECT_TABLE } from '../consts/common';
import Button from '../components/button';
import { projectService } from './project.service';
import { useNavigate } from 'react-router-dom';
import { validationSchema } from './validationSchema';
import { useCategoryCountry } from '../hooks/useCategoryCountry';
import ShadowWrapper from '../components/ShadowWrapper';
import FormItem from '../components/FormItem';
import ButtonClose from '../components/ButtonClose';

const initialValues = {
	title_ua: '',
	content_ua: '',
	table_ua: [...DEFAULT_PROJECT_TABLE],
	title_ru: '',
	content_ru: '',
	table_ru: [...DEFAULT_PROJECT_TABLE],
	title_en: '',
	content_en: '',
	table_en: [...DEFAULT_PROJECT_TABLE],
	date: new Date(),
	countryId_ua: '',
	countryId_ru: '',
	countryId_en: '',
	categoryId_ua: '',
	categoryId_ru: '',
	categoryId_en: '',
	switcherGeo: false,
	switcher_ru: false,
	switcher_en: false,
	switcher_ua: false,
	geo: {
		lat: undefined,
		lng: undefined,
	},
	slug_ua: '',
	slug_ru: '',
	slug_en: '',
};

const ProjectCreatePage = (): JSX.Element => {
	const navigate = useNavigate();
	const language = useSelector(getLanguage);
	let createRequestInfo: any;

	const { categories, countries } = useCategoryCountry();

	let imageHelper = (mainImage: any, sliderImage: any) => {
		let resultArray;
		if (sliderImage?.length > 0) {
			resultArray = [mainImage, ...sliderImage];
		} else {
			resultArray = [mainImage];
		}
		return resultArray;
	};
	let tableName = `table_${language}`;

	useEffect(() => {
		const handlePopState = (event: any) => {
			const confirmed = window.confirm(
				'Ви впевнені що хочете покинути сторінку?'
			);
			if (!confirmed) {
				// Push the current state back to the history stack
				window.history.pushState(
					null,
					null as any as string,
					window.location.href
				);
			} else {
				navigate('/project');
			}
		};

		// Add the event listener for popstate
		window.addEventListener('popstate', handlePopState);

		// Push the initial state to the history stack
		window.history.pushState(null, null as any as string, window.location.href);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	useEffect(() => {
		const handleBeforeUnload = (event: any) => {
			event.preventDefault();
			event.returnValue = ''; // Сучасні браузери показують стандартне повідомлення
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const form = useFormik<any>({
		...defaultFormikConfig,
		validationSchema,
		initialValues: {
			...initialValues,
		},
		onSubmit: (values, { setErrors }) => {
			setIsDisabled(true);
			projectService
				.create(
					language,
					{
						slug: values.slug_ua,
						title: values.title_ua,
						body: values.content_ua,
						isEnabled: '1',
						country: values.countryId_ua,
						category: values.categoryId_ua,
						latitude: values?.geo_ua?.lat ? values?.geo_ua?.lat : '',
						longitude: values?.geo_ua?.lng ? values?.geo_ua?.lng : '',
						table: values?.table_ua,
					},
					imageHelper(values.image_ua, values.images_ua),
					(data: { data: { article: { id: string | number } } }) => {
						createRequestInfo = data;
					}
				)
				.then(() => {
					if (values?.content_ru && values?.slug_ru && values?.title_ru) {
						projectService.createTranslation(
							'ru',
							createRequestInfo?.data?.project?.id,
							{
								body: values?.content_ru,
								slug: values?.slug_ru,
								title: values?.title_ru,
								isPinned: '0',
								isEnabled: '1',
								table: values?.table_ru,
							},
							imageHelper(values.image_ru, values.images_ru)
						);
					}
					if (values?.content_en && values?.slug_en && values?.title_en) {
						projectService.createTranslation(
							'en',
							createRequestInfo?.data?.project?.id,
							{
								body: values?.content_en,
								slug: values?.slug_en,
								title: values?.title_en,
								isPinned: '0',
								isEnabled: '1',
								table: values?.table_en,
								latitude: values?.geo_en?.lat ? values?.geo_en?.lat : '',
								longitude: values?.geo_en?.lng ? values?.geo_en?.lng : '',
							},
							imageHelper(values.image_en, values.images_en)
						);
					}
					navigate(createPathToListRoute(PROJECT_ROUTE));
				})
				.catch((error) => {
					setErrors({
						...error.fields,
					});
				})
				.finally(() => {
					setIsDisabled(false);
				});
		},
	});

	return (
		<PageContainer className="project-form">
			<ShadowWrapper variant="content" className="project-form__body">
				<ButtonClose align="right" onClick={() => navigate(-1)} />
				<div>
					<div className="project-form__item">
						{createInput(form, {
							key: 'title_' + language,
							type: 'text',
							label: 'Назва проекту*',
							labelVariant: 'bold',
						})}
						{language === 'ua' &&
							createInput(form, {
								key: 'countryId_' + language,
								type: 'select',
								label: 'Країна*',
								labelVariant: 'bold',
								options: {
									options: countries.map((country) => ({
										label: country.name,
										value: country?.country?.id,
									})),
								},
							})}
					</div>
					<div className="project-form__category">
						{language === 'ua' &&
							createInput(form, {
								key: 'categoryId_' + language,
								type: 'select',
								label: 'Категорії проекту*',
								labelVariant: 'bold',
								options: {
									options: categories.map((category) => ({
										label: category.name,
										value: category?.category?.id,
									})),
								},
							})}
					</div>
					<FormItem>
						{createInput(form, {
							key: 'image_' + language,
							label: 'Головне фото проекту*',
							labelVariant: 'bold',
							type: 'image',
						})}
					</FormItem>
					<FormItem className="project-form__image">
						{createInput(form, {
							key: 'images_' + language,
							label: 'Фото проекту у слайдері*',
							labelVariant: 'bold',
							type: 'slider',
						})}
					</FormItem>
					{createInput(form, {
						key: 'content_' + language,
						label: 'Опис проекту*',
						labelVariant: 'bold',
						type: 'editor',
					})}
					{createInput(form, {
						key: 'slug_' + language,
						type: 'text',
						label: 'slug*',
						labelVariant: 'bold',
					})}
					<FormItem className="project-form__table-item">
						{createInput(form, {
							key: 'switcher_' + language,
							label: 'Таблиця',
							labelVariant: 'bold',
							type: 'switch',
						})}
					</FormItem>
					{/* @ts-ignore*/}
					{form.values[`switcher_${language}`] &&
						createInput(form, {
							key: 'table_' + language,
							label: 'Занесення даних до таблиці',
							labelVariant: 'bold',
							type: 'table',
						})}
					{language === 'ua' && (
						<FormItem className="project-form__table-item">
							{createInput(form, {
								key: 'switcherGeo_' + language,
								label: 'Геолокація',
								labelVariant: 'bold',
								type: 'switch',
							})}
						</FormItem>
					)}
					{language === 'ua' && (
						<FormItem>
							{form.values[`switcherGeo_${language}`] &&
								createInput(form, {
									key: 'geo_' + language,
									label: 'Геолокація',
									labelVariant: 'bold',
									type: 'geo',
								})}
						</FormItem>
					)}
					<div className="project-form__footer">
						<Button isDisabled={isDisabled} onClick={form.submitForm}>
							Зберегти
						</Button>
					</div>
				</div>
			</ShadowWrapper>
		</PageContainer>
	);
};

export default ProjectCreatePage;
