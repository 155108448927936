import React, { FC } from 'react';

interface propsFormItem {
	className?: string;
}

const FormItem: FC<propsFormItem> = ({ children, className }) => {
	return <div className={`form-item ${className}`}>{children}</div>;
};

export default FormItem;
