import React from 'react';
import { useLocation } from 'react-router-dom';
import { Trash } from 'react-bootstrap-icons';
import { documentService } from '../document.service';
import DocumentIcon from '../../public/svgs/DocumentIcon';
import { useNavigate } from 'react-router-dom';
import { DOCUMENT_ROUTE, REGULAR_INFO_ROUTE } from '../../consts/routes';
import { createPathToEditRoute } from '../../helpers/createRoute';
import ButtonLink from '../../components/ButtonLink';
import ShadowWrapper from '../../components/ShadowWrapper';
import Title from '../../components/Title';

type TDocumentPreviewProps = {
	name: string;
	onDelete: (id: string) => void;
	id: string;
	documentID: string;
};

const DocumentPreview = (props: TDocumentPreviewProps): JSX.Element => {
	const { name, id, onDelete, documentID } = props;
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<li className="document-preview">
			<ShadowWrapper variant="content" className="document-preview__body">
				<DocumentIcon className="document-preview__icon" />
				<div className="document-preview__info">
					<Title type="h4" size="small" className="document-preview__title">
						{name}
					</Title>
					<div>
						<ButtonLink
							variant="small"
							className="document-preview__button"
							onClick={() =>
								navigate(
									createPathToEditRoute(
										id,
										location.pathname.indexOf(REGULAR_INFO_ROUTE) === -1
											? DOCUMENT_ROUTE
											: REGULAR_INFO_ROUTE
									)
								)
							}
						>
							Редагувати
						</ButtonLink>
						<button
							className="document-preview__button-trash"
							onClick={() => {
								documentService.delete(documentID).then(() => {
									onDelete(documentID);
								});
							}}
						>
							<Trash color="red" />
						</button>
					</div>
				</div>
			</ShadowWrapper>
		</li>
	);
};

export default DocumentPreview;
