import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoading } from '../../hooks/useLoading';
import { INITIAL_MAX_PAGE, RECORDS_PER_PAGE } from '../../consts/common';
import Loader from '../loader';
import { TPagination, TPaginationResponse } from '../../interfaces/TPagination';
import { createPagination } from '../pagination';
import { useSelector } from 'react-redux';
import { getLanguage, getReloadListWithPagination } from '../../store/common/selectors.common';
import Title from '../Title';

interface IEntityWithId {
	id: number;
	is_pinned?: boolean;
}

type TListWithPaginationProps<TData extends IEntityWithId> = {
	Element: React.ReactType;
	endpoint: (
		language: string,
		pagination: TPagination,
		project?: boolean | undefined
	) => Promise<{ entities: TData[]; pagination: TPaginationResponse }>;
	type: 'row' | 'column';
	title: string;
	className?: string;
	elementProps?: { [key: string]: any };
	categoryCountry?: boolean;
};

function ListWithPagination<TData extends IEntityWithId>(
	props: TListWithPaginationProps<TData>
): JSX.Element {

	const { Element, endpoint, type, elementProps, title, className, categoryCountry=false } = props;
	const language = useSelector(getLanguage);
	const reload = useSelector(getReloadListWithPagination);
	const [initialLoad, setInitialLoad] = useState(true);
	const { loading, setLoading } = useLoading({ initialLoading: false });
	const [maxPage, setMaxPage] = useState<number>(INITIAL_MAX_PAGE);
	const [items, setItems] = useState<TData[]>([]);
	const location = useLocation();
	const [idPinned, setIdPinned] = useState<number>(-1);

	const pagination = createPagination({
		maxPage,
		onPageChange: (page: number) => {
			setLoading(true);
			fetchData(page).finally(() => setLoading(false));
		},
	});

	const categoryCountryLanguage = () => {
		return categoryCountry ? 'ua': language
	}

	const fetchData = async (page: number) => {
		setLoading(true);
		await endpoint(categoryCountryLanguage(), {
			page,
			per_page: RECORDS_PER_PAGE,
		})
			.then((data) => {
				const lastPage = data?.pagination?.lastPage;
				if (lastPage) {
					setMaxPage(lastPage);
				}
				
				setItems(data.entities);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (initialLoad) {
				setInitialLoad(false);
				return;
			}
			fetchData(pagination.getCurrentPage());
			return () => {
				isMounted = false;
			};
		}
	}, [reload, location, language]);

	return (
		<div className={className}>
			<Title type="h2" size="large" indents="vertical-indent">
				{title}
			</Title>
			<ul className={`list-with-pagination list-with-pagination--${type}`}>
				<Loader condition={loading}>
					{items?.map((item, index) => (
						<Element
							key={index}
							{...elementProps}
							{...item}
							onDeleteItem={() => {
								fetchData(pagination.getCurrentPage());
							}}
							onEditItem={() => {
								fetchData(pagination.getCurrentPage());
							}}
							onIdPinned={setIdPinned}
							idPinned={idPinned}
						/>
					))}
				</Loader>
			</ul>
			{pagination.element}
		</div>
	);
}

export default ListWithPagination;
