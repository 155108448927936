export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const DOCUMENT_ROUTE = '/document';
export const REGULAR_INFO_ROUTE = '/regular-info';
export const NEWS_ROUTE = '/news';
export const VACANCY_ROUTE = '/vacancy';
export const CATEGORY_ROUTE = '/category';
export const COUNTRY_ROUTE = '/country';
export const PROJECT_ROUTE = '/project';
export const PROJECT_EDIT_ROUTE = '/project/:id/';
export const PROJECT_CREATE_ROUTE = '/project/create';
