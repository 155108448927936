import { setToast } from './setToast';

export type FileTypes = 'image' | 'video' | 'audio';

export const validateFileType = (
	file: File | undefined,
	type: FileTypes
): boolean => {
	if (file?.type.split('/')[0] !== type) {
		setToast('Неправильний завантажуваний тип файлу', 'error');
		return false;
	}

	return true;
};
