import React, { useEffect, useMemo, useState } from 'react';
import { createInputs, IField } from '../../helpers/createInput';
import Button from '../../components/button';
import { useFormik } from 'formik';
import { defaultFormikConfig } from '../../components/form/config';
import { object, ref, string } from 'yup';
import { authService } from '../auth.service';
import { DateEntity } from '../../entities/Date';
import { getDefaultExpireTime } from '../../helpers/getDefaultExpireTime';
import ResetPassword from './ResetPassword';

type TResetPasswordProps = {
	show: boolean;
	onChange: (value: boolean) => void;
	email: string;
	onConfirm: () => void;
	initialExpireCodeDate: DateEntity | null;
}

const fields: IField[] = [
	{
		key: 'code',
		label: 'Код підтвердження',
		labelVariant: 'light',
		type: 'text',
		className: 'input--code',
	},
	{
		key: 'password',
		label: 'Введіть новий пароль',
		labelVariant: 'light',
		type: 'password',
	},
	{
		key: 'repeatPassword',
		label: 'Підтвердіть новий пароль',
		labelVariant: 'light',
		type: 'password',
	},
];

const validationSchema = object({
	code: string()
		.required('Обов\'язкове поле')
		.matches(/^[0-9]+$/, 'Тільки цифри')
		.min(8, 'Мин. кількість 8 цифр')
		.max(8, 'Макс. кількість 8 цифр'),
	password: string()
		.required('Вкажіть пароль')
		.min(8, 'Пароль повинен містити не менше 8 символів, 1 цифри та 1 спец. символу')
		.matches(/(?=.*[!@#$%^&*])(?=.*[0-9])/, 'Пароль повинен містити не менше 8 символів, 1 цифри та 1 спец. символу'),
	repeatPassword: string().
		oneOf([ref('password'), null], 'Паролі мають співпадати'),
});

const defaultInitialValues = {
	code: '',
	password: '',
	repeatPassword: '',
};

const ResetPasswordModal = (props: TResetPasswordProps): JSX.Element => {
	const { show, onChange, onConfirm, email, initialExpireCodeDate } = props;
	const [expireCodeDate, setExpireCodeDate] = useState(initialExpireCodeDate || getDefaultExpireTime());
	const [time, setTime] = useState<number>(0);

	useEffect(() => {
		const dateNow = new Date();
		setTime(expireCodeDate.dateInMilliseconds - dateNow.getTime());
	}, []);

	const formattedTimeLeft = useMemo(() => {
		const minutes = Math.floor(time / 1000 / 60);
		const seconds = Math.floor((time - minutes * 1000 * 60) / 1000);
		if (minutes < 0 || seconds < 0) {
			return '00:00';
		}
		return `${DateEntity.addZeroToNumber(minutes)}:${DateEntity.addZeroToNumber(seconds)}`;
	}, [time]);

	const codeExpired = useMemo(() => {
		const dateNow = new Date();
		return dateNow.getTime() > expireCodeDate.dateInMilliseconds;
	}, [expireCodeDate, time]);

	useEffect(() => {
		if (!codeExpired) {
			const intervalId = setInterval(() => {
				const dateNow = new Date();
				setTime(expireCodeDate.dateInMilliseconds - dateNow.getTime());
			}, 1000);
			return () => clearInterval(intervalId);
		}
	}, [codeExpired, expireCodeDate]);

	const resendCode = () => {
		authService
			.sendResetPasswordCode(email)
			.then((data) => {
				setExpireCodeDate(data.date);
				const dateNow = new Date();
				setTime(data.date.dateInMilliseconds - dateNow.getTime());
			});
	};

	const form = useFormik({
		...defaultFormikConfig,
		initialValues: defaultInitialValues,
		validationSchema: validationSchema,
		onSubmit: async (values, { setErrors, resetForm }) => {
			await authService.resetPassword(email, values.code, values.password, values.repeatPassword)
				.then(() => {
					resetForm();
					onConfirm();
				})
				.catch((error) => {
					setErrors({
						repeatPassword: error.fields?.password_repeat,
						password: error.fields?.password,
						code: error.fields?.code,
					});
				});
		},
	});

	return (
		<ResetPassword
			show={show}
			title="Відновити пароль"
			onChange={(value) => { form.resetForm(); onChange(value); }}
		>
			<>
				{createInputs(form, fields)}
				<Button
					length="full-length"
					disabled={!codeExpired}
					onClick={resendCode}
				>
					{codeExpired ? 'Надіслати код знову' : formattedTimeLeft}
				</Button>
				<Button
					length="full-length"
					onClick={form.handleSubmit}
				>
					Відновити пароль
				</Button>
			</>
		</ResetPassword>
	);
};

export default ResetPasswordModal;
