import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import createInput from '../../helpers/createInput';
import Button from '../../components/button';
import { TCreateNews } from '../types';
import { defaultFormikConfig } from '../../components/form/config';
import { validationSchema } from '../validationSchema';
import { newsService } from '../news.service';
import { getLanguage } from '../../store/common/selectors.common';
import FormItem from '../../components/FormItem';
import { useNavigate } from 'react-router-dom';
import { NEWS_ROUTE } from '../../consts/routes';
import { createPathToListRoute } from '../../helpers/createRoute';

const initialValues = {
	title_ua: '',
	content_ua: '',
	title_en: '',
	content_en: '',
	title_ru: '',
	content_ru: '',
	image: '' as any,
	date: new Date(),
	slug_ua: '',
	slug_ru: '',
	slug_en: '',
};

type TCreateNewsForm = {
	onSuccess: () => void;
};

const CreateNewsForm = (props: TCreateNewsForm): JSX.Element => {
	const { onSuccess } = props;
	const navigate = useNavigate();
	let createRequestInfo: { data: { article: { id: string | number } } };
	const language = useSelector(getLanguage);

	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const form = useFormik<TCreateNews>({
		...defaultFormikConfig,
		initialValues: {
			...initialValues,
			title: '',
			slug: '',
			body: '',
			isEnabled: '',
			isPinned: '',
			priority: '',
			updatedAt: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values: TCreateNews, { setErrors }) => {
			setIsDisabled(true);

			newsService
				.create(
					{
						body: values.content_ua as string,
						slug: values.slug_ua as string,
						title: values.title_ua as string,
						image: values.image_ua as File,
						isPinned: '0',
						isEnabled: '1',
						priority: '0',
						updatedAt: new Date(values.date as Date).toLocaleDateString(
							'fr-FR'
						),
					},
					(data: { data: { article: { id: string | number } } }) => {
						createRequestInfo = data;
					}
				)
				.then((data) => {
					if (values?.content_ru && values?.slug_ru && values?.title_ru) {
						newsService.createTranslation(
							'ru',
							createRequestInfo?.data?.article?.id,
							{
								body: values?.content_ru,
								slug: values?.slug_ru,
								title: values?.title_ru,
								image: values?.image_ru as File,
								isPinned: '0',
								isEnabled: '1',
								priority: '0',
								updatedAt: new Date(values.date as Date).toLocaleDateString(
									'fr-FR'
								),
							}
						);
					}
					if (values?.content_en && values?.slug_en && values?.title_en) {
						newsService.createTranslation(
							'en',
							createRequestInfo?.data?.article?.id,
							{
								body: values?.content_en,
								slug: values?.slug_en,
								title: values?.title_en,
								image: values?.image_en as File,
								isPinned: '0',
								isEnabled: '1',
								priority: '0',
								updatedAt: new Date(values.date as Date).toLocaleDateString(
									'fr-FR'
								),
							}
						);
					}
				})
				.then(() => {
					setIsDisabled(false);
					navigate(createPathToListRoute(NEWS_ROUTE));
				})
				.catch((error) => {
					setErrors({
						...error.fields,
					});
					setIsDisabled(false);
				});
		},
	});
	return (
		<div className="news-form">
			<div className="news-form__header">
				{createInput(form, {
					key: 'title_' + language,
					type: 'text',
					label: 'Заголовок*',
					labelVariant: 'bold',
					className: 'news-form__input-title',
				})}
				{createInput(form, {
					key: 'date',
					type: 'datepicker',
					label: 'Дата*',
					labelVariant: 'bold',
					className: 'news-form__input-datepicker',
				})}
			</div>
			<FormItem>
				{createInput(form, {
					key: 'image_' + language,
					type: 'image',
					label: 'Головне зображення новини*',
					labelVariant: 'bold',
				})}
			</FormItem>
			{createInput(form, {
				key: 'content_' + language,
				type: 'editor',
				label: 'Текст новини*',
				labelVariant: 'bold',
			})}
			{createInput(form, {
				key: 'slug_' + language,
				type: 'text',
				label: 'slug*',
				labelVariant: 'bold',
				className: 'news-form__input-title',
			})}
			<div className="news-form__footer">
				<Button isDisabled={isDisabled} onClick={form.submitForm}>
					Зберегти
				</Button>
			</div>
		</div>
	);
};

export default CreateNewsForm;
