import React from 'react';
import { Trash } from 'react-bootstrap-icons';
import { vacancyService } from '../vacancy.service';
import { useNavigate } from 'react-router-dom';
import { createPathToEditRoute } from '../../helpers/createRoute';
import { VACANCY_ROUTE } from '../../consts/routes';
import Title from '../../components/Title';
import ShadowWrapper from '../../components/ShadowWrapper';
import ButtonLink from '../../components/ButtonLink';

type TVacancyPreviewProps = {
	id: number;
	title: string | null;
	onDeleteItem: (id: string) => void;
	slug: string | null;
	vacancyId: string;
}

const VacancyPreview = (props: TVacancyPreviewProps): JSX.Element => {
	const {
		title,
		id,
		onDeleteItem,
		vacancyId
	} = props;
	const navigate = useNavigate();
	return (
		<li className="vacancy-preview">
			<ShadowWrapper
				variant="content"
				className="vacancy-preview__body"
			>
				<Title
					type="h4"
					size="small"
					className="vacancy-preview__title"
				>
					{title}
				</Title>
				<div className="vacancy-preview__control">
					<ButtonLink
						variant="small"
						className="vacancy-preview__button-edit"
						onClick={() => navigate(createPathToEditRoute(id, VACANCY_ROUTE))}
					>
						Редагувати
					</ButtonLink>
					<button>
						<Trash
							color="red"
							className="vacancy-preview__button-trash"
							onClick={() => {
								vacancyService.delete(vacancyId).then(() => onDeleteItem(vacancyId as string));
							}}
						/>
					</button>
				</div>
			</ShadowWrapper>

		</li>
	);
};

export default VacancyPreview;
