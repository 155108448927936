import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
	CATEGORY_ROUTE,
	COUNTRY_ROUTE,
	DOCUMENT_ROUTE,
	REGULAR_INFO_ROUTE,
	LOGIN_ROUTE,
	NEWS_ROUTE,
	PROJECT_ROUTE,
	VACANCY_ROUTE,
} from './consts/routes';
import LoginPage from './auth/login/login.page';
import { getAccessToken } from './store/user/user.selectors';
import DocumentPage from './document/document.page';
import RequireNonAuth from './components/requiredNonAuth';
import NewsPage from './news/news.page';
import VacanciesPage from './vacancies/vacancies.page';
import CategoryPage from './category/category.page';
import CountryPage from './country/country.page';
import ProjectsListPage from './project/projectsList.page';
import ProjectCreatePage from './project/projectCreate.page';
import ProjectEditPage from './project/projectEdit.page';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import NewsCreatePage from './news/newsCreate.page';
import NewsEditPage from './news/newsEdit.page';
import RequireAuth from './components/requiredAuth';
import CreateVacancyPage from './vacancies/createVacancy.page';
import EditVacancyPage from './vacancies/editVacancy.page';
import DocumentEditPage from './document/documentEdit.page';
import DocumentCreatePage from './document/documentCreate.page';
import BackgroundLogin from './components/BackgroundLogin';
import Aside from './components/Aside';
import Header from './components/Header';

const createCrud = (
	routeName: string,
	ListEL: React.ReactType,
	CreateEl?: React.ReactType,
	EditEl?: React.ReactType
) => {
	return (
		<>
			<Route
				path={routeName}
				element={
					<RequireAuth>
						<ListEL />
					</RequireAuth>
				}
			/>
			{CreateEl && (
				<Route
					path={routeName + '/create'}
					element={
						<RequireAuth>
							<CreateEl />
						</RequireAuth>
					}
				/>
			)}
			{EditEl && (
				<Route
					path={routeName + '/:id/edit'}
					element={
						<RequireAuth>
							<EditEl />
						</RequireAuth>
					}
				/>
			)}
		</>
	);
};

function App() {
	const accessToken = useSelector(getAccessToken);
	return (
		<div className={!accessToken ? 'wrapper wrapper--background' : 'wrapper'}>
			<BackgroundLogin className="wrapper__background top-left" />
			<BackgroundLogin className="wrapper__background bottom-right" />
			<div className="container">
				{accessToken && (
					<>
						<Aside />
						<Header />
					</>
				)}
				<Routes>
					<Route
						path="*"
						element={
							accessToken ? (
								<Navigate to={DOCUMENT_ROUTE} />
							) : (
								<Navigate to={LOGIN_ROUTE} />
							)
						}
					/>
					<Route
						path={LOGIN_ROUTE}
						element={
							<RequireNonAuth>
								<LoginPage />
							</RequireNonAuth>
						}
					/>
					{createCrud(NEWS_ROUTE, NewsPage, NewsCreatePage, NewsEditPage)}
					{createCrud(
						DOCUMENT_ROUTE,
						DocumentPage,
						DocumentCreatePage,
						DocumentEditPage
					)}
					{createCrud(
						REGULAR_INFO_ROUTE,
						DocumentPage,
						DocumentCreatePage,
						DocumentEditPage
					)}
					{createCrud(
						VACANCY_ROUTE,
						VacanciesPage,
						CreateVacancyPage,
						EditVacancyPage
					)}
					{createCrud(CATEGORY_ROUTE, CategoryPage)}
					{createCrud(COUNTRY_ROUTE, CountryPage)}
					{createCrud(
						PROJECT_ROUTE,
						ProjectsListPage,
						ProjectCreatePage,
						ProjectEditPage
					)}
				</Routes>
				<ToastContainer />
			</div>
		</div>
	);
}

export default App;
