/* eslint-disable indent */
import React, { useMemo, useState, useCallback } from 'react';
import PageContainer from '../components/PageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { newsService } from './news.service';
import Loader from '../components/loader';
import EditNewsForm from './components/editNewsForm';
import { useSelector } from 'react-redux';
import { getLanguage } from '../store/common/selectors.common';
import { INews } from './types';
import { DateEntity } from '../entities/Date';
import ShadowWrapper from '../components/ShadowWrapper';
import ButtonClose from '../components/ButtonClose';
import { NEWS_ROUTE } from '../consts/routes';
import { createPathToListRoute } from '../helpers/createRoute';

const NewsEditPage = (): JSX.Element => {
	const { id } = useParams();
	const language = useSelector(getLanguage);
	const navigate = useNavigate();
	const [news, setNews] = useState<null | INews>(null);
	const [newsRu, setNewsRu] = useState<null | INews>(null);
	const [newsEn, setNewsEn] = useState<null | INews>(null);
	const [reload, setReload] = useState(false);

	const dateTransform = (newsDate: any) => {
		let date = new Date(newsDate);

		let year = date.getFullYear();
		let month = (date.getMonth() + 1).toString().padStart(2, '0');
		let day = date.getDate().toString().padStart(2, '0');
		let hours = date.getHours().toString().padStart(2, '0');
		let minutes = date.getMinutes().toString().padStart(2, '0');
		let seconds = date.getSeconds().toString().padStart(2, '0');
		let formattedDate =
			year +
			'-' +
			month +
			'-' +
			day +
			' ' +
			hours +
			':' +
			minutes +
			':' +
			seconds +
			'.000000';
		return formattedDate;
	};
	const promise = useMemo(async () => {
		const dataUa = await newsService.getById(id!);
		setNews(dataUa);
		if (dataUa?.article?.translations?.length > 1) {
			let ruInfo = dataUa.article.translations.find(
				(el: { language: { code: string } }) => el.language.code === 'ru'
			);
			let enInfo = dataUa.article.translations.find(
				(el: { language: { code: string } }) => el.language.code === 'en'
			);

			if (ruInfo?.id) {
				const dataRu = await newsService.getById(ruInfo.id);
				setNewsRu(dataRu);
			}

			if (enInfo?.id) {
				const dataEn = await newsService.getById(enInfo.id);
				setNewsEn(dataEn);
			}
		}
	}, [reload]);

	
	const getForm = useCallback(() => {
		if (!news) {
			return '';
		}

		const commonData = (newsDate: any, newsData: any) => {
			let formattedDateObj = {
				date: dateTransform(newsDate),
				timezone: 'UTC',
				timezone_type: 3,
			};

			return {
				date: newsDate ? new DateEntity(formattedDateObj) : new DateEntity(),
				id: newsData?.id,
				articleId: news.article.id,
				isPinned: news.article.isPinned,
				priority: news.article.priority,
				onSubmit: () => {
					navigate(createPathToListRoute(NEWS_ROUTE));
				},
			};
		};

		switch (language) {
			case 'ru':
				return (
					<EditNewsForm
						key="ru"
						content={newsRu?.body || ''}
						title={newsRu?.title || ''}
						setReload={setReload}
						reload={reload}
						translationId={newsRu?.article?.id || ''}
						image={
							newsRu?.image
								? `${process.env.REACT_APP_API_URL}/storage/${newsRu?.image?.original?.path}`
								: ''
						}
						{...(commonData(newsRu?.article?.updatedAt, newsRu) || {})}
						slug={newsRu?.slug || ''}
					/>
				);
			case 'en':
				return (
					<EditNewsForm
						key="en"
						content={newsEn?.body || ''}
						title={newsEn?.title || ''}
						setReload={setReload}
						reload={reload}
						translationId={newsEn?.article?.id || ''}
						image={
							newsEn?.image
								? `${process.env.REACT_APP_API_URL}/storage/${newsEn?.image?.original?.path}`
								: ''
						}
						slug={newsEn?.slug || ''}
						{...(commonData(newsEn?.article?.updatedAt, newsEn) || {})}
					/>
				);
			case 'ua':
				return (
					<EditNewsForm
						key="ua"
						content={news.body || ''}
						title={news.title || ''}
						setReload={setReload}
						reload={reload}
						image={
							news.image
								? `${process.env.REACT_APP_API_URL}/storage/${news.image.original.path}`
								: ''
						}
						translationId={news.article.id || ''}
						slug={news.slug}
						{...(commonData(news?.article?.updatedAt, news) || {})}
					/>
				);
			default:
				return null;
		}
	}, [news, newsRu, newsEn, language, navigate]);

	return (
		<PageContainer className="news-edit">
			<ShadowWrapper variant="content" className="news-edit__body">
				<ButtonClose align="right" onClick={() => navigate(-1)} />
				<Loader condition={promise}>
					{news ? <>{getForm()}</> : <span>Не знайдено</span>}
				</Loader>
			</ShadowWrapper>
		</PageContainer>
	);
};

export default NewsEditPage;
