import React, { ChangeEvent, useMemo, useRef } from 'react';
import { Image as ImageEntity } from '../../entities/Image';
import { Image as BootstrapImage, Row } from 'react-bootstrap';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import InputError from '../inputError';
import './index.css';
import ButtonLink from '../ButtonLink';
import { isBoolean, isNull, isNumber } from '../../helpers/typeChecks';
import { validateFileType } from '../../helpers/validateFileType';
import InputLabel from '../inputLabel';
import EditableImage from '../editableImage';
import { Trash } from 'react-bootstrap-icons';
import store from '../../store/store.main';
import { setSliderDeleteImage } from '../../store/common/actions.common';

const ImageInput = (props: IFormFieldProps<undefined>): JSX.Element => {
	const {
		placeholder,
		label,
		labelVariant,
		className,
		setField,
		setError,
		name,
		value,
		error,
		touched,
		project=false
	} = props;
	const inputRef = useRef<HTMLInputElement | null>(null);
	const baseUrl = `${process.env.REACT_APP_API_URL}/storage/`
	const image = useMemo(() => {
		if(project && props.value[0]?.image?.large?.path) {
			return `${baseUrl}${props?.value[0]?.image?.large?.path}`
		}
		if (isBoolean(value) || isNumber(value)) return '';
		return new ImageEntity(props.value).toString;
	}, [props.value]);

	const onImageChoose = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0];
		if (!isNull(file)) {
			setField?.(name, file);
			setError?.(name, '');
		}
		e.target.value = '';
	};

	return (
		<div className="image-input">
			{label && (
				<InputLabel
					labelVariant={labelVariant}
					label={label}
					className="image-input__label"
				/>
			)}
			{image ? (
				<div className="flex-row d-flex">
					<div className="image-input__wrapper">
						<BootstrapImage
							style={{ width: 300 }}
							src={image}
							alt="user photo"
						/>
						<Trash
							color="red"
							className="trashBox editable-image__svg"
							onClick={(e) => {
								if (project && props.value[0]) {
									store.dispatch(setSliderDeleteImage( {image: props.value[0], index: 0} as any))
								}
								e.stopPropagation();
								setField(name, '');
							}}
						/>
						<label
							htmlFor="main-photo"
							className="image-input__change editable-image"
						>
							<p className="button-link button-link--small editable-image__button">
								Змінити
							</p>
							<input
								id="main-photo"
								style={{
									display: 'none',
								}}
								type="file"
								accept=".jpg, .jpeg, .png"
								onChange={(e) => {
									const file = e?.target?.files?.[0];
									if (project && props.value[0]) {
										store.dispatch(setSliderDeleteImage({image: props.value[0], index: 0} as any))
									}
									if (!isNull(file) && validateFileType(file, 'image')) {
										onImageChoose(e);
									}
								}}
							/>
						</label>
					</div>
				</div>
			) : (
				<>
					<ButtonLink
						variant="small"
						onClick={() => {
							inputRef.current?.click();
						}}
						className="image-input__button"
					>
						+ Додати фото
					</ButtonLink>
					<input
						ref={inputRef}
						className={className + ' rootInput'}
						type="file"
						placeholder={placeholder}
						accept=".jpg, .jpeg, .png"
						onChange={(e) => onImageChoose(e)}
					/>
				</>
			)}
			<InputError
				error={error}
				condition={!!(error && touched)}
				className="mb-2"
			/>
		</div>
	);
};

export default ImageInput;
