import {toast, ToastOptions} from 'react-toastify';

export type ToastTypes = 'error' | 'info' | 'success' | 'warning' | 'default';

const defaultConfig: ToastOptions = {
	position: 'top-right',
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	type: 'default',
	draggable: true,
};

export const setToast = (message: string, type: ToastTypes): void => {
	toast(message, {
		...defaultConfig,
		type,
	});
};
