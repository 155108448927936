import React, { useEffect, useRef, useState } from 'react';
import { Editor as TinymceEditor } from '@tinymce/tinymce-react';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import InputError from '../inputError';
import InputLabel from '../inputLabel';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../store/user/user.selectors';
import { getLanguage } from '../../store/common/selectors.common';
import { useLocation } from 'react-router-dom';

const Editor = (props: IFormFieldProps<undefined>): JSX.Element => {
	const {
		setField,
		name,
		value,
		error,
		label,
		labelVariant,
		touched,
		setTouched,
		touchedCont,
		initialValue,
	} = props;
	const token = useSelector(getAccessToken);
	const language = useSelector(getLanguage);
	let content_name = `content_${language}`;
	const handleEditorChange = (content: any) => {
		if (value !== content) {
			setField(name, content, true);
		}
	};
	const location = useLocation();
	const editorRef = useRef<any>(null);
	const [isEditorReady, setIsEditorReady] = useState(false);
	useEffect(() => {
		if (isEditorReady && editorRef.current) {
			editorRef.current.setContent(value);
			setField(name, value, true);
		}
	}, [isEditorReady, initialValue, name]);
	return (
		<div
			className={
				!!(
					error &&
					(location.pathname.includes('edit')
						? touched
						: touchedCont[content_name])
				)
					? 'editor-input editor-input--error'
					: 'editor-input'
			}
		>
			{label && <InputLabel labelVariant={labelVariant} label={label} />}
			<TinymceEditor
				onEditorChange={handleEditorChange}
				apiKey="4es5un76iybplggpp1iqqfz9y3h05u2ocyg3d9oj2cjt3htn"
				//4es5un76iybplggpp1iqqfz9y3h05u2ocyg3d9oj2cjt3htn
				// value={initialValue[name] || ''}
				initialValue=""
				// value={value || ''}
				onInit={(evt, editor) => {
					editorRef.current = editor;
					setIsEditorReady(true);
				}}
				onBlur={() => setTouched(name, '', '')}
				init={{
					height: 500,
					menubar: 'file edit view insert format tools table help',
					resize: false,
					statusbar: false,
					image_title: true,
					images_upload_base_path: ``,
					images_upload_url: `${process.env.REACT_APP_API_URL}/api/admin/file/save`,
					images_upload_handler: function (blobInfo, success, failure) {
						let xhr: XMLHttpRequest, formData;
						xhr = new XMLHttpRequest();
						xhr.open(
							'POST',
							`${process.env.REACT_APP_API_URL}/api/admin/file/save`
						);
						xhr.setRequestHeader('Authorization', 'Bearer ' + token);
						xhr.onload = function () {
							let json;
							if (xhr?.status != 200) {
								failure('HTTP Error: ' + xhr?.status);
								return;
							}
							success(
								`${process.env.REACT_APP_API_URL}/storage/${xhr.response
									.split('{"path":"')[1]
									.slice(0, -2)}`
							);
						};

						formData = new FormData();
						formData.append('file', blobInfo.blob(), blobInfo.filename());

						xhr.send(formData);
					},
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount',
						'image code',
					],
					toolbar:
						'insertfile undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons',

					content_style:
						'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
				}}
			/>
			{error && (
				<InputError
					error={error}
					condition={!!(error && touchedCont[content_name])}
				/>
			)}
		</div>
	);
};

export default Editor;

// import React from 'react';
// import { Editor as TinymceEditor } from '@tinymce/tinymce-react';
// import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
// import InputError from '../inputError';
// import InputLabel from '../inputLabel';
// import { useSelector } from 'react-redux';
// import { getAccessToken } from '../../store/user/user.selectors';

// const Editor = (props: IFormFieldProps<undefined>): JSX.Element => {
// 	const { setField, name, value, error, label, labelVariant, touched } = props;
// 	const token = useSelector(getAccessToken);

// 	return (
// 		<div
// 			className={error ? 'editor-input editor-input--error' : 'editor-input'}
// 		>
// 			{label && <InputLabel labelVariant={labelVariant} label={label} />}
// 			<TinymceEditor
// 				onEditorChange={(newValue, editor) => {
// 					setField(name, editor.getContent(), true);
// 				}}
// 				apiKey="11v0rofi4f7jff4ic4zckprby8eizt2euq3mru49jtjwtp1i"
// 				value={value || ''}
// 				init={{
// 					height: 500,
// 					menubar: 'file edit view insert format tools table help',
// 					resize: false,
// 					statusbar: false,
// 					image_title: true,
// 					images_upload_base_path: ``,
// 					images_upload_url: `${process.env.REACT_APP_API_URL}/api/admin/file/save`,
// 					images_upload_handler: function (blobInfo, success, failure) {
// 						let xhr: XMLHttpRequest, formData;
// 						xhr = new XMLHttpRequest();
// 						xhr.open(
// 							'POST',
// 							`${process.env.REACT_APP_API_URL}/api/admin/file/save`
// 						);
// 						xhr.setRequestHeader('Authorization', 'Bearer ' + token);
// 						xhr.onload = function () {
// 							let json;
// 							if (xhr?.status != 200) {
// 								failure('HTTP Error: ' + xhr?.status);
// 								return;
// 							}
// 							success(
// 								`${process.env.REACT_APP_API_URL}/storage/${xhr.response
// 									.split('{"path":"')[1]
// 									.slice(0, -2)}`
// 							);
// 						};

// 						formData = new FormData();
// 						formData.append('file', blobInfo.blob(), blobInfo.filename());

// 						xhr.send(formData);
// 					},
// 					plugins: [
// 						'advlist autolink lists link image charmap print preview anchor',
// 						'searchreplace visualblocks code fullscreen',
// 						'insertdatetime media table paste code help wordcount',
// 						'image code',
// 					],
// 					toolbar:
// 						'insertfile undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons',

// 					content_style:
// 						'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
// 				}}
// 			/>
// 			{error && <InputError error={error} condition={!!(error && touched)} />}
// 		</div>
// 	);
// };

// export default Editor;
