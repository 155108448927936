import React from 'react';
import DocumentPreview from './documentPreview';
import { TDocument } from '../types';
import { IListItem } from '../../interfaces/IListItem';

type TDocumentListItemProps = TDocument & IListItem<TDocument>;

const DocumentListItem = (props: TDocumentListItemProps): JSX.Element => {
	const {
		name,
		onDeleteItem,
		id,
		document
	} = props;

	return (
		<DocumentPreview
			name={name as string}
			onDelete={onDeleteItem}
			id={id as string}
			documentID={document.id as unknown as string} 
		/>
	);
};

export default DocumentListItem;
