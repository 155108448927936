import React from 'react';
import InputLabel from '../inputLabel';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import InputError from '../inputError';
import ReactSwitch from 'react-switch';

const SwitcherInput = (props: IFormFieldProps<undefined>): JSX.Element => {
	const {
		label,
		labelVariant,
		options: initialOptions,
		error,
		name,
		value,
		setField,
		className = '',
		touched = false,
	} = props;
	return (
		<div className="switch-input">
			{label && (
				<InputLabel
					labelVariant={labelVariant}
					label={label}
					className="switch-input__label"
				/>
			)}
			<ReactSwitch
				className="switch-input__control"
				checked={!!value}
				onChange={(newValue) => {
					setField(name, newValue, true);
				}}
				onColor="#457292"
				boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
				activeBoxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
				uncheckedIcon={false}
				checkedIcon={false}
			/>
			<InputError condition={!!(error && touched)} error={error} />
		</div>
	);
};

export default SwitcherInput;
