import * as yup from 'yup';
import {isRequiredLang} from '../helpers/isRequiredLang';
import {AnyObject, Maybe, Optionals} from 'yup/lib/types';
import {TLang} from '../interfaces/TLang';
import {AnySchema} from 'yup/lib/schema';
import Lazy from 'yup/lib/Lazy';
import {Asserts, TypeOf} from 'yup/lib/util/types';

yup.addMethod<yup.StringSchema>(yup.string, 'requiredByLang', function (lang: TLang, errorMessage: string) {
	return this.test('test-required-by-lang', errorMessage, function (value) {
		const {path, createError} = this;
		return !isRequiredLang(lang) || !!value || createError({path, message: errorMessage});
	});
});

yup.addMethod(yup.array, 'unique', function(mapper = (a: any) => a, message: string) {
	return this.test('unique', message, function(list) {
		return list?.length  === new Set(list?.map(mapper)).size;
	});
});

declare module 'yup' {
	interface StringSchema<
		TType extends Maybe<string> = string | undefined,
		TContext extends AnyObject = AnyObject,
		TOut extends TType = TType
		> extends yup.BaseSchema<TType, TContext, TOut> {
		requiredByLang(lang: TLang, errorMessage: string): StringSchema<TType, TContext>;
	}

	interface NumberSchema<
		TType extends Maybe<number> = number | undefined,
		TContext extends AnyObject = AnyObject,
		TOut extends TType = TType
		> extends yup.BaseSchema<TType, TContext, TOut> {
		requiredByLang(): NumberSchema<TType, TContext>;
	}

	interface ArraySchema<
		T extends AnySchema | Lazy<any, any>,
		C extends AnyObject = AnyObject,
		TIn extends Maybe<TypeOf<T>[]> = TypeOf<T>[] | undefined,
		TOut extends Maybe<Asserts<T>[]> = Asserts<T>[] | Optionals<TIn>
	> extends yup.BaseSchema<TIn, C, TOut> {
		unique(mapper: (a: any) => any, errorMessage: string): ArraySchema<T, C>;
	}
}

export default yup;
