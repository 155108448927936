import {Config} from './Config';
import {appendToFormData} from './FormData';

export interface IField <FieldType>{
	execute: (config: Config<FieldType>) => void;
}

export class ObjectField implements IField<any> {
	execute(config: Config<any>) {
		if (config.config.stringifyObjects) {
			return config.formData.append(config.key, JSON.stringify(config.value));
		}
		Object.keys(config.value).forEach((nestedKey: string) => appendToFormData(
			new Config(
				`${config.key}[${nestedKey}]`,
				config.value[nestedKey],
				config.config,
				config.formData
			)
		));
	}
}

export class DefaultField implements IField<number | string> {
	execute(config: Config<number | string>) {
		if (config.value !== '') {
			config.formData.append(config.key, config.value.toString());
		}
	}
}

export class FileField implements IField<File> {
	execute(config: Config<File>) {
		config.formData.append(config.key, config.value, config.value.fileName);
	}
}

export class DateField implements IField<Date> {
	execute(config: Config<Date>) {
		config.formData.append(config.key, config.value.toISOString());
	}
}

export class UndefinedField implements IField<undefined> {
	execute(config: Config<undefined>) {
		if (config.config.undefinedAsNull) {
			config.formData.append(config.key, 'null');
		}
	}
}

export class NullField implements IField<null> {
	execute(config: Config<null>) {
		if (!config.config.nullAsUndefined) {
			config.formData.append(config.key, 'undefined');
		}
	}
}

export class BooleanField implements IField<boolean> {
	execute(config: Config<boolean>) {
		if (config.config.booleanAsInteger) {
			return config.formData.append(config.key, (config.value ? 1 : 0).toString());
		}
		config.formData.append(config.key, config.value.toString());
	}
}

export class ArrayField implements IField<[]> {
	execute(config: Config<[]>) {
		if (config.value.length) {
			return config.value.forEach((nestedField: any, index: number) => {
				const updatedKey = config.key + `[${config.config.useIndices ? index : ''}]`;
				appendToFormData(new Config(updatedKey, nestedField, config.config, config.formData));
			});
		}
		if (config.config.allowEmptyArray) {
			config.formData.append(config.key + '[]', '');
		}
	}
}
