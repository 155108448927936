import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LANG_CONFIG } from '../../consts/common';
import { setLanguage } from '../../store/common/actions.common';
import { getForm, getLanguage } from '../../store/common/selectors.common';
import store from '../../store/store.main';

const LanguagePicker = (): JSX.Element => {
	const language = useSelector(getLanguage);
	const form = useSelector(getForm);
	const location = useLocation();
	return (
		<ul className="language-picker">
			{LANG_CONFIG.map((lang) => (
				<li key={lang.name} className="language-picker__item">
					<button
						className={`language-picker__button ${
							lang.name === language ? 'active' : ''
						}`}
						onClick={() => {
							store.dispatch(setLanguage(lang.name));
							if (
								form.dirty &&
								location.pathname.includes('/edit') &&
								(location.pathname.includes('project') ||
									location.pathname.includes('news'))
							) {
								const confirmed = window.confirm(
									'Чи хочете ви зберегти переклад?'
								);
								if (confirmed) {
									form.handleSubmit();
								} else {
									return '';
								}
							}
							return;
						}}
					>
						{lang.label}
					</button>
				</li>
			))}
		</ul>
	);
};

export default LanguagePicker;
