import { NewsRepository } from './news.repository';
import { TCreateNews, TEditNews } from './types';
import { setToast } from '../helpers/setToast';
import { TPagination } from '../interfaces/TPagination';
import {
	DELETE_ERROR_TEXT,
	FETCH_ERROR_TEXT,
	UPLOAD_FILE_ERROR,
} from '../consts/errorMessages';

export class NewsService {
	private readonly repository: NewsRepository;
	constructor() {
		this.repository = new NewsRepository();
	}

	async create(data: TCreateNews, cb : any) {
		const q = await this.repository
			.create(data)
			.then((data) => {
				cb(data)
				setToast('Новина успішно створена', 'info');
			})
			.catch((error) => {
				if(error.fields.slug) {
					setToast(`${error.fields.slug} - slug`, 'error');
				}
				if(error.fields.title) {
					setToast(`${error.fields.slug} - title`, 'error');
				}
				if(error.fields.body) {
					setToast(`${error.fields.slug} - body`, 'error');
				}
				setToast(error.message, 'error');
				throw error;
			});
		return q
	}


	async createTranslation(language: string, id: number | string, data: TCreateNews) {
		await this.repository
			.createTranslation(language, id, data)
			.then(() => {
				setToast('Новина успішно створена', 'info');
			})
			.catch((error) => {
				if(error.fields.slug) {
					setToast(`${error.fields.slug} - slug(${language})`, 'error');
				}
				setToast(error.message, 'error');
				throw error;
			});
	}

	async edit(id: string, data: TEditNews) {
		await this.repository
			.edit(id, data)
			.then(() => {
				setToast('Новина успішно оновлена', 'info');
			})
			.catch((error) => {
				if(error.fields.slug) {
					setToast(`${error.fields.slug} - slug`, 'error');
				}
				setToast(error.message || 'Помилка при редагуванні', 'error');
				throw error;
			});
	}

	async delete(id: string) {
		await this.repository
			.delete(id)
			.then(() => {
				setToast('Новина успішно видалена', 'info');
			})
			.catch((error) => {
				setToast(error?.message || DELETE_ERROR_TEXT, 'error');
				throw error;
			});
	}

	async pin(id: string) {
		await this.repository
			.pin(id)
			.then(() => {
				setToast('Новина успішно закріплена', 'info');
			})
			.catch((error) => {
				setToast(error?.message || 'Помилка при закріпленні', 'error');
				throw error;
			});
	}

	async getById(id: string) {
		return this.repository.getById(id).catch((error) => {
			setToast(error.message, 'error');
			throw error;
		});
	}

	async getAll(language = 'ua', pagination: TPagination) {
		const result = await this.repository.getAll(language, pagination).catch((error) => {
			setToast(error.messag || FETCH_ERROR_TEXT, 'error');
			throw error;
		});

		return {
			entities: result.articles,
			pagination: result.pagination,
		};
	}
}

export const newsService = new NewsService();
