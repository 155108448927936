import React from 'react';

interface propsBackgroundLogin {
	className?: string,
}

const BackgroundLogin = ({ className }: propsBackgroundLogin) => {
	return (
		<div className={`background-login ${className}`} />
	);
};

export default BackgroundLogin;
