import React from 'react';
import ListWithPagination from '../components/listWithPagination';
import ProjectListItem from './components/projectListItem';
import { projectService } from './project.service';
import PageContainer from '../components/PageContainer';
import { useNavigate } from 'react-router-dom';
import { PROJECT_ROUTE } from '../consts/routes';
import { createPathToCreateRoute } from '../helpers/createRoute';
import ButtonLink from '../components/ButtonLink';

const ProjectsListPage = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<PageContainer>
			<ButtonLink
				variant="big"
				onClick={() => {
					navigate(createPathToCreateRoute(PROJECT_ROUTE));
				}}
			>
				Додати новий проект
			</ButtonLink>
			<ListWithPagination
				Element={ProjectListItem}
				endpoint={projectService.getAll.bind(projectService)}
				type="row"
				title="Завантажені проекти"
			/>
		</PageContainer>
	);
};

export default ProjectsListPage;
