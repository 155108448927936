import React, { useRef } from 'react';
import { isNull } from '../../helpers/typeChecks';
import { validateFileType } from '../../helpers/validateFileType';
import { Trash } from 'react-bootstrap-icons';
import ButtonLink from '../ButtonLink';
import store from '../../store/store.main';
import { setSliderDeleteImage } from '../../store/common/actions.common';

type TEditableImage = {
	src: string | null;
	onChange: (image: File) => void;
	alt?: string;
	editText?: string;
	onDelete?: () => void;
	leftBottomCorner?: string | null;
	rightBottomCorner?: string | null;
	className?: string;
};

const EditableImage = (props: TEditableImage): JSX.Element => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const {
		src,
		onChange,
		onDelete,
		editText = 'Змінити',
		alt,
		leftBottomCorner,
		rightBottomCorner,
		className,
	} = props;
	return (
		<div
			className={
				src !== null
					? `${className} editable-image`
					: `${className} editable-image editable-image--no-image`
			}
			onClick={(e) => {
				inputRef?.current?.click();
			}}
		>
			<ButtonLink variant="small" className="editable-image__button">
				{editText}
			</ButtonLink>
			{onDelete && (
				<Trash
					color="red"
					className="trashBox editable-image__svg"
					onClick={(e) => {
						e.stopPropagation();
						onDelete();
					}}
				/>
			)}
			{leftBottomCorner && (
				<span className="editable-image__leftBottomCorner textInImage">
					{leftBottomCorner}
				</span>
			)}
			{rightBottomCorner && (
				<span className="editable-image__rightBottomCorner textInImage">
					{rightBottomCorner}
				</span>
			)}
			{src ? <img src={src as string} alt={alt} width={200} /> : <div></div>}
			<input
				type="file"
				accept=".jpg, .jpeg, .png"
				hidden
				ref={inputRef}
				onChange={(e) => {
					const file = e?.target?.files?.[0];
					if (className === 'project-list-item__image') {
						store.dispatch(setSliderDeleteImage({image: file, index: 0} as any))
					}
					if (!isNull(file) && validateFileType(file, 'image')) {
						onChange(file!);
					}
					e.target.value = '';
				}}
			/>
		</div>
	);
};

export default EditableImage;
