import React, {MouseEventHandler, ReactNode} from 'react';

interface propsButtonLink {
	children: ReactNode;
	className?: string;
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
	variant: 'login' | 'small' | 'big',
}

const ButtonLink = ({
	children,
	className,
	onClick,
	variant
}: propsButtonLink) => {
	return (
		<button
			className={`button-link button-link--${variant} ${className}`}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default ButtonLink;
