import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { Link } from 'react-router-dom';
import companies from '../data/companies';
import store from '../store/store.main';
import { setSite } from '../store/common/actions.common';

import { getSiteId } from '../store/common/selectors.common';
import { DOCUMENT_ROUTE } from '../consts/routes';

interface propsLogoDropdown {
	className?: string;
}

const LogoDropdown = ({ className }: propsLogoDropdown) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const refDropdown = useRef<HTMLButtonElement>(null);
	const navigate = useNavigate();
	const siteId = getSiteId(store.getState());

	const handleCloseDropdown = () => {
		setIsDropdownOpen(false);
	};

	useOnClickOutside(refDropdown, handleCloseDropdown);

	return (
		<div
			className={
				isDropdownOpen
					? `${className} logo-dropdown open`
					: `${className} logo-dropdown`
			}
		>
			<div className="logo-dropdown__body">
				<div className="logo-dropdown__logo">
					<Link to="/" className="logo-dropdown__logo-link">
						<img
							src={companies.find((company) => siteId === company.id)?.image}
							alt="Logo"
						/>
					</Link>
					<button
						ref={refDropdown}
						className="logo-dropdown__logo-arrow"
						onClick={() =>
							isDropdownOpen
								? setIsDropdownOpen(false)
								: setIsDropdownOpen(true)
						}
					/>
				</div>

				<ul className="logo-dropdown__list">
					{companies.map((company, index) => (
						<li
							key={index}
							className="logo-dropdown__item"
							onClick={() => {
								store.dispatch(setSite(company.id));
								navigate(DOCUMENT_ROUTE);
								window.location.reload();
							}}
						>
							<div className="logo-dropdown__link">
								<div className="logo-dropdown__item-logo">
									<img src={company.image} alt={company.name} />
								</div>
								<div className="logo-dropdown__item-name">
									{company.type} <b>{company.name}</b>
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default LogoDropdown;
