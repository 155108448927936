import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { defaultFormikConfig } from '../../components/form/config';
import { TCreateMixinCountryCategory } from '../types';
import { TCreateFormProps } from '../../interfaces/TCreateFormProps';
import createInput from '../../helpers/createInput';
import { LANG_CONFIG } from '../../consts/common';
import Button from '../../components/button';
import { validationSchema } from '../validationSchema';
import ButtonClose from '../../components/ButtonClose';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const initialValues = {
	name_en: '',
	name_ru: '',
	name_ua: '',
};

const CreateForm = (props: TCreateFormProps): JSX.Element => {
	const { onSuccess, additionalProps, service, onClose } = props;
	let createRequestInfo : any
	let categoryName = service.repository.routeName
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const form = useFormik<TCreateMixinCountryCategory>({
		...defaultFormikConfig,
		validationSchema,
		initialValues: {
			...initialValues,
			name: '',
			isEnabled: '',
			priority: ''
		},
		onSubmit: (values, { setErrors }) => {
			setIsDisabled(true);
			service
			.create('ua', {name: values.name_ua as string, isEnabled: '1'}, (data: any) => {
				createRequestInfo = data
			})
			.then((data) => {
				if(values?.name_ru) {
					service
					.createTranslation('ru', createRequestInfo.data[categoryName].id, {
						name: values.name_ru,
						isEnabled: '1',
						priority: '0'
					})
				}
				if(values?.name_en) {
					service
					.createTranslation('en', createRequestInfo.data[categoryName].id, {
						name: values.name_en,
						isEnabled: '1',
						priority: '0'
					})
				}
			})
				.then(() => {
					onSuccess();
				})
				.catch((error) => {
					setErrors({
						...error.fields,
					});
				})
				.finally(() => {
					setIsDisabled(false);
				});
		},
	});

	const refAddForm = useRef<HTMLDivElement>(null);
	useOnClickOutside(refAddForm, onClose);

	return (
		<div className="category-form">
			<div ref={refAddForm} className="category-form__body">
				<ButtonClose align="right" onClick={() => onClose()} />
				{LANG_CONFIG.map((language) =>
					createInput(form, {
						key: 'name_' + language.name,
						label: additionalProps.name + ' ' + language.label,
						labelVariant: 'bold',
						type: 'text',
					})
				)}
				<div className="category-form__footer">
					<Button isDisabled={isDisabled} onClick={form.submitForm}>
						{additionalProps.createText}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default CreateForm;
