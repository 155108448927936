import React, { useEffect, useState } from 'react';
import createInput from '../../helpers/createInput';
import Button from '../../components/button';
import FormItem from '../../components/FormItem';
import { useFormik } from 'formik';
import { TEditNews } from '../types';
import { defaultFormikConfig } from '../../components/form/config';
import Yup from '../../entities/Yup';
import { newsService } from '../news.service';
import { DateEntity } from '../../entities/Date';
import { useNavigate } from 'react-router-dom';
import { createPathToListRoute } from '../../helpers/createRoute';
import { NEWS_ROUTE } from '../../consts/routes';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../store/common/selectors.common';
import store from '../../store/store.main';
import { setForm } from '../../store/common/actions.common';

interface IEditNewsForm {
	id: string;
	date: DateEntity;
	content: string;
	title: string;
	image: string | File;
	onSubmit: () => void;
	slug?: string;
	translationId: string | '';
	isPinned: string;
	priority: string;
	articleId: string;
	key?: string;
	setReload?: any;
	reload?: any;
}

const validationSchema = Yup.object().shape({
	title: Yup.string()
		.required('Вкажіть заголовок')
		.max(200, 'Макс. кількість 200 символи'),
	content: Yup.string()
		.required('Вкажіть текст новини')
		.max(15000, 'Макс. кількість 15000 символи'),
	date: Yup.string().required('Вкажіть дату'),
	image: Yup.mixed().required('Завантажте зображення'),
	slug: Yup.string()
		.matches(
			/^[a-z0-9]+(?:-[a-z0-9]+)*$/,
			'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link'
		)
		.min(3, 'Мін.кількість символів 3')
		.max(200, 'Макс. кількість 200 символи'),
});

const EditNewsForm = (props: IEditNewsForm): JSX.Element => {
	const {
		id,
		content,
		title,
		date,
		image,
		onSubmit,
		slug,
		isPinned,
		priority,
		translationId,
		articleId,
		setReload,
		reload,
	} = props;
	const navigate = useNavigate();

	const language = useSelector(getLanguage);
	const [shouldRedirect, setShouldRedirect] = useState(false);

	useEffect(() => {
		const handlePopState = (event: any) => {
			const confirmed = window.confirm(
				'Ви впевнені що хочете покинути сторінку?'
			);
			if (!confirmed) {
				// Push the current state back to the history stack
				window.history.pushState(
					null,
					null as any as string,
					window.location.href
				);
			} else {
				navigate('/news');
			}
		};

		// Add the event listener for popstate
		window.addEventListener('popstate', handlePopState);

		// Push the initial state to the history stack
		window.history.pushState(null, null as any as string, window.location.href);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	useEffect(() => {
		const handleBeforeUnload = (event: any) => {
			event.preventDefault();
			event.returnValue = ''; // Сучасні браузери показують стандартне повідомлення
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const form = useFormik<TEditNews>({
		...defaultFormikConfig,
		initialValues: {
			date: date.date,
			title,
			content,
			image,
			slug: slug as string,
			body: '',
			isEnabled: '',
			isPinned: '',
			priority: '',
			updatedAt: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values: TEditNews, { setErrors }) => {
			setIsDisabled(true);
			if (id) {
				newsService
					.edit(id, {
						title: values.title,
						body: values.content as string,
						date: values.date,
						image: values.image,
						slug: values.slug ? (values.slug as string) : (slug as string),
						isEnabled: '1',
						isPinned: isPinned === 'true' ? '1' : '0',
						priority: priority,
						updatedAt: new Date(values.date as Date).toLocaleDateString(
							'fr-FR'
						),
					})
					.then(() => {
						setIsDisabled(false);
						if (shouldRedirect) {
							navigate(createPathToListRoute(NEWS_ROUTE));
						} else {
							setReload(!reload);
						}
					})
					.catch((error) => {
						setErrors({
							...error.fields,
						});
						setIsDisabled(false);
					});
			} else {
				newsService
					.createTranslation(language, articleId, {
						title: values.title,
						body: values.content as string,
						date: values.date,
						image: values.image as File,
						slug: values.slug ? (values.slug as string) : (slug as string),
						isEnabled: '1',
						isPinned: isPinned === 'true' ? '1' : '0',
						priority: priority,
						updatedAt: new Date(values.date as Date).toLocaleDateString(
							'fr-FR'
						),
					})
					.then(() => {
						setIsDisabled(false);
						if (shouldRedirect) {
							navigate(createPathToListRoute(NEWS_ROUTE));
						} else {
							setReload(!reload);
						}
					})
					.catch((error) => {
						setErrors({
							...error.fields,
						});
						setIsDisabled(false);
					});
			}
		},
	});
	useEffect(() => {
		store.dispatch(setForm(form));
		return () => {
			store.dispatch(setForm({}));
		};
	}, [form]);

	return (
		<form className="news-form" onSubmit={(e) => e.preventDefault()}>
			<div className="news-form__header">
				{createInput(form, {
					key: `title`,
					type: 'text',
					label: 'Заголовок*',
					labelVariant: 'bold',
					className: 'news-form__input-title',
				})}
				{createInput(form, {
					key: 'date',
					type: 'datepicker',
					label: 'Дата*',
					labelVariant: 'bold',
					className: 'news-form__input-datepicker',
				})}
			</div>
			<FormItem>
				{createInput(form, {
					key: 'image',
					type: 'image',
					label: 'Головне зображення новини*',
					labelVariant: 'bold',
				})}
			</FormItem>
			{createInput(form, {
				key: 'content',
				type: 'editor',
				label: 'Текст новини*',
				labelVariant: 'bold',
			})}
			{createInput(form, {
				key: 'slug',
				type: 'text',
				label: 'slug*',
				labelVariant: 'bold',
				className: 'news-form__input-title',
			})}
			<div className="news-form__footer">
				<Button
					isDisabled={isDisabled}
					onClick={() => {
						setShouldRedirect(true);
						form.handleSubmit();
					}}
				>
					Зберегти
				</Button>
			</div>
		</form>
	);
};

export default EditNewsForm;
