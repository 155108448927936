import { ApiService } from '../services/ApiService';
import { HttpMethods } from '../enums/HttpMethods';
import {
	TCreateMixinCountryCategory,
	TEditMixinCountryCategory,
} from './types';
import { addObjectToFormData } from '../helpers/formData/FormData';
import { TPagination } from '../interfaces/TPagination';
import { createQueryParams } from '../helpers/createQueryParams';
import { TCategory } from '../category/type';
import { TCountry } from '../country/type';
import { getLanguage, getSiteId } from '../store/common/selectors.common';
import store from '../store/store.main';
import { workspace } from '../consts/workspace';

export class Repository {
	routeName: string;
	private readonly apiService: ApiService;
	workspace: string;
	language: string;
	constructor(routeName: string) {
		this.routeName = routeName;
		this.apiService = new ApiService();
		this.workspace =  workspace[getSiteId(store.getState()) - 1]
		this.language = getLanguage(store.getState())
	}



	async getAll(language: string, pagination: TPagination, project = false) {
		let query = project ? `?perPage=100` : createQueryParams(pagination)
		const response = await this.apiService.request(
			`/admin/${this.routeName}/all` + query,
			{
				method: HttpMethods.GET,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${language}`,
				}
			}
		);

		let result;
		let countryName = `name_` + language
		if (this.routeName === 'category') {
			result = response?.data?.categories?.map((item: TCategory) => ({
				id: item.id,
				name_en: item.category_en,
				name_ru: item.category_ru,
				name_ua: item.name,
				name: item.name,
				category: item.category
			}));
		} else {
			result = response?.data?.countries?.map((item: TCountry) => ({
				
				id: item.id,
				name_en: item.country_en,
				name_ru: item.country_ru,
				name_ua: item.name,
				name: item.name,
				country: item.country
			}));
		}

		return {
			entities: result,
			pagination: response.data.pagination,
		};
	}

	async getOne(id: string) {
		const response = await this.apiService.request(`/admin/${this.routeName}/translation/${id}/get`, {
			method: HttpMethods.GET,
			headers: {
				'X-Workspace': `${this.workspace}`,
			}
		});
		return response.data
	}


	async edit(id: string, data: TEditMixinCountryCategory) {
		const language = getLanguage(store.getState());

		const formData = addObjectToFormData(data);
		await this.apiService.request(`/admin/${this.routeName}/translation/${id}/update`, {
			method: HttpMethods.POST,
			data: formData,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});
	}

	async createTranslation(language : string, id : string, data: TCreateMixinCountryCategory) {
		const formData = addObjectToFormData(data);
		await this.apiService.request(
			`admin/${this.routeName}/${id}/translation/create`,
			{
				method: HttpMethods.POST,
				data: formData,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${language}`,
				}
			}
		);
	}

	async create(language: string, data: TCreateMixinCountryCategory) {
		const siteId = getSiteId(store.getState());
		const formData = addObjectToFormData(data);
		const q = await this.apiService.request(
			`/admin/${this.routeName}/create`,
			{
				method: HttpMethods.POST,
				data: formData,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${language}`,
				}
			}
		);
		return q
	}

	async delete(id: string) {
		await this.apiService.request(`/admin/${this.routeName}/${id}/delete/`, {
			method: HttpMethods.DELETE,
			headers: {
				'X-Workspace': `${this.workspace}`,
			}
		});
	}
}
