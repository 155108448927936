import {AxiosError} from 'axios';

export type TApiError = {
	message: string;
	fields: {[key: string]: string};
}

const defaultMessageError = 'Что-то пошло не так';

export const convertApiError = (error: AxiosError): TApiError => {
	const result = {
		message: error.response?.data?.message || error.response?.data?.errors || defaultMessageError,
		fields: {},
	};


	const errors = error?.response?.data?.errors;
	if (errors) {
		result.fields = Object.keys(errors).reduce((acc: {[key: string]: string}, key) => {
			acc[key] = errors[key][0];
			return acc;
		}, {});
	}
	return result;
};
