import React, { useState } from 'react';
import { useFormik } from 'formik';
import { validationSchema } from '../validationSchema';
import { defaultFormikConfig } from '../../components/form/config';
import createInput from '../../helpers/createInput';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../store/common/selectors.common';
import Button from '../../components/button';
import { vacancyService } from '../vacancy.service';
import { TCreateVacancy } from '../types';
import ShadowWrapper from '../../components/ShadowWrapper';
import ButtonClose from '../../components/ButtonClose';
import { useNavigate } from 'react-router-dom';

const initialValues = {
	title_ua: '',
	content_ua: '',
	title_ru: '',
	content_ru: '',
	title_en: '',
	content_en: '',
	slug_ua: '',
	slug_ru: '',
	slug_en: '',
};

type TCreateVacancyForm = {
	onSuccess: () => void;
};

const CreateVacancyForm = (props: TCreateVacancyForm): JSX.Element => {
	const { onSuccess } = props;
	const language = useSelector(getLanguage);
	const navigate = useNavigate();
	let createRequestInfo: { data: { vacancy: { id: string; }; }; }
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const form = useFormik<TCreateVacancy>({
		...defaultFormikConfig,
		initialValues: {
			...initialValues,
			title: '',
			body: undefined,
			isEnabled: 0,
			priority: 0,
			slug: ''
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setErrors, resetForm }) => {
			setIsDisabled(true);
			vacancyService
				.create(language, {
					title: values.title_ua,
					body: values.content_ua,
					isEnabled: 1,
					slug: values.slug_ua as string,
					priority: 0
				},  (data: any) => {
					createRequestInfo = data

				})
				.then((data) => {
					if(values?.title_ru && values?.slug_ru) {
						vacancyService
						.createTranslation('ru', createRequestInfo.data.vacancy.id, {
							title: values.title_ru,
							body: values.content_ru,
							isEnabled: 1,
							slug: values.slug_ru,
							priority: 0
						})
					}
					if(values?.title_en && values?.slug_en) {
						vacancyService
						.createTranslation('en', createRequestInfo?.data?.vacancy.id, {
							title: values.title_en,
							body: values.content_en,
							isEnabled: 1,
							slug: values.slug_en,
							priority: 0
						})
					}
				})
				.then(() => {
					onSuccess();
					resetForm();
				})
				.catch((error) => {
					setErrors({
						...error?.fields,
					});
				})
				.finally(() => {
					setIsDisabled(false);
				});
		},
	});

	return (
		<div className="vacancy-form">
			<ShadowWrapper variant="content" className="vacancy-form__body">
				<ButtonClose align="right" onClick={() => navigate(-1)} />
				{createInput(form, {
					key: 'title_' + language,
					label: 'Назва вакансії*',
					labelVariant: 'bold',
					type: 'text',
				})}
				{createInput(form, {
					key: 'content_' + language,
					label: 'Текст вакансії',
					labelVariant: 'bold',
					type: 'editor',
				})}
				{createInput(form, {
					key: 'slug_' + language,
					label: 'slug*',
					labelVariant: 'bold',
					type: 'text',
				})}
				<div className="vacancy-form__footer">
					<Button isDisabled={isDisabled} onClick={form.submitForm}>
						Зберегти
					</Button>
				</div>
			</ShadowWrapper>
		</div>
	);
};

export default CreateVacancyForm;
