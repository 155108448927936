import Yup from '../entities/Yup';
import { TTableEl } from '../components/tableInput';

export const validationSchema = Yup.object().shape(
	{
		title_ua: Yup.string()
			.min(3, 'мінімум 3 символи')
			.max(200, 'Макс. кількість 200 символи'),
		content_ua: Yup.string()
			.max(15000, 'Макс. кількість 15000 символи'),
		slug_ua: Yup.string().matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link')
			.min(3, 'мінімум 3 символи')
			.max(15000, 'Макс. кількість 15000 символи'),
		table_ua: Yup.array()
			.of(
				Yup.object().shape({
					key: Yup.string()
						.max(64, 'Макс. кількість 64 символи'),
					value: Yup.string()
						.max(64, 'Макс. кількість 64 символи'),
					id: Yup.string(),
				})
			)
			,
		title_ru: Yup.string().when('content_ru', {
			is: (field: string) => field,
			then: Yup.string()
				.max(200, 'Макс. кількість 200 символи'),
			otherwise: Yup.string(),
		}),
		content_ru: Yup.string().when('title_ru', {
			is: (field: string) => field,
			then: Yup.string()

				.max(15000, 'Макс. кількість 15000 символи'),
			otherwise: Yup.string(),
		}),
		slug_ru: Yup.string().matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link')
			.max(15000, 'Макс. кількість 15000 символи'),
		table_ru: Yup.array()
			.of(
				Yup.object().shape({
					key: Yup.string()
						.max(64, 'Макс. кількість 64 символи'),
					value: Yup.string()
						.max(64, 'Макс. кількість 64 символи'),
					id: Yup.string(),
				})
			)
			,
		title_en: Yup.string().when('content_en', {
			is: (field: string) => field,
			then: Yup.string()
				.max(200, 'Макс. кількість 200 символи'),
			otherwise: Yup.string(),
		}),
		slug_en: Yup.string().matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link')
			.max(15000, 'Макс. кількість 15000 символи'),
		content_en: Yup.string().when('title_en', {
			is: (field: string) => field,
			then: Yup.string()
				.max(15000, 'Макс. кількість 15000 символи'),
			otherwise: Yup.string(),
		}),
		table_en: Yup.array()
			.of(
				Yup.object().shape({
					key: Yup.string()
						.max(64, 'Макс. кількість 64 символи'),
					value: Yup.string()
						.max(64, 'Макс. кількість 64 символи'),
					id: Yup.string(),
				})
			)
			.unique(
				(el: TTableEl) => el.key,
				'Назва поля таблиці повинна бути унікальна'
			),
		image: Yup.mixed(),
		images: Yup.array().of(Yup.mixed()),
		geo: Yup.object().shape(
			{
				lat: Yup.number().when('lng', {
					is: (field: number) => field,
					then: Yup.number()
				}),
				lng: Yup.number().when('lat', {
					is: (field: number) => field,
					then: Yup.number()
				}),
			},
			[['lat', 'lng']]
		),
		countryId_ua: Yup.string(),
		countryId_ru: Yup.string(),
		countryId_en: Yup.string(),
		categoryId_ua: Yup.string(),
		categoryId_ru: Yup.string(),
		categoryId_en: Yup.string(),
	},
	[
		['content_en', 'title_en'],
		['content_ru', 'title_ru'],
	]
);


export const validationSchemaUpdate = Yup.object().shape(
	{
		title: Yup.string()
			.min(3, 'мінімум 3 символи')
			.max(200, 'Макс. кількість 200 символи'),
		content: Yup.string()
			.max(15000, 'Макс. кількість 15000 символи'),
		slug: Yup.string().matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link')
			.min(3, 'мінімум 3 символи')
			.max(15000, 'Макс. кількість 15000 символи'),
		table: Yup.array()
			.of(
				Yup.object().shape({
					key: Yup.string()
						.max(64, 'Макс. кількість 64 символи'),
					value: Yup.string()
						.max(64, 'Макс. кількість 64 символи'),
					id: Yup.string(),
				})
			)
			,
		image: Yup.mixed(),
		images: Yup.array().of(Yup.mixed()),
		geo: Yup.object().shape(
			{
				lat: Yup.number().when('lng', {
					is: (field: number) => field,
					then: Yup.number()
				}),
				lng: Yup.number().when('lat', {
					is: (field: number) => field,
					then: Yup.number()
				}),
			},
			[['lat', 'lng']]
		),
		countryId: Yup.string(),
		categoryId: Yup.string(),
		title_ua: Yup.string(),
		title_ru: Yup.string(),
		
	},
	[
		['content_en', 'title_en'],
		['content_ru', 'title_ru'],
	]
);