/* eslint-disable indent */
import {
	DOCUMENT_ROUTE,
	REGULAR_INFO_ROUTE,
	PROJECT_ROUTE,
	NEWS_ROUTE,
	VACANCY_ROUTE,
	CATEGORY_ROUTE,
	COUNTRY_ROUTE,
} from '../consts/routes';

const navigations = [
	{
		path: DOCUMENT_ROUTE,
		title: 'Документи',
	},
	{
		path: PROJECT_ROUTE,
		title: 'Проекти',
	},
	{
		path: NEWS_ROUTE,
		title: 'Новини',
	},
	{
		path: VACANCY_ROUTE,
		title: 'ВакансіЇ',
	},
	{
		path: CATEGORY_ROUTE,
		title: 'Категорії',
	},
	{
		path: COUNTRY_ROUTE,
		title: 'Країни',
	},
];

const navigationsUkrgidro = [
	{
		path: DOCUMENT_ROUTE,
		title: 'Документи',
	},
	{
		path: REGULAR_INFO_ROUTE,
		title: 'Регулярна інформація',
	},
	{
		path: PROJECT_ROUTE,
		title: 'Проекти',
	},
	{
		path: NEWS_ROUTE,
		title: 'Новини',
	},
	{
		path: VACANCY_ROUTE,
		title: 'ВакансіЇ',
	},
	{
		path: CATEGORY_ROUTE,
		title: 'Категорії',
	},
	{
		path: COUNTRY_ROUTE,
		title: 'Країни',
	},
];

const navigationsMtsii = [
	{
		path: DOCUMENT_ROUTE,
		title: 'Документи',
	},
	{
		path: NEWS_ROUTE,
		title: 'Новини',
	},
	{
		path: VACANCY_ROUTE,
		title: 'ВакансіЇ',
	},
];

export const getSiteNavigation = (siteId: string | number) => {
	switch (siteId) {
		case 1:
			return navigationsUkrgidro;
		case 4:
			return navigationsMtsii;
		default:
			return navigations;
	}
};

export default navigations;
