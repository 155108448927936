import React from 'react';
import { TProject } from '../types';
import { IListItem } from '../../interfaces/IListItem';
import EditableImage from '../../components/editableImage';
import { projectService } from '../project.service';
import { Trash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { PROJECT_ROUTE } from '../../consts/routes';
import { createPathToEditRoute } from '../../helpers/createRoute';
import ShadowWrapper from '../../components/ShadowWrapper';
import Title from '../../components/Title';
import ButtonLink from '../../components/ButtonLink';
import { getDeletedSliderImages, getLanguage } from '../../store/common/selectors.common';
import store from '../../store/store.main';
import { useSelector } from 'react-redux';

type TProjectListItemProps = TProject & IListItem<TProject>;

const ProjectListItem = (props: TProjectListItemProps): JSX.Element => {
	const {
		title,
		images,
		onEditItem,
		onDeleteItem,
		id,
		project,
		slug,
		content
	} = props;
	const baseUrl = `${process.env.REACT_APP_API_URL}/storage/`
	const navigate = useNavigate();
	const language = useSelector(getLanguage);
	let checkLng = (lng:string) => project.translations.some(el => el.language.code === lng)
	async function changeImage (language: string, image: File) {
		let data
		let idForImgRequest:any
		if(language === 'ua') {
			idForImgRequest = id
			data = await projectService.getById(id);
		}
		if(language === 'ru' && checkLng('ru')) {
			let ruTranslation =  project.translations.find(el =>  el.language.code === 'ru')
			idForImgRequest = ruTranslation?.id
			data = await projectService.getById(idForImgRequest as any as string)
		}
		if(language === 'en' && checkLng('en')) {
			let enTranslation =  project.translations.find(el =>  el.language.code === 'en')
			idForImgRequest = enTranslation?.id
			data = await projectService.getById(idForImgRequest as any as string)
		}
		let imagesDelete = getDeletedSliderImages(store.getState());
		projectService
			.edit(language, idForImgRequest, {
				slug: data?.slug,
				title: data?.title,
				body: data?.body,
				isEnabled: '1',
				priority: '0',
				country: data?.project?.country?.id,
				category: data?.project?.category?.id,
				images_to_remove: imagesDelete,
				latitude: data?.project?.point?.latitude ? data?.project?.point?.latitude : '',
				longitude: data?.project?.point?.longitude ? data?.project?.point?.longitude : '',
				table: data?.properties
			}, imageHelper(image, data.images.filter((el:any, i:any) => {
				if (i >= 1) {
					return el;
				}
			}))).then(() => {
				onEditItem();
			});
			
	}
	let imageHelper = (mainImage:any, sliderImage:any) => {
		let resultArray
		if(sliderImage?.length > 0 && mainImage?.length) {
			resultArray = [...mainImage, ...sliderImage]
			return resultArray
		} 
		if(sliderImage?.length > 0 && mainImage?.name) {
			resultArray = [mainImage,...sliderImage ]
			return resultArray
		} 
		if(sliderImage?.length > 0 && !mainImage?.length) {
			resultArray = [...sliderImage]
			return resultArray
		} 
		if(mainImage?.length) {
			resultArray = [...mainImage]
			return resultArray
		} 

		if(mainImage?.name) {
			resultArray = [mainImage]

			return resultArray
		} 	
	}
	return (
		<li className="project-list-item">
			<ShadowWrapper variant="content" className="project-list-item__body">
				<Title type="h4" size="small" className="project-list-item__title">
					{title}
				</Title>
				<EditableImage
					alt={images ? title : ''}
					src={images[0]?.image?.large?.path ? `${baseUrl}${images[0]?.image?.large?.path}` : ''}
					onChange={async (image: File) => {
							if(language === 'ua') {
								changeImage('ua', image)
							}
							if(language === 'ru' && checkLng('ru') ) {
								changeImage('ru', image)
							}
							if(language === 'en' && checkLng('en') ) {
								changeImage('en', image)
							}
						}
					
				 }
				 className="project-list-item__image" 
				/>
				<div className="project-list-item__footer">
					<ButtonLink
						variant="small"
						onClick={() => {
							navigate(createPathToEditRoute(id, PROJECT_ROUTE));
						}}
						className="project-list-item__button-edit"
					>
						Редагувати
					</ButtonLink>
					<button
						onClick={() => {
							projectService.delete(project?.id).then(() => {
								onDeleteItem(project?.id);
							});
						}}
						className="project-list-item__button-trash"
					>
						<Trash color="red" />
					</button>
				</div>
			</ShadowWrapper>
		</li>
	);
};

export default ProjectListItem;




