import React from 'react';

interface propsButtonClose {
	className?: string,
	onClick?: () => void,
	align?: 'right',
}

const ButtonClose = ({ onClick, className, align }: propsButtonClose) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={`button-close button-close--${align} ${className}`}
		/>
	);
};

export default ButtonClose;
