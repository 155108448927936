import {useState} from 'react';

type TUseLoadingProps = {
	initialLoading: boolean;
}

export const useLoading = (props: TUseLoadingProps) => {
	const [loading, setLoading] = useState(props.initialLoading);
	
	return {
		loading,
		setLoading,
	};
};
