import React from 'react';
import { createInputs, IField } from '../../helpers/createInput';
import Button from '../../components/button';
import { useFormik } from 'formik';
import { defaultFormikConfig } from '../../components/form/config';
import { authService } from '../auth.service';
import { object, string } from 'yup';
import { DateEntity } from '../../entities/Date';
import ResetPassword from './ResetPassword';

type TSendResetPasswordCodeProps = {
	show: boolean;
	onChange: (value: boolean) => void;
	onConfirm: (email: string, date: DateEntity) => void;
}

const fields: IField[] = [
	{
		label: 'E-mail',
		labelVariant: 'light',
		type: 'email',
		key: 'email',
	}
];

const defaultInitialValues = {
	email: '',
};

const validationSchema = object({
	email: string().required('Вкажіть e-mail').email('Некоректний e-mail'),
});

const SendResetPasswordCodeModal = (props: TSendResetPasswordCodeProps): JSX.Element => {
	const { show, onChange, onConfirm } = props;

	const form = useFormik({
		...defaultFormikConfig,
		initialValues: defaultInitialValues,
		validationSchema: validationSchema,
		onSubmit: async (values, { setErrors, resetForm }) => {
			await authService.sendResetPasswordCode(values.email)
				.then((response) => {
					resetForm();
					onConfirm(values.email, response.date);
				})
				.catch((error) => {
					setErrors({
						email: error.fields.email,
					});
				});
		},
	});

	return (
		<ResetPassword
			show={show}
			title="Відновити пароль"
			onChange={(value) => { form.resetForm(); onChange(value); }}
		>
			<>
				{createInputs(form, fields)}
				<Button
					length="full-length"
					onClick={form.handleSubmit}
				>
					Відправити
				</Button>
			</>
		</ResetPassword>
	);
};

export default SendResetPasswordCodeModal;
