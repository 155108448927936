import React from 'react';
import PageContainer from '../components/PageContainer';
import DocumentCreateForm from './components/documentCreateForm';
import { useNavigate } from 'react-router-dom';
import { DOCUMENT_ROUTE, REGULAR_INFO_ROUTE } from '../consts/routes';
import { createPathToListRoute } from '../helpers/createRoute';
import ShadowWrapper from '../components/ShadowWrapper';

const DocumentCreatePage = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<PageContainer>
			<ShadowWrapper variant="content" className="document-page__create-form">
				<DocumentCreateForm
					onSuccess={() =>
						navigate(
							createPathToListRoute(
								location.pathname.indexOf(REGULAR_INFO_ROUTE) === -1
									? DOCUMENT_ROUTE
									: REGULAR_INFO_ROUTE
							)
						)
					}
				/>
			</ShadowWrapper>
		</PageContainer>
	);
};

export default DocumentCreatePage;
