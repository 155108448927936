import React, { useState } from 'react';
import ButtonLink from '../../components/ButtonLink';
import { reloadListWithPagination } from '../../store/common/actions.common';
import store from '../../store/store.main';
import { Service } from '../../categoryCountyMixin/service';

type TPageHeader = {
	FormElement: React.ReactType;
	title: string;
	additionalProps?: { [key: string]: any };
	service: Service;
};

const PageHeader = (props: TPageHeader): JSX.Element => {
	const { FormElement, service, additionalProps, title } = props;
	const [addFormOpen, setAddFormOpen] = useState<boolean>(false);
	return (
		<>
			<div>
				<ButtonLink
					variant="big"
					onClick={() => setAddFormOpen((state) => !state)}
				>
					{title}
				</ButtonLink>
			</div>
			{addFormOpen && (
				<FormElement
					onClose={() => setAddFormOpen(false)}
					service={service}
					additionalProps={additionalProps}
					onSuccess={() => {
						store.dispatch(reloadListWithPagination());
						setAddFormOpen(false);
					}}
				/>
			)}
		</>
	);
};

export default PageHeader;
