import React, { FC } from 'react';

interface propsPageContainer {
	className?: string,
}

const PageContainer: FC<propsPageContainer> = ({ children, className }) => {
	return (
		<div className={`${className} page-container`}>
			{children}
		</div>
	);
};

export default PageContainer;
