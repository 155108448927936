import React, { RefObject, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { defaultFormikConfig } from '../../components/form/config';
import { validationSchema } from '../validationSchema';
import { TEditMixinCountryCategory } from '../types';
import { LANG_CONFIG } from '../../consts/common';
import createInput from '../../helpers/createInput';
import Button from '../../components/button';
import { Service } from '../service';
import ButtonLink from '../../components/ButtonLink';
import ButtonClose from '../../components/ButtonClose';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import Loader from '../../components/loader';

interface TEditCategoryForm extends TEditMixinCountryCategory {
	id: string;
	onEdit: () => void;
	onDelete: (id: string) => void;
	deleteText: string;
	service: Service;
	onClose: () => void;
	editMode?: boolean;
	loading?: any;
	isLoading?:any;
	idRu?: string;
	idEn?: string;
	entityId?: string;
}

const EditForm = (props: TEditCategoryForm): JSX.Element => {
	const {
		id,
		deleteText,
		service,
		name_en,
		name_ua,
		name_ru,
		onEdit,
		onDelete,
		onClose,
		editMode=false,
		loading,
		isLoading,
		idRu,
		idEn,
		entityId,
		priority
	} = props;

	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const form = useFormik<TEditMixinCountryCategory>({
		...defaultFormikConfig,
		validationSchema,
		initialValues: {
			name_en,
			name_ru,
			name_ua,
		},
		onSubmit: (values, { setErrors, resetForm }) => {
			setIsDisabled(true);
			const checkForService = () => {
				if(values.name_en && idEn && form.initialValues.name_en !== values.name_en) {
					service
					.edit(idEn, {name: values.name_en, isEnabled: '1', priority: priority})
				}
				if(values.name_ru && idRu && form.initialValues.name_ru !== values.name_ru) {
					service
					.edit(idRu, {name: values.name_ru, isEnabled: '1', priority: priority})
				}
	
				if(values.name_ru && !idRu) {
					service
					.createTranslation('ru', entityId as string, {name: values.name_ru, isEnabled: '1', priority: '0'})
				}
				if(values.name_en && !idEn) {
					service
					.createTranslation('en', entityId as string, {name: values.name_en, isEnabled: '1', priority: '0'})
				}
				//ua is required language so it`s always edit in this form
				return service.edit(id, {name: values.name_ua, isEnabled: '1', priority: priority}, true)
			}
				checkForService()
				.then(() => {
					onEdit();
					resetForm();
				})
				.catch((error) => {
					setErrors({
						...error.fields,
					});
				})
				.finally(() => {
					setIsDisabled(false);
				});
			
		},
	});
	const refEditForm = useRef<HTMLDivElement>(null);
	useOnClickOutside(refEditForm, onClose);

	return (
		<div className="category-form">
			<div ref={refEditForm} className="category-form__body">
				<ButtonClose align="right" onClick={onClose} />
				{LANG_CONFIG.map((language) =>
					createInput(form, {
						key: 'name_' + language.name,
						label: 'Категорія ' + language.label,
						labelVariant: 'bold',
						type: 'text',
						className: 'category-form__item',
					})
				)}
				<div className="category-form__footer">
					<ButtonLink
						variant="small"
						onClick={() => {
							service.delete(entityId as string).then(() => {
								onDelete(entityId as string);
							});
						}}
						className="category-form__button-delete"
					>
						{deleteText}
					</ButtonLink>
					<Button isDisabled={isDisabled} onClick={form.submitForm}>
						Зберегти
					</Button>
				</div>
			</div>	
		</div>
	);
};

export default EditForm;
