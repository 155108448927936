import React, { useEffect } from 'react';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import EditableImage from '../editableImage';
import { Image } from '../../entities/Image';
import InputLabel from '../inputLabel';
import InputError from '../inputError';
import store from '../../store/store.main';
import {
	resetSliderDeleteImages,
	setSliderDeleteImage,
} from '../../store/common/actions.common';
import { isString } from '../../helpers/typeChecks';

const InputSlider = (props: IFormFieldProps<undefined>): JSX.Element => {
	const {
		label,
		labelVariant,
		options: initialOptions,
		error,
		name,
		value,
		setField,
		className = '',
		touched = false,
		project = false
	} = props;

	const baseUrl = `${process.env.REACT_APP_API_URL}/storage/`

	useEffect(() => {
		store.dispatch(resetSliderDeleteImages());
	}, []);

	return (
		<>
			{label && <InputLabel labelVariant={labelVariant} label={label} />}
			<div>
				{value?.map?.((image: any, index: number) => {
					const imageEntity = new Image(image);
					return (
						<EditableImage
							key={index.toString()}
							src={project && image?.image ? `${baseUrl}${image?.image?.large?.path}`: imageEntity.toString}
							editText="Змінити"
							onDelete={() => {
								setField(
									name,
									value.filter((image: any, i: number) => {
										if (project && image?.image && index === i) {
											store.dispatch(setSliderDeleteImage({image: image, index: index + 1} as any))
										}
										if (isString(image) && index === i) {
											store.dispatch(setSliderDeleteImage(image));
										}
										return index !== i;
									}),
									true
								);
							}}
							onChange={(imageChange: any) => {
								if (project && image?.image) {
									store.dispatch(setSliderDeleteImage({image: image, index: index + 1} as any))
								}
								const result = [...value];
								result[index] = imageChange;
								setField(name, result, true);
							}}
						/>
					);
				})}
				<EditableImage
					src=""
					editText="Додати"
					onChange={(image: File) => {
						if (image) {
							const result = value ? [...value] : [];
							result.push(image);
							setField(name, result, true);
						}
					}}
				/>
			</div>
			<InputError condition={!!(error && touched)} error={error} />
		</>
	);
};

export default InputSlider;
