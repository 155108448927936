import {isArray, isBoolean, isDate, isNull, isNumber, isObject, isString, isUndefined} from './typeChecks';
import {DateEntity} from '../entities/Date';

export const createQueryParams = (data: {[key: string]: any}): string => {
	return Object.keys(data).reduce((acc, key) => {
		const operation = createQueryParam(data[key]);
		return acc + operation.execute(data[key], key);
	}, '?').slice(0, -1);
};

const addQueryParamSplitter = (queryParams: string): string => {
	return queryParams + '&';
};

const generateQueryParam = (key: string, value: string): string => {
	return addQueryParamSplitter(`${key}=${value}`);
};

interface IQueryParam<Type> {
	execute: (value: Type, key: string) => string;
}

const createQueryParam = (param: any): IQueryParam<any> => {
	if (isUndefined(param)) {
		return new UndefinedQueryParam();
	}
	if (isNull(param)) {
		return new NullQueryParam();
	}
	if (isArray(param)) {
		return new ArrayQueryParam();
	}
	if (isObject(param)) {
		return new ObjectQueryParam();
	}
	if (isDate(param)) {
		return new DateQueryParam();
	}
	if (isBoolean(param)) {
		return new BooleanQueryParam();
	}
	if (isNumber(param)) {
		return new NumberQueryParam();
	}
	if (isString(param)) {
		return new StringQueryParam();
	}
	return new DefaultQueryParam();
};

class DefaultQueryParam implements IQueryParam<any> {
	execute(value: any, key: string): string {
		return '';
	}
}

class DateQueryParam implements IQueryParam<Date> {
	execute(value: Date, key: string): string {
		return generateQueryParam(key, new DateEntity(value).toString);
	}
}

class ObjectQueryParam implements IQueryParam<{[key: string]: any}> {
	execute(value: {[key: string]: any}, key: string): string {
		const keys = Object.keys(value);
		return keys.reduce((acc, nestedKey) => {
			const operation = createQueryParam(value[nestedKey]);
			return acc + operation.execute(value[key], key + `[${nestedKey}]`);
		}, '');
	}
}

class BooleanQueryParam implements IQueryParam<boolean> {
	execute(value: boolean, key: string) {
		return generateQueryParam(key, value.toString());
	}
}

class NullQueryParam implements IQueryParam<null> {
	execute(value: null, key: string) {
		return '';
	}
}

class UndefinedQueryParam implements IQueryParam<undefined> {
	execute(value: undefined, key: string) {
		return '';
	}
}

class NumberQueryParam implements IQueryParam<number> {
	execute(value: number, key: string) {
		return generateQueryParam(key, value.toString());
	}
}

class StringQueryParam implements IQueryParam<string> {
	execute(value: string, key: string) {
		return generateQueryParam(key, value);
	}
}

class ArrayQueryParam implements IQueryParam<any[]> {
	execute(values: any[], key: string) {
		return values.reduce((acc: string, value: any, index) => {
			const operation = createQueryParam(value);
			return acc + operation.execute(value[key], key + `[${index}]`);
		}, '');
	}
}
