import {combineReducers} from 'redux';
import {userReducer} from './user/user.reducer';
import {commonReducer} from './common/reducer.common';


const rootReducer = combineReducers({
	userReducer,
	commonReducer,
});

export default rootReducer;
