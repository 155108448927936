import Yup from '../entities/Yup';

export const validationSchema = Yup.object().shape({
	title_ua: Yup.string()
		.requiredByLang('ua', 'Вкажіть назву вакансії').max(200, 'Макс. кількість 200 символи'),
	content_ua: Yup.string().max(15000, 'Макс. кількість 15000 символи'),
		// .requiredByLang('ua', 'Вкажіть опис вакансії')
	slug_ua: Yup.string()
	.requiredByLang('ua', 'Вкажіть slug').max(200, 'Макс. кількість 200 символи').matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link'),
	
	title_ru: Yup.string()
		.when('content_ru', {
			is: (field: string) => field,
			then: Yup.string().max(200, 'Макс. кількість 200 символи'),
			otherwise: Yup.string(),
		}),
	content_ru: Yup.string()
		.when('title_ru', {
			is: (field: string) => field,
			then: Yup.string().max(15000, 'Макс. кількість 15000 символи'),
			otherwise: Yup.string(),
		}),
	slug_ru: Yup.string().max(200, 'Макс. кількість 200 символи').matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link'),	
	title_en: Yup.string()
		.when('content_en', {
			is: (field: string) => field,
			then: Yup.string().max(200, 'Макс. кількість 200 символи'),
			otherwise: Yup.string(),
		}),
	content_en: Yup.string()
		.when('title_en', {
			is: (field: string) => field,
			then: Yup.string().max(15000, 'Макс. кількість 15000 символи'),
			otherwise: Yup.string(),
		}),
	slug_en: Yup.string().max(200, 'Макс. кількість 200 символи').matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Лінк мусить бути англійською мовою, без пробілів та без використання особливих символів; для прикладу test-slug-for-link'),
}, [
	['content_en', 'title_en'],
	['content_ru', 'title_ru'],
]);
