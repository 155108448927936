import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import InputLabel from '../inputLabel';
import InputError from '../inputError';
import FormItem from '../FormItem';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../store/common/selectors.common';

export type TSelectOption = {
	label: string;
	value: string
}

const SelectInput = (props: IFormFieldProps<{
	options: TSelectOption[];

}>): JSX.Element => {
	const {
		label,
		labelVariant,
		options: initialOptions,
		error,
		name,
		value,
		setField,
		className = '',
		touched = false
	} = props;
	const [options, setOptions] = useState<TSelectOption[]>(initialOptions?.options || []);
	const language = useSelector(getLanguage);
	let val = options.find((option) => option.value === value)
	useEffect(() => {
		if (initialOptions?.options) {
			setOptions(initialOptions.options);
		}
	}, [initialOptions, language]);

	return (
		<FormItem className={error && touched ? 'select select--error' : 'select'}>
			{label && <InputLabel labelVariant={labelVariant} label={label} />}
			<Select
				className={`${className} select__input`}
				onChange={(value: any) => {
					setField(name, value?.value, true)
				}}
				defaultValue={val ? val : ''}
				value={val ? val : ''}
				options={options}
				placeholder=""
				styles={{
					control: base => ({
						...base,
						border: '1px solid var(--greyDark)',
						boxShadow: 'none',
						'&:hover': {
							border: '1px solid var(--greyDark)',
						}
					})
				}}
			/>
			<InputError condition={!!(error && touched)} error={error} />
		</FormItem>
	);
};

export default SelectInput;
