import React from 'react';

interface propsArrow {
	className?: string,
	variant?: 'grey' | 'black',
	side?: 'left' | 'right',
}

function Arrow({ className, variant, side }: propsArrow) {
	return (
		<span className={`${className} arrow arrow--${variant} arrow--${side}`} />
	);
}

export default Arrow;
