/* eslint-disable indent */
import {
	RELOAD_LIST_WITH_PAGINATION,
	RESET_DELETED_IMAGES,
	SET_DELETED_IMAGE,
	SET_LANG,
	SET_SITE,
	SET_FORM
} from './types.common';
import { TLang } from '../../interfaces/TLang';
import { storage } from '../../entities/Storage';
import { SITE_ID } from '../../consts/storageKeys';

interface ICommonReducerState {
	siteId: number;
	language: TLang;
	sliderDeleteImages: string[];
	reloadListWithPagination: boolean;
	form: any;
}

const initialSiteId = storage.get(SITE_ID);

const initialState: ICommonReducerState = {
	siteId: initialSiteId ? +initialSiteId : 1,
	language: 'ua',
	reloadListWithPagination: false,
	sliderDeleteImages: [],
	form: {},
};

export const commonReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_LANG:
			return { ...state, language: action.language };
		case SET_FORM:
			return { ...state, form: action.form };
		case SET_SITE:
			storage.set(SITE_ID, action.id);
			return { ...state, siteId: action.id };
		case RELOAD_LIST_WITH_PAGINATION:
			return {
				...state,
				reloadListWithPagination: !state.reloadListWithPagination,
			};
		case RESET_DELETED_IMAGES:
			return { ...state, sliderDeleteImages: [] };
		case SET_DELETED_IMAGE:
			// eslint-disable-next-line no-case-declarations
			const updatedSliderDeleteImages = [...state.sliderDeleteImages];
			updatedSliderDeleteImages.push(action.image as string);
			return { ...state, sliderDeleteImages: updatedSliderDeleteImages };
		default:
			return state;
	}
};
