import {IStorage} from '../interfaces/IStorage';
import {localStore} from './LocalStorage';

class Storage {
	constructor(private readonly storage: IStorage) {}

	get(key: string): string {
		return this.storage.get(key);
	}

	set(key: string, value: string): void {
		this.storage.set(key, value);
	}

	delete(key: string): void {
		this.storage.delete(key);
	}
}

export const storage = new Storage(localStore);
