import React from 'react';
import {Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {LOGIN_ROUTE} from '../../consts/routes';
import {getAccessToken} from '../../store/user/user.selectors';

export type TRouteProps = {
	children: JSX.Element;
}

const RequireAuth = (props: TRouteProps): JSX.Element => {
	const token = useSelector(getAccessToken);
	if (!token) {
		return <Navigate to={LOGIN_ROUTE} />;
	}

	return props.children;
};

export default RequireAuth;
