export const createPathToEditRoute = (id: number | string, routeName: string) => {
	return routeName + '/' + id + '/edit';
};

export const createPathToListRoute = (routeName: string) => {
	return routeName;
};

export const createPathToCreateRoute = (routeName: string) => {
	return routeName + '/create';
};
