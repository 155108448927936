import {ApiService} from '../services/ApiService';
import {HttpMethods} from '../enums/HttpMethods';
import {addObjectToFormData} from '../helpers/formData/FormData';

export class AuthRepository {
	private readonly apiService: ApiService;
	constructor() {
		this.apiService = new ApiService();
	}

	async login(username: string, code: string) {
		const formData = addObjectToFormData({
			username,
			code,
		});
		const response = await this.apiService.request('/authentication/login', {
			method: HttpMethods.POST,
			data: formData,
		});

		return response.data;
	}

	async sendResetPasswordCode(email: string) {
		const formData = addObjectToFormData({
			email,
		});
		const response = await this.apiService.request('/get-reset-code', {
			method: HttpMethods.POST,
			data: formData,
		});

		return response.data;
	}

	async resetPasswordCode(email: string, code: string, password: string, repeatPassword: string) {
		const formData = addObjectToFormData({
			email,
			code,
			password,
			password_confirmation: repeatPassword,
		});

		const response = await this.apiService.request('/change-password-by-code', {
			method: HttpMethods.POST,
			data: formData,
		});

		return response.data;
	}
}

export const authRepository = new AuthRepository();
