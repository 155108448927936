import React from 'react';
import PageContainer from '../components/PageContainer';
import CreateVacancyForm from './components/createVacancyForm';
import { useNavigate } from 'react-router-dom';
import { createPathToListRoute } from '../helpers/createRoute';
import { VACANCY_ROUTE } from '../consts/routes';

const CreateVacancyPage = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<PageContainer>
			<CreateVacancyForm
				onSuccess={() => navigate(createPathToListRoute(VACANCY_ROUTE))}
			/>
		</PageContainer>
	);
};

export default CreateVacancyPage;
