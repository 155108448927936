import React, {useEffect, useState} from 'react';
import SendResetPasswordCodeModal from './sendResetPasswordCode.modal';
import ResetPasswordModal from './resetPassword.modal';
import {DateEntity} from '../../entities/Date';

type TResetPasswordProps = {
	show: boolean;
	onChange: (show: boolean) => void;
}

const ResetPasswordPage = (props: TResetPasswordProps): JSX.Element => {
	const {show: initialShow, onChange} = props;
	const [show, setShow] = useState(initialShow);
	const [sendResetPasswordModal, setSendResetPasswordModal] = useState(true);
	const [email, setEmail] = useState<string>('');
	const [expireCodeDate, setExpireCodeDate] = useState<DateEntity | null>(null);

	const resetPage = () => {
		setExpireCodeDate(null);
		setEmail('');
		setSendResetPasswordModal(true);
	};

	useEffect(() => {
		setShow(initialShow);
	}, [initialShow]);

	useEffect(() => {
		onChange(show);
	}, [show]);

	return (
		sendResetPasswordModal
			?
			<SendResetPasswordCodeModal
				show={show}
				onChange={(value: boolean) => {
					resetPage();
					onChange(value);
				}}
				onConfirm={(email, date) =>{
					setSendResetPasswordModal(false);
					setEmail(email);
					setExpireCodeDate(date);
				}}
			/>
			:
			<ResetPasswordModal
				show={show}
				email={email}
				initialExpireCodeDate={expireCodeDate}
				onChange={(value) => {
					resetPage();
					onChange(value);
				}}
				onConfirm={() => {
					resetPage();
					setShow(false);
				}}
			/>
	);
};

export default ResetPasswordPage;
