import { ProjectRepository } from './project.repository';
import { TPagination } from '../interfaces/TPagination';
import { setToast } from '../helpers/setToast';
import {
	CREATE_ERROR_TEXT,
	DELETE_ERROR_TEXT,
	FETCH_ERROR_TEXT,
	UPLOAD_FILE_ERROR,
} from '../consts/errorMessages';
import { TCreateProjectForm, TEditProjectForm } from './types';
import { convertTableToKeyValue } from '../helpers/convertTableToKeyValue';
import { isFile } from '../helpers/typeChecks';

export class ProjectService {
	private readonly repository: ProjectRepository;
	constructor() {
		this.repository = new ProjectRepository();
	}

	async getAll(language='ua', pagination: TPagination) {
		const response = await this.repository.getAll(language, pagination).catch((error) => {
			setToast(error?.message || FETCH_ERROR_TEXT, 'error');
			throw error;
		});

		return {
			entities: response.projects,
			pagination: response.pagination,
		};
	}

	async edit(language: string,id: string, data: any, image:any) {
		await this.repository
			.edit(
				language,
				id,
				data,
				image
			)
			.then(() => {
				setToast('Проект успішно відредаговано', 'info');
			})
			.catch((error) => {
				if(error?.fields) {
					Object.keys(error?.fields).map(el => {
						if(el === 'images.0.image') {
							'images.0.image'
							setToast(`${error?.message[el]} - головне фото проекту`, 'error');
						} else {
							setToast(`${error?.message[el]} - ${el}`, 'error');
						}
					})
				}

				setToast(error?.message || CREATE_ERROR_TEXT, 'error');
				throw error;
			});
	}



	async createImage(id: string, data: any) {
		await this.repository.createImage(id, data)
	}

	async deleteImage(id: string) {
		await this.repository.deleteImage(id)
	}

	async create(language:string, data: TCreateProjectForm, image: any, cb:any) {
		const q = await this.repository
			.create(language,
				data,
				image
			)
			.then((data) => {
				cb(data)
				setToast('Проект успішно створений', 'info');
			})
			.catch((error) => {
				if(error?.fields) {
					Object.keys(error?.fields).map(el => {
						if(el === 'images.0.image') {
							'images.0.image'
							setToast(`${error?.message[el]} - головне фото проекту`, 'error');
						} else {
							setToast(`${error?.message[el]} - ${el}`, 'error');
						}
					})
				}
				setToast(error?.message || CREATE_ERROR_TEXT, 'error');
				throw error;
			});
		return q
	}

	async createTranslation(language:string, id: string, data: any, image: any) {
		const q = await this.repository
			.createTranslation(language,
				id,
				data,
				image
			)
			.then(() => {
				setToast('Проект успішно створений', 'info');
			})
			.catch((error) => {
				if(error?.fields) {
					Object.keys(error?.fields).map(el => {
						if(el === 'images.0.image') {
							'images.0.image'
							setToast(`${error?.message[el]} - головне фото проекту`, 'error');
						} else {
							setToast(`${error?.message[el]} - ${el}`, 'error');
						}
					})
				}
				setToast(error?.message || CREATE_ERROR_TEXT, 'error');
				throw error;
			});
		return q
	}

	async delete(id: string) {
		await this.repository
			.delete(id)
			.then(() => {
				setToast('Проект видалено', 'info');
			})
			.catch((error) => {
				setToast(error?.message || DELETE_ERROR_TEXT, 'error');
			});
	}

	async editImage(id: number, image: File) {
		await this.repository
			.editImage(id, image)
			.then(() => {
				setToast('Фото проекту оновлено', 'info');
			})
			.catch((error) => {
				setToast(error?.message || UPLOAD_FILE_ERROR, 'error');
				throw error;
			});
	}

	async getById(id: string) {
		return await this.repository.getById(id).catch((error) => {
			setToast(error?.message, 'error');
			throw error;
		});
	}
}

export const projectService = new ProjectService();
