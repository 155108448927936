import React from 'react';
import Page from '../categoryCountyMixin/page';

const CountryPage = (): JSX.Element => {
	return (
		<Page
			headerTitle="Додати країну"
			routeName="country"
			editText="Редагувати краіну"
			name="Країна"
			deleteText="Видалити країну"
			createText="Додати країну"
		/>
	);
};

export default CountryPage;
