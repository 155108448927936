import React from 'react';
import { INews } from '../types';
import NewsPreview from './newsPreview';
import { IListItem } from '../../interfaces/IListItem';

type TNewsListItemProps = IListItem<INews> & INews;

const NewsListItem = (props: TNewsListItemProps): JSX.Element => {
	const {
		id,
		image,
		title,
		onDeleteItem,
		onEditItem,
		onIdPinned,
		idPinned,
		body,
		article
	} = props;

	return (
		<NewsPreview
			image={image}
			id={id as string}
			title={title}
			content={body}
			onDeleteItem={onDeleteItem}
			onEditItem={onEditItem}
			onIdPinned={onIdPinned}
			idPinned={idPinned}
			is_pinned={props.article.isPinned}
			articleId={props.article.id}
			article={article}
		/>
	);
};

export default NewsListItem;
