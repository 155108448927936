import {SET_ACCESS_TOKEN, SET_REFRESH_TOKEN} from './user.types';

export const setAccessToken = (accessToken: string) => ({
	type: SET_ACCESS_TOKEN,
	accessToken,
});

export const setRefreshToken = (refreshToken: string) => ({
	type: SET_REFRESH_TOKEN,
	refreshToken,
});
