import { ApiService } from '../services/ApiService';
import { TPagination } from '../interfaces/TPagination';
import { getSiteId } from '../store/common/selectors.common';
import store from '../store/store.main';
import { createQueryParams } from '../helpers/createQueryParams';
import { HttpMethods } from '../enums/HttpMethods';
import { addObjectToFormData } from '../helpers/formData/FormData';
import { TCreateProject, TEditProject } from './types';
import { workspace } from '../consts/workspace';

export class ProjectRepository {
	private readonly apiService: ApiService;
	private readonly routeName: string = 'project';
	workspace: string;
	constructor() {
		this.apiService = new ApiService();
		this.workspace =  workspace[getSiteId(store.getState()) - 1]
	}

	async create(language: string, data: any, image: any) {
		let dataFields = new FormData()
		dataFields.append('slug', data.slug)
		dataFields.append('title', data.title)
		dataFields.append('body', data.body)
		dataFields.append('isEnabled', data.isEnabled)
		dataFields.append('country', data.country)
		dataFields.append('category', data.category)
		if(data?.latitude && data?.longitude) {
			dataFields.append('latitude', data.latitude)
			dataFields.append('longitude', data.longitude)
		}
		image.map((el: any,i: any) => {
			if(el) {
				dataFields.append(`images[${i}][image]`,el)
				dataFields.append(`images[${i}][isEnabled]`,'1')
				dataFields.append(`images[${i}][priority]`,`${i}`)
			}
		})
		if(data?.table?.length > 0 && data?.table[0]?.key !== ' ') {
			data?.table.map((el: any, i: any) => {
				dataFields.append(`properties[${i}][key]`, el.key)
				dataFields.append(`properties[${i}][value]`, el.value)
				dataFields.append(`properties[${i}][isEnabled]`, '1')
			})
		}
		const q = await this.apiService.request(`/admin/project/create`, {
			method: HttpMethods.POST,
			data: dataFields,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});
		return q
	}

	async createTranslation(language: string, id: string, data: any, image: any) {
		const formData = addObjectToFormData({
			...data,
			...image
		});
		let dataFields = new FormData()
		dataFields.append('slug', data.slug)
		dataFields.append('title', data.title)
		dataFields.append('body', data.body)
		dataFields.append('isEnabled', data.isEnabled)
		dataFields.append('priority', data.priority)
		if(data?.latitude && data?.longitude) {
			dataFields.append('latitude', data.latitude)
			dataFields.append('longitude', data.longitude)
		}
		if(data?.table?.length > 0 && data?.table[0]?.key !== ' ') {
			data?.table.map((el: any, i: any) => {
				dataFields.append(`properties[${i}][key]`, el.key)
				dataFields.append(`properties[${i}][value]`, el.value)
				dataFields.append(`properties[${i}][isEnabled]`, '1')
			})
		}
		if(image) {
			image.map((el: any,i: any) => {
				if(el) {
					dataFields.append(`images[${i}][image]`,el) 
					dataFields.append(`images[${i}][isEnabled]`,'1')
					dataFields.append(`images[${i}][priority]`,`${i}`)
				}
			})
		} else {
			// dataFields.append(`images`, []) 
		}


		const q = await this.apiService.request(`/admin/project/${id}/translation/create`, {
			method: HttpMethods.POST,
			data: dataFields,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});
		return q
	}

	async edit(
		language: string,
		id: string,
		data: any,
		image:any
	) {
		let dataFields = new FormData()
		dataFields.append('slug', data.slug)
		dataFields.append('title', data.title)
		dataFields.append('body', data.body)
		dataFields.append('isEnabled', data.isEnabled)
		dataFields.append('priority', data.priority)
		dataFields.append('country', data.country)
		dataFields.append('category', data.category)
		if(data?.table?.length > 0 && data?.table[0]?.key !== ' ') {
			data?.table.map((el: { key: string | Blob; value: string | Blob; }, i: any) => {
				dataFields.append(`properties[${i}][key]`, el.key)
				dataFields.append(`properties[${i}][value]`, el.value)
				dataFields.append(`properties[${i}][isEnabled]`, '1')
			})
		}
		if(data?.latitude && data?.longitude) {
			dataFields.append('latitude', data.latitude)
			dataFields.append('longitude', data.longitude)
		}
		if(image) {
			image.map((el: any, i: any) => {
				if(el instanceof File) {
					let removeId = data?.images_to_remove.find((deleteImage: { index: any; }) => {
						return deleteImage.index === i
					})
						if(removeId?.image?.id) {
							dataFields.append(`images[${i}][image]`, el)
							dataFields.append(`images[${i}][isEnabled]`,'1')
							dataFields.append(`images[${i}][id]`,`${removeId?.image?.id}`)
							// dataFields.append(`images[${i}][priority]`,`${i}`)
						} else {
							dataFields.append(`images[${i}][image]`, el)
							dataFields.append(`images[${i}][isEnabled]`,'1')
							dataFields.append(`images[${i}][priority]`,`${i}`)
						}
				} else {
					dataFields.append(`images[${i}][isEnabled]`,'1')
					dataFields.append(`images[${i}][id]`,`${el.id}`)
					// dataFields.append(`images[${i}][priority]`,`${i}`)
				}
			})
		}

		await this.apiService.request(`/admin/project/translation/${id}/update`, {
			method: HttpMethods.POST,
			data: dataFields,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});
	}

	

	async deleteImage(id: string) {
		await this.apiService.request(
			`/admin/project/translation/image/${id}/delete`,
			{
				method: HttpMethods.DELETE,
				headers: {
					'X-Workspace': `${this.workspace}`,
				}
			}
		);
	}

	async createImage(id: string, data: any) {
		await this.apiService.request(
			`/admin/project/translation/${id}/image/create`,
			{
				method: HttpMethods.POST,
				data,
				headers: {
					'X-Workspace': `${this.workspace}`,
				}
			}
		);

	}

	async getById(id: string) {
		const response = await this.apiService.request(
			`/admin/project/translation/${id}/get`,
			{
				method: HttpMethods.GET,
			}
		);

		return response.data;
	}

	async getAll(language='ua', pagination: TPagination) {
		const response = await this.apiService.request(
			`/admin/project/all` + createQueryParams(pagination),
			{
				method: HttpMethods.GET,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `${language}`,
				}
			}
		);
		return response.data;
	}

	async editImage(id: number, image: File) {
		const formData = addObjectToFormData({
			image,
		});
		await this.apiService.request(`/${this.routeName}/edit-thumbnail/` + id, {
			method: HttpMethods.POST,
			data: formData,
		});
	}

	async delete(id: string) {
		await this.apiService.request(`/admin/project/${id}/delete`, {
			method: HttpMethods.DELETE,
			headers: {
				'X-Workspace': `${this.workspace}`
			}
		});
	}
}
