import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TProject } from './types';
import { projectService } from './project.service';
import PageContainer from '../components/PageContainer';
import Loader from '../components/loader';
import ProjectEditForm from './components/projectEditForm';
import { useCategoryCountry } from '../hooks/useCategoryCountry';
import { keyValueToTable } from '../helpers/keyValueToTable';
import { DEFAULT_PROJECT_TABLE } from '../consts/common';
import { useSelector } from 'react-redux';
import { getLanguage } from '../store/common/selectors.common';

type TProjectParams = {
	id: string;
};

const ProjectEditPage = (): JSX.Element => {
	const { id } = useParams<TProjectParams>();
	const [project, setProject] = useState<TProject | null>(null);
	const [projectRu, setProjectRu] = useState<TProject | null>(null);
	const [projectEn, setProjectEn] = useState<TProject | null>(null);
	const { categories, countries, promise } = useCategoryCountry();
	const language = useSelector(getLanguage);
	const countryId = useMemo(() => {
		if (!project || !countries.length) {
			return '';
		}
		return (
			countries.find(
				(country) =>
					country.name === project?.project?.country?.translation?.name
			)?.country?.id || ''
		);
	}, [countries, project, language]);

	const countryId_ru = useMemo(() => {
		if (!projectRu || !countries.length) {
			return '';
		}
		return (
			countries.find(
				(country) =>
					country.name === projectRu?.project?.country?.translation?.name
			)?.country?.id || ''
		);
	}, [countries, projectRu, language]);

	const countryId_en = useMemo(() => {
		if (!projectEn || !countries.length) {
			return '';
		}
		return (
			countries.find(
				(country) =>
					country.name === projectEn?.project?.country?.translation?.name
			)?.country?.id || ''
		);
	}, [countries, projectEn, language]);

	const categoryId = useMemo(() => {
		if (!project || !categories.length) {
			return '';
		}

		return (
			categories.find(
				(category) =>
					category.name === project?.project?.category?.translation?.name
			)?.category?.id || ''
		);
	}, [categories, project, language]);

	const categoryId_ru = useMemo(() => {
		if (!projectRu || !categories.length) {
			return '';
		}

		return (
			categories.find(
				(category) =>
					category.name === projectRu?.project?.category?.translation?.name
			)?.category?.id || ''
		);
	}, [categories, projectRu, language]);

	const categoryId_en = useMemo(() => {
		if (!projectEn || !categories.length) {
			return '';
		}
		return (
			categories.find(
				(category) =>
					category.name === projectEn?.project?.category?.translation?.name
			)?.category?.id || ''
		);
	}, [categories, projectEn, language]);

	const [reload, setReload] = useState(false);
	const projectPromise = useMemo(async () => {
		let dataUa = await projectService.getById(id!);
		setProject(dataUa as any);

		if (dataUa?.project?.translations?.length > 1) {
			let ruInfo = dataUa.project.translations.find(
				(el: { language: { code: string } }) => el.language.code === 'ru'
			);
			let enInfo = dataUa.project.translations.find(
				(el: { language: { code: string } }) => el.language.code === 'en'
			);
			if (ruInfo?.id) {
				const dataRu = await projectService.getById(ruInfo.id);
				setProjectRu(dataRu);
			}
			if (enInfo?.id) {
				const dataEn = await projectService.getById(enInfo.id);
				setProjectEn(dataEn);
			}
		}
	}, [reload]);

	const getForm = () => {
		if (!project) {
			return '';
		}

		switch (language) {
			case 'ru':
				return (
					<ProjectEditForm
						key="ru"
						setReload={setReload as any}
						reload={reload as any}
						categories={categories}
						switcherGeo={
							!!(
								projectRu?.project?.point?.latitude ||
								projectRu?.project?.point?.longitude
							) as unknown as any
						}
						countries={countries}
						title={projectRu?.title || ''}
						content={projectRu?.body || ''}
						image={
							projectRu?.images[0]
								? [projectRu?.images[0]]
								: ('' as unknown as any)
						}
						images={
							projectRu?.images[1]
								? projectRu?.images?.filter((el, i) => {
										if (i >= 1) {
											return el;
										}
								  })
								: ('' as unknown as any)
						}
						table_ru={
							projectRu?.properties[0]
								? keyValueToTable(projectRu?.properties)!
								: DEFAULT_PROJECT_TABLE
						}
						countryId={countryId_ru}
						categoryId={categoryId_ru}
						slug={projectRu?.slug}
						switcher_ru={!!projectRu?.properties[0] as unknown as any}
						geo={{
							lng: projectRu?.project?.point?.longitude || undefined,
							lat: projectRu?.project?.point?.latitude || undefined,
						}}
						id={projectRu?.id as unknown as any}
						projectId={project?.project?.id}
					/>
				);
			case 'en':
				return (
					<ProjectEditForm
						key="en"
						setReload={setReload as any}
						reload={reload as any}
						categories={categories}
						switcherGeo={
							!!(
								projectEn?.project?.point?.latitude ||
								projectEn?.project?.point?.longitude
							) as unknown as any
						}
						countries={countries}
						title={projectEn?.title || ''}
						content={projectEn?.body || ''}
						image={projectEn?.images[0] ? [projectEn?.images[0]] : ('' as any)}
						images={
							projectEn?.images[1]
								? projectEn?.images?.filter((el, i) => {
										if (i >= 1) {
											return el;
										}
								  })
								: ('' as any)
						}
						table_en={
							projectEn?.properties[0]
								? keyValueToTable(projectEn?.properties)!
								: DEFAULT_PROJECT_TABLE
						}
						categoryId={categoryId_en || ''}
						countryId={countryId_en || ''}
						slug={projectEn?.slug || ''}
						switcher_en={!!projectEn?.properties[0] as unknown as any}
						geo={{
							lng: projectEn?.project?.point?.longitude || undefined,
							lat: projectEn?.project?.point?.latitude || undefined,
						}}
						id={projectEn?.id as any}
						projectId={project?.project?.id}
					/>
				);
			case 'ua':
				return (
					<ProjectEditForm
						key="ua"
						setReload={setReload as any}
						reload={reload as any}
						categories={categories}
						switcherGeo={
							!!(
								project?.project?.point?.longitude ||
								project?.project?.point?.latitude
							) as unknown as any
						}
						countries={countries}
						title={project.title}
						content={project.body}
						image={
							(project?.images[0] ? [project?.images[0]] : '') as unknown as
								| File
								| undefined
						}
						images={
							(project?.images[1]
								? project?.images?.filter((el, i) => {
										if (i >= 1) {
											return el;
										}
								  })
								: '') as unknown as (string | File)[] | undefined
						}
						table_ua={
							project?.properties[0]
								? keyValueToTable(project?.properties)!
								: DEFAULT_PROJECT_TABLE
						}
						categoryId={categoryId}
						countryId={countryId}
						slug={project.slug}
						switcher_ua={!!project?.properties[0] as unknown as any}
						geo={{
							lng: project?.project?.point?.longitude || undefined,
							lat: project?.project?.point?.latitude || undefined,
						}}
						id={project?.id}
						projectId={project?.project?.id}
					/>
				);
		}
	};

	return (
		<PageContainer>
			<Loader condition={Promise.all([promise, projectPromise])}>
				{project ? (
					<>{getForm()}</>
				) : (
					<span>Проект із заданим id не знайдено</span>
				)}
			</Loader>
		</PageContainer>
	);
};

export default ProjectEditPage;
