import React, { useEffect, useState } from 'react';
import ButtonClose from '../../components/ButtonClose';
import ShadowWrapper from '../../components/ShadowWrapper';
import Title from '../../components/Title';

interface propsResetPassword {
	show: boolean,
	children: JSX.Element,
	title?: string,
	onChange: (value: boolean) => void,
}

const ResetPassword = ({ show, children, title, onChange }: propsResetPassword) => {

	const [isShow, setIsShow] = useState(show);
	useEffect(() => {
		setIsShow(isShow);
	}, [setIsShow]);

	return (
		<ShadowWrapper
			variant="login"
			className="reset-password"
		>
			<header className="reset-password__header">
				<Title
					type="h2"
					size="middle"
					className="reset-password__title"
				>
					{title}
				</Title>
				<ButtonClose
					onClick={() => onChange(false)}
					className="reset-password__close"
				/>
			</header>
			<div className="reset-password__body">
				{children}
			</div>
		</ShadowWrapper>
	);
};

export default ResetPassword;
