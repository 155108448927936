import React, { ChangeEvent, useRef } from 'react';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import ButtonLink from '../ButtonLink';
import { isNull } from '../../helpers/typeChecks';
import InputError from '../inputError';
import InputLabel from '../inputLabel';

const FileInput = (props: IFormFieldProps<undefined>): JSX.Element => {
	const {
		placeholder,
		label,
		labelVariant,
		buttonText,
		className,
		setField,
		setError,
		name,
		value,
		error,
		touched,
	} = props;

	const inputRef = useRef<HTMLInputElement | null>(null);

	const onFileChoose = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e?.target?.files?.length) {
			return;
		}

		const file = e?.target?.files?.[0];

		if (!isNull(file)) {
			setField?.(name, file);
			setError?.(name, '');
			e.target.value = '';
		}
	};

	return (
		<div className="file-input">
			{label && (
				<InputLabel
					labelVariant={labelVariant}
					label={label}
					className="file-input__label"
				/>
			)}
			<ButtonLink
				variant="small"
				onClick={(e) => {
					e.preventDefault();
					inputRef.current?.click();
				}}
				className="file-input__button-link"
			>
				{value ? value.name : buttonText}
			</ButtonLink>
			<input
				ref={inputRef}
				className={className + ' rootInput'}
				type="file"
				placeholder={placeholder}
				onChange={(e) => onFileChoose(e)}
			/>
			<InputError error={error} condition={!!(error && touched)} />
		</div>
	);
};

export default FileInput;
