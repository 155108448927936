import {TFormDataExporterConfig} from './FormData';

export class Config<ValueType> {
	constructor(
		private readonly _key: string,
		private readonly _value: ValueType | any,
		private readonly _cfg: TFormDataExporterConfig,
		private readonly _formData: FormData,
	){}
	get key() {
		return this._key;
	}

	get value() {
		return this._value;
	}

	get config() {
		return this._cfg;
	}

	get formData() {
		return this._formData;
	}
}
