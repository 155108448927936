import React from 'react';
import { newsService } from './news.service';
import NewsListItem from './components/newsListItem';
import ListWithPagination from '../components/listWithPagination';
import PageContainer from '../components/PageContainer';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { NEWS_ROUTE } from '../consts/routes';
import { createPathToCreateRoute } from '../helpers/createRoute';
import ButtonLink from '../components/ButtonLink';

const NewsPage = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<PageContainer className="news-page">
			<ButtonLink
				variant="big"
				onClick={() => navigate(createPathToCreateRoute(NEWS_ROUTE))}
			>
				Додати новину
			</ButtonLink>
			<ListWithPagination
				Element={NewsListItem}
				endpoint={newsService.getAll.bind(newsService)}
				type="column"
				title="Всі новини"
			/>
		</PageContainer>
	);
};

export default NewsPage;
