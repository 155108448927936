import { createElement, FC } from 'react';

interface propsTitle {
	className?: string;
	type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	size: 'large' | 'middle' | 'small';
	indents?: 'vertical-indent';
}

const elements = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
};

const Title: FC<propsTitle> = ({
	type,
	children,
	className,
	size,
	indents,
}) => {
	return createElement(
		elements[type] || elements.h2,
		{ className: `title title--${size} title--${indents} ${className}` },
		children
	);
};

export default Title;
