import React from 'react';
import LogoDropdown from './LogoDropdown';
import Navigation from './Navigation';

const Aside = () => {
	return (
		<aside
			className="aside"
		>
			<div className="aside__body">
				<LogoDropdown className="aside__logo-dropdown" />
				<Navigation className="aside__navigation" />
			</div>
		</aside>
	);
};

export default Aside;
