import React from 'react';
import PageContainer from '../components/PageContainer';
import ListWithPagination from '../components/listWithPagination';
import { vacancyService } from './vacancy.service';
import VacancyListItem from './components/vacancyListItem';
import ButtonLink from '../components/ButtonLink';
import { useNavigate } from 'react-router-dom';
import { createPathToCreateRoute } from '../helpers/createRoute';
import { VACANCY_ROUTE } from '../consts/routes';

const VacanciesPage = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<PageContainer className="vacancies-page">
			<ButtonLink
				variant="big"
				onClick={() => navigate(createPathToCreateRoute(VACANCY_ROUTE))}
			>
				Додати вакансію
			</ButtonLink>
			<ListWithPagination
				Element={VacancyListItem}
				endpoint={vacancyService.getAll.bind(vacancyService)}
				type="column"
				title="Актуальні вакансії"
			/>
		</PageContainer>
	);
};

export default VacanciesPage;
