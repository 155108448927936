import React from 'react';
import LanguagePicker from '../components/languagePicker';
import Logout from './Logout';

function Header() {
	return (
		<header className="header">
			<div className="header__body">
				<LanguagePicker />
				<Logout className="header__logout" />
			</div>
		</header>
	);
}

export default Header;
