/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react';
import {
	FIRST_PAGE_NUMBER,
	PAGINATION_ITEMS_PER_VIEW,
} from '../../consts/common';
import Arrow from '../Arrow';

type TPaginationProps = {
	limitPerView?: number;
	maxPage: number;
	onPageChange: (page: number) => void;
	initialCurrentPage?: number;
};

const isFirstPage = (currentPage: number): boolean => {
	return currentPage === FIRST_PAGE_NUMBER;
};

const isLastPage = (currentPage: number, maxPage: number): boolean => {
	return currentPage === maxPage;
};

const getPaginationItems = (
	limitPerView: number,
	maxPage: number,
	currentPage: number
) => {
	if (maxPage <= 1 || limitPerView < 1) {
		return [];
	}
	const diff = maxPage - currentPage;

	if (diff > limitPerView) {
		if (currentPage === 1) ++currentPage;
		return Array(limitPerView)
			.fill(0)
			.map((_, index) => index + currentPage);
	}

	const result = Array(diff)
		.fill(0)
		.map((_, index) => index + currentPage);

	for (let i = 0; i < limitPerView - diff; ++i) {
		result.unshift(currentPage - 1 - i);
	}

	return result;
};

export const createPagination = (
	props: TPaginationProps
): { element: JSX.Element; getCurrentPage: () => number } => {
	const {
		limitPerView = PAGINATION_ITEMS_PER_VIEW,
		maxPage,
		initialCurrentPage = FIRST_PAGE_NUMBER,
		onPageChange,
	} = props;
	const [currentPage, setCurrentPage] = useState<number>(initialCurrentPage);

	const paginationIndices = useMemo(
		() =>
			getPaginationItems(limitPerView, maxPage, currentPage).filter(
				(pageIndex) => {
					return pageIndex > FIRST_PAGE_NUMBER && pageIndex < maxPage;
				}
			),
		[FIRST_PAGE_NUMBER, maxPage, limitPerView, currentPage]
	);

	useEffect(() => {
		onPageChange(currentPage);
	}, [currentPage]);

	return {
		element:
			maxPage > 1 ? (
				<div className="pagination">
					<button
						type="button"
						onClick={() => {
							if (!isFirstPage(currentPage))
								setCurrentPage((state) => state - 1);
						}}
						className="pagination__prev"
					>
						<Arrow
							side="left"
							variant={currentPage === FIRST_PAGE_NUMBER ? 'grey' : 'black'}
						/>
					</button>
					<button
						type="button"
						className={
							currentPage === FIRST_PAGE_NUMBER
								? 'pagination__num pagination__num--current'
								: 'pagination__num'
						}
						onClick={() => setCurrentPage(FIRST_PAGE_NUMBER)}
					>
						{FIRST_PAGE_NUMBER}
					</button>
					{!isFirstPage(paginationIndices[0] - 1) &&
						maxPage >= PAGINATION_ITEMS_PER_VIEW && (
							<span className="me-2">...</span>
						)}
					{paginationIndices.map((pageIndex) => (
						<button
							key={pageIndex}
							type="button"
							className={
								currentPage === pageIndex
									? 'pagination__num pagination__num--current'
									: 'pagination__num'
							}
							onClick={() => setCurrentPage(pageIndex)}
						>
							{pageIndex}
						</button>
					))}
					{!isLastPage(
						paginationIndices[paginationIndices.length - 1] + 1,
						maxPage
					) &&
						maxPage >= PAGINATION_ITEMS_PER_VIEW && <span>...</span>}
					<button
						type="button"
						className={
							currentPage === maxPage
								? 'pagination__num pagination__num--current'
								: 'pagination__num'
						}
						onClick={() => setCurrentPage(maxPage)}
					>
						{maxPage}
					</button>
					<button
						type="button"
						onClick={() => {
							if (!isLastPage(currentPage, maxPage))
								setCurrentPage((state) => state + 1);
						}}
						className="pagination__next"
					>
						<Arrow
							side="right"
							variant={currentPage === maxPage ? 'grey' : 'black'}
						/>
					</button>
				</div>
			) : (
				<></>
			),
		getCurrentPage: () => currentPage,
	};
};
