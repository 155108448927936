export class Image {
	private readonly image: string;
	constructor(image: string | File) {
		if (image instanceof File) {
			this.image = Image.getPreviewImage(image);
		} else {
			this.image = image;
		}
	}

	static getPreviewImage(file: File): string {
		return URL.createObjectURL(file);
	}

	get toString(): string {
		return this.image;
	}
}
