import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { defaultFormikConfig } from '../../components/form/config';
import { TDocumentCreate } from '../types';
import Yup from '../../entities/Yup';
import createInput from '../../helpers/createInput';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../store/common/selectors.common';
import Button from '../../components/button';
import { documentService } from '../document.service';
import ButtonClose from '../../components/ButtonClose';
import { useNavigate } from 'react-router-dom';
import { REGULAR_INFO_ROUTE } from '../../consts/routes';

const initialValues = {
	name: '',
	file: '' as any,
	isEnabled: 1,
	type: 'downloadable'
};

type TDocumentCreateForm = {
	onSuccess: () => void;
};

const DocumentCreateForm = (props: TDocumentCreateForm): JSX.Element => {
	const { onSuccess } = props;
	const language = useSelector(getLanguage);
	const navigate = useNavigate();
	const location = useLocation();
	let createRequestInfo: { data: { document: { id: string | number; }; }; } 
	const maxInName =
		location.pathname.indexOf(REGULAR_INFO_ROUTE) !== -1 ? 200 : 50;
	const validationSchema = Yup.object().shape(
		{
			name_ua: Yup.string()
				.required('Вкажіть назву документу')
				.max(maxInName, `Макс. кількість ${maxInName} символи`),
			file_ua: Yup.mixed().required('Завантажте документ'),
			
			name_ru: Yup.string().when('document_ru', {
				is: (field: File) => field,
				then: Yup.string()
					.required('Вкажіть назву документу')
					.max(maxInName, `Макс. кількість ${maxInName} символи`),
				otherwise: Yup.string(),
			}),
			file_ru: Yup.mixed()
				.when('name_ru', {
					is: (field: string) => field,
					then: Yup.mixed().required('Завантажте документ'),
					otherwise: Yup.mixed(),
				})
				.nullable(),
			name_en: Yup.string().when('document_en', {
				is: (field: File) => field,
				then: Yup.string()
					.required('Вкажіть назву документу')
					.max(maxInName, `Макс. кількість ${maxInName} символи`),
				otherwise: Yup.string(),
			}),
			file_en: Yup.mixed()
				.when('name_en', {
					is: (field: string) => field,
					then: Yup.mixed().required('Завантажте документ'),
					otherwise: Yup.mixed(),
				})
				.nullable(),
		},
		[
			['document_en', 'name_en'],
			['document_ru', 'name_ru'],
		]
	);

	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const form = useFormik<TDocumentCreate>({
		...defaultFormikConfig,
		initialValues: {
			...initialValues,
		},
		validationSchema,
		 onSubmit: (values, { setErrors, resetForm }) => {
			setIsDisabled(true);
			if (values.file_ua && values.name_ua) {
				documentService
				.create({
					file: values?.file_ua as File,
					name: values.name_ua,
					isEnabled: 1,
					type: location.pathname.indexOf(REGULAR_INFO_ROUTE) === -1 ? 'downloadable' : 'regular_information',

				}, (data: any) => {
					createRequestInfo = data
				})
				.then((data) => {
					if(values?.file_ru && values?.name_ru) {
						documentService
						.createTranslation('ru', createRequestInfo.data.document.id, {
							file: values?.file_ru,
							name: values?.name_ru,
							isEnabled: 1,
							type:
								location.pathname.indexOf(REGULAR_INFO_ROUTE) === -1 ? 'downloadable' : 'regular_information',
						})
					}
					if(values?.file_en && values?.name_en) {
						documentService
						.createTranslation('en', createRequestInfo.data.document.id, {
							file: values?.file_en,
							name: values?.name_en,
							isEnabled: 1,
							type:
								location.pathname.indexOf(REGULAR_INFO_ROUTE) === -1 ? 'downloadable' : 'regular_information',
						})
					}
				}).then(()=> {
					onSuccess();
				})
				.catch((error) => {
					setErrors({
						...error.fields,
					});
				})
				.finally(() => {
					setIsDisabled(false);
				});
		
			}
		},
	});

	return (
		<form className="document-create-form">
			<ButtonClose align="right" onClick={() => navigate(-1)} />
			{createInput(form, {
				key: 'name_' + language,
				label: 'Назва документу*',
				labelVariant: 'bold',
				type: 'text',
			})}
			{createInput(form, {
				key: 'file_' + language,
				label: 'Документ*',
				labelVariant: 'bold',
				buttonText: form.values[
					`document_${language}` as keyof typeof form.values
				]
					? 'Завантажити інший документ'
					: 'Завантажити документ',
				type: 'file',
			})}
			<div className="document-create-form__footer">
				<Button isDisabled={isDisabled} onClick={form.submitForm}>
					Зберегти
				</Button>
			</div>
		</form>
	);
};

export default DocumentCreateForm;
