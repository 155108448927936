/* eslint-disable indent */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import PageContainer from '../components/PageContainer';
import ListWithPagination from '../components/listWithPagination';
import DocumentListItem from './components/documentListItem';
import { documentService } from './document.service';
import ButtonLink from '../components/ButtonLink';
import { isNull } from '../helpers/typeChecks';
import { apiService } from '../services/ApiService';
import Title from '../components/Title';
import companies from '../data/companies';
import { useNavigate, useLocation } from 'react-router-dom';
import { createPathToCreateRoute } from '../helpers/createRoute';
import { DOCUMENT_ROUTE, REGULAR_INFO_ROUTE } from '../consts/routes';
import store from '../store/store.main';
import { getSiteId } from '../store/common/selectors.common';
import { TMainDocument } from './types';
import { useSelector } from 'react-redux';
import { getLanguage } from '../store/common/selectors.common';

const DocumentPage = () => {
	const language = useSelector(getLanguage);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const siteId = getSiteId(store.getState());
	const [mainDocById, setmainDocById] = useState();
	const [mainDoc, setmainDoc] = useState<TMainDocument>({
		document_ua: '',
		document_ru: '',
		document_en: '',
		original_name_ua: '',
		original_name_ru: '',
		original_name_en: '',
		id: '',
		site_name: '',
		name: ''
	});
	const [reload, setReload] = useState<boolean>(false);

	const currentCompany = companies.find((company) => siteId === company.id);

	useEffect(() => {
		documentService.getMainDoc().then((data) => {
			setmainDoc(data);
			setReload(false);
		})
	}, [reload, location, language]);

	let reloadDoc = () => {
		setReload(true)
		window.location.reload()
	} 

	const service = useMemo(async() => {
		if(mainDoc?.id) {
			return await documentService.getById(`${mainDoc?.id}`).then((data) => {
				setmainDocById(data)
			});
		}
	}, [reload, location, language]);

	let checkTranslations = (document:any) => {
		return document?.document?.translations?.find((el: { language: { code: any; }; }) => el.language.code === language)
	}

	return (
		<PageContainer className="document-page">
			<ButtonLink
				variant="big"
				onClick={() =>
					navigate(
						createPathToCreateRoute(
							location.pathname === REGULAR_INFO_ROUTE
								? REGULAR_INFO_ROUTE
								: DOCUMENT_ROUTE
						)
					)
				}
			>
				{location.pathname === REGULAR_INFO_ROUTE
					? 'Додати регулярну інформацію'
					: 'Додати документ'}
			</ButtonLink>
			<ListWithPagination
				Element={DocumentListItem}
				endpoint={
					location.pathname === REGULAR_INFO_ROUTE
						? documentService.getAllRegular.bind(documentService)
						: documentService.getAll.bind(documentService)
				}
				type="column"
				title={
					location.pathname === REGULAR_INFO_ROUTE
						? 'Завантажена регулярна інформація'
						: 'Завантажені документи'
				}
			/>
			{location.pathname !== REGULAR_INFO_ROUTE && (
				<div>
					<Title type="h3" size="large" className="document-page__title">
						Організаційна структура {currentCompany?.type}{' '}
						{currentCompany?.name}
					</Title>
					<div>
						<ButtonLink
							variant="big"
							onClick={() => {
								inputRef.current?.click();
							}}
							className="document-page__download-file"
						>
							{!mainDoc?.name
								? 'Завантажити документ'
								: mainDoc?.name}
						</ButtonLink>
						<input
							ref={inputRef}
							className={'rootInput'}
							type="file"
							placeholder={'Somet text'}
							onChange={(e) => {
								const file = e?.target?.files?.[0];
								if (!isNull(file)) {
									if (language === 'ua') {
										mainDoc?.name  ? documentService.edit(language, mainDoc?.id, {name: file?.name as string, isEnabled: '1', file:file as File, type: 'organizational_structure', 'priority' : '0'}) : apiService.linkDocument(file!)
										window.setTimeout(reloadDoc, 2000);
										
									}
									 else {
										let lng = checkTranslations(mainDocById)
										lng?.id ? documentService.edit(language, lng?.id, {name: file?.name as string, isEnabled: 1, file:file as File, type: 'organizational_structure', 'priority' : '0'}) : documentService.createTranslation(language,mainDoc?.document?.id, {
											name: file?.name as string, "isEnabled": 1, "file": file as File, 'type': 'organizational_structure'})
											window.setTimeout(reloadDoc, 2000);
									 }
									
								}
							}}
						/>	
					</div>
				</div>
			)}
		</PageContainer>
	);
};

export default DocumentPage;
