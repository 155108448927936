import React, { useState } from 'react';
import createInput from '../../helpers/createInput';
import { useFormik } from 'formik';
import { defaultFormikConfig } from '../../components/form/config';
import Yup from '../../entities/Yup';
import { documentService } from '../document.service';
import Button from '../../components/button';
import { TEditDocument } from '../types';
import { REGULAR_INFO_ROUTE } from '../../consts/routes';
import { getLanguage } from '../../store/common/selectors.common';
import { useSelector } from 'react-redux';

type TEditDocumentFormProps = {
	id: string;
	name: string;
	path: string;
	onSubmit: () => void;
	type: string;
	priority: string;
	documentId: string;
};

const EditDocumentForm = (props: TEditDocumentFormProps): JSX.Element => {
	const { id, name, path, onSubmit, type, priority, documentId } = props;
	
	const language = useSelector(getLanguage);
	const maxInName =
		location.pathname.indexOf(REGULAR_INFO_ROUTE) !== -1 ? 200 : 50;

	const validationSchemaObj = {
		document_optional: Yup.object().shape({
			name: Yup.string()
				.required('Вкажіть Назва документу')
				.max(maxInName, `Макс. кількість ${maxInName} символи`),
			document: Yup.mixed(),
		}),
		document_required: Yup.object().shape({
			name: Yup.string()
				.required('Вкажіть Назва документу')
				.max(maxInName, `Макс. кількість ${maxInName} символи`),
			document: Yup.mixed(),
		}),
	};

	const validationSchema =
		validationSchemaObj[path ? 'document_optional' : 'document_required'];

	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const form = useFormik<TEditDocument>({
		...defaultFormikConfig,
		
		initialValues: {
			name: name,
			file: '' as any,
			type: '',
			isEnabled: '',
			priority: ''
		},
		validationSchema,
		onSubmit: (values, { setErrors }) => {
			setIsDisabled(true);
			if(id) {
				documentService
				.edit(language, id, {...values, 'type': type, 'priority': priority})
				.then(() => {
					onSubmit();
				})
				.catch((error) => {
					setErrors({
						...error.fields,
					});
				})
				.finally(() => {
					setIsDisabled(false);
				});
			} else {
				documentService
				.createTranslation(language, documentId, {...values, 'type': type})
				.then(() => {
					onSubmit();
				})
				.catch((error) => {
					setErrors({
						...error.fields,
					});
				})
				.finally(() => {
					setIsDisabled(false);
				});
			}

		},
	});

	return (
		<form className="document-edit-form">
			{createInput(form, {
				key: 'name',
				label: 'Назва документу*',
				labelVariant: 'bold',
				type: 'text',
			})}
			{createInput(form, {
				type: 'file',
				label: 'Документ*',
				labelVariant: 'bold',
				key: 'file',
				buttonText:
					path !== '' || form.values.document ? path : 'Завантажити документ',
			})}

			<div className="document-edit-form__footer">
				<Button isDisabled={isDisabled} onClick={form.submitForm}>
					Зберегти
				</Button>
			</div>
		</form>
	);
};

export default EditDocumentForm;
