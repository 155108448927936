/* eslint-disable indent */
import React from 'react';
import Input from '../components/input';
import Datepicker from '../components/datepicker';
import Editor from '../components/editor';
import ImageInput from '../components/imageInput';
import FileInput from '../components/fileInput';
import SelectInput from '../components/selectInput';
import InputSlider from '../components/inputSlider';
import SwitcherInput from '../components/switchInput';
import TableInput from '../components/tableInput';
import GeoInput from '../components/geoInput';

export interface IField {
	project?: boolean;
	label?: string;
	labelVariant?: 'bold' | 'light';
	buttonText?: string;
	type: string;
	className?: string;
	options?: any;
	key: string;
}

export interface IMultiLangField extends IField {
	isCommonField: boolean;
}

export const createInputs = (form: any, fields: IField[]): JSX.Element[] => {
	return fields.map((field) => (
		<div key={field.key}>{createInput(form, field)}</div>
	));
};

export const createInput = (form: any, field: IField): JSX.Element => {
	const props = {
		keyOfField: field.key,
		label: field.label,
		labelVariant: field.labelVariant,
		buttonText: field.buttonText,
		name: field.key,
		options: field.options,
		className: field.className,
		value: form.values[field.key],
		type: field.type,
		touched: form.touched[field.key],
		touchedCont: form.touched,
		setTouched: (key: string, value: any, validate?: boolean) => {
			// form.setTouched(key, true);
			setTimeout(() => form.setFieldTouched(key, true));
			// return form.setFieldValue(key, value, validate);
		},
		error: form.errors[field.key],
		onChange: form.handleChange,
		onBlur: form.handleBlur,
		setField: (key: string, value: any, validate?: boolean) => {
			// setTimeout(() => form.setFieldTouched(key, true));
			return form.setFieldValue(key, value, validate);
		},
		setError: form.setFieldError,
		project: field?.project,
		initialValue: form.initialValues,
	};

	switch (field.type) {
		case 'datepicker':
			return <Datepicker {...props} />;
		case 'editor':
			return <Editor {...props} />;
		case 'file':
			return <FileInput {...props} />;
		case 'slider':
			return <InputSlider {...props} />;
		case 'switch':
			return <SwitcherInput {...props} />;
		case 'table':
			return <TableInput {...props} />;
		case 'select':
			return <SelectInput {...props} />;
		case 'image':
			return <ImageInput {...props} />;
		case 'geo':
			return <GeoInput {...props} />;
		default:
			return <Input key={props.keyOfField} {...props} />;
	}
};

export default createInput;
