import React from 'react';

interface propsInputError {
	error?: string;
	className?: string;
	condition: boolean;
}

const InputError = ({ error, className, condition }: propsInputError) => {
	return (
		<>
			{
				condition && (
					<span className={`input-error ${className}`}>
						{error}
					</span>
				)
			}
		</>
	);
};

export default InputError;
