import React from 'react';
import { TVacancy } from '../types';
import { IListItem } from '../../interfaces/IListItem';
import VacancyPreview from './vacancyPreview';

type TVacancyListItem = TVacancy & IListItem<TVacancy>;

const VacancyListItem = (props: TVacancyListItem): JSX.Element => {
	const {
		onDeleteItem,
		title,
		id,
		slug,
		vacancy
	} = props;

	return (
		<VacancyPreview
			id={id}
			title={title}
			onDeleteItem={onDeleteItem}
			slug={slug}
			vacancyId={vacancy?.id as string}
		/>
	);
};

export default VacancyListItem;
