import React, { useEffect, useRef, useState } from 'react';
import { TCreateProjectForm } from '../types';
import { useFormik } from 'formik';
import { defaultFormikConfig } from '../../components/form/config';
import { validationSchemaUpdate as validationSchema } from '../validationSchema';
import createInput from '../../helpers/createInput';
import Button from '../../components/button';
import { useSelector } from 'react-redux';
import {
	getDeletedSliderImages,
	getLanguage,
} from '../../store/common/selectors.common';
import { TMixinCategoryCountry } from '../../categoryCountyMixin/types';
import { projectService } from '../project.service';
import store from '../../store/store.main';
import { useNavigate } from 'react-router-dom';
import { PROJECT_ROUTE } from '../../consts/routes';
import { createPathToListRoute } from '../../helpers/createRoute';
import ShadowWrapper from '../../components/ShadowWrapper';
import FormItem from '../../components/FormItem';
import ButtonClose from '../../components/ButtonClose';
import {
	resetSliderDeleteImages,
	setForm,
} from '../../store/common/actions.common';
import { AnyMessageParams } from 'yup/lib/types';

type TProjectEditFormProps = TCreateProjectForm & {
	id: string;
	countries: TMixinCategoryCountry[];
	categories: TMixinCategoryCountry[];
};

const ProjectEditForm = (props: TProjectEditFormProps): JSX.Element => {
	const { id, categories, countries, reload, setReload, ...initialValues } =
		props;
	const navigate = useNavigate();
	const language = useSelector(getLanguage);
	let imageHelper = (mainImage: any, sliderImage: any) => {
		let resultArray;
		if (sliderImage?.length > 0 && mainImage?.length) {
			resultArray = [...mainImage, ...sliderImage];
			return resultArray;
		}
		if (sliderImage?.length > 0 && mainImage?.name) {
			resultArray = [mainImage, ...sliderImage];
			return resultArray;
		}
		if (sliderImage?.length > 0 && !mainImage?.length) {
			resultArray = [...sliderImage];
			return resultArray;
		}
		if (mainImage?.length) {
			resultArray = [...mainImage];
			return resultArray;
		}

		if (mainImage?.name) {
			resultArray = [mainImage];
			return resultArray;
		}
	};
	let tableName = `table_${language}`;
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [shouldRedirect, setShouldRedirect] = useState(false);

	const isNavigating = useRef(false);

	useEffect(() => {
		const handlePopState = (event: any) => {
			const confirmed = window.confirm(
				'Ви впевнені що хочете покинути сторінку?'
			);
			if (!confirmed) {
				// Push the current state back to the history stack
				window.history.pushState(
					null,
					null as any as string,
					window.location.href
				);
			} else {
				navigate('/project');
			}
		};

		// Add the event listener for popstate
		window.addEventListener('popstate', handlePopState);

		// Push the initial state to the history stack
		window.history.pushState(null, null as any as string, window.location.href);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [navigate]);

	useEffect(() => {
		const handleBeforeUnload = (event: any) => {
			event.preventDefault();
			event.returnValue = ''; // Сучасні браузери показують стандартне повідомлення
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const form = useFormik<TCreateProjectForm>({
		...defaultFormikConfig,
		initialValues: {
			...initialValues,
		},
		validationSchema,
		onSubmit: (results, { setErrors }) => {
			setIsDisabled(true);
			let imagesDelete = getDeletedSliderImages(store.getState());
			if (id) {
				projectService
					.edit(
						language,
						id,
						{
							slug: results.slug,
							title: results.title,
							body: results.content,
							isEnabled: '1',
							priority: '0',
							country: results.countryId,
							category: results.categoryId,
							images_to_remove: imagesDelete,
							latitude: results?.geo?.lat ? results?.geo?.lat : '',
							longitude: results?.geo?.lng ? results?.geo?.lng : '',
							table: results[tableName],
						},
						imageHelper(results.image, results.images)
					)
					.then(() => {
						if (shouldRedirect) {
							navigate(createPathToListRoute(PROJECT_ROUTE));
						} else {
							setReload(!reload);
						}
					})
					.catch((error) => {
						setErrors({
							...error.fields,
						});
					})
					.finally(() => {
						setIsDisabled(false);
						resetSliderDeleteImages();
					});
			} else {
				projectService
					.createTranslation(
						language,
						props?.projectId as unknown as string,
						{
							body: results?.content,
							slug: results?.slug,
							title: results?.title,
							isPinned: '0',
							isEnabled: '1',
							priority: '0',
						},
						imageHelper(results.image, results.images)
					)
					.then(() => {
						if (shouldRedirect) {
							navigate(createPathToListRoute(PROJECT_ROUTE));
						} else {
							setReload(!reload);
						}
					})
					.catch((error) => {
						setErrors({
							...error.fields,
						});
					})
					.finally(() => {
						setIsDisabled(false);
						resetSliderDeleteImages();
					});
			}
		},
	});
	useEffect(() => {
		store.dispatch(setForm(form));
		return () => {
			store.dispatch(setForm({}));
		};
	}, [form]);
	return (
		<ShadowWrapper variant="content" className="project-form__body">
			<ButtonClose align="right" onClick={() => navigate(-1)} />
			<div>
				<div className="project-form__item">
					{createInput(form, {
						key: 'title',
						type: 'text',
						label: 'Назва проекту*',
						labelVariant: 'bold',
					})}
					{createInput(form, {
						key: 'countryId',
						type: 'select',
						label: 'Країна*',
						labelVariant: 'bold',
						options: {
							options: countries.map((country) => ({
								label: country.name,
								value: country?.country?.id,
							})),
						},
					})}
				</div>
			</div>
			<div className="project-form__category">
				{createInput(form, {
					key: 'categoryId',
					type: 'select',
					label: 'Категорії проекту*',
					labelVariant: 'bold',
					options: {
						options: categories.map((category) => ({
							label: category.name,
							value: category?.category?.id,
						})),
					},
				})}
			</div>
			<FormItem>
				{createInput(form, {
					key: 'image',
					label: 'Головне фото проекту*',
					labelVariant: 'bold',
					type: 'image',
					project: true,
				})}
			</FormItem>
			<FormItem className="project-form__image">
				{createInput(form, {
					key: 'images',
					label: 'Фото проекту у слайдері*',
					labelVariant: 'bold',
					type: 'slider',
					project: true,
				})}
			</FormItem>
			{createInput(form, {
				key: 'content',
				label: 'Опис проекту*',
				labelVariant: 'bold',
				type: 'editor',
			})}
			{createInput(form, {
				key: 'slug',
				type: 'text',
				label: 'slug*',
				labelVariant: 'bold',
			})}
			<FormItem className="project-form__table-item">
				{createInput(form, {
					key: 'switcher_' + language,
					label: 'Таблиця',
					labelVariant: 'bold',
					type: 'switch',
				})}
			</FormItem>
			{/* @ts-ignore*/}
			{form.values[`switcher_${language}`] &&
				createInput(form, {
					key: 'table_' + language,
					label: 'Занесення даних до таблиці',
					type: 'table',
				})}

			{language === 'ua' && (
				<>
					<FormItem className="project-form__table-item">
						{createInput(form, {
							key: 'switcherGeo',
							label: 'Геолокація',
							labelVariant: 'bold',
							type: 'switch',
						})}
					</FormItem>
					<FormItem>
						{form.values.switcherGeo &&
							createInput(form, {
								key: 'geo',
								label: 'Геолокація',
								labelVariant: 'bold',
								type: 'geo',
							})}
					</FormItem>
				</>
			)}
			<div className="project-form__footer">
				<Button
					isDisabled={isDisabled}
					onClick={() => {
						setShouldRedirect(true);
						form.handleSubmit();
					}}
				>
					Зберегти
				</Button>
			</div>
		</ShadowWrapper>
	);
};

export default ProjectEditForm;
