import React from 'react';
import { LOGIN_ROUTE } from '../consts/routes';

interface propsLogout {
	className?: string,
}

const Logout = ({ className }: propsLogout) => {

	const handleLogout = () => {
		localStorage.clear();
		window.location.href = LOGIN_ROUTE;
	};

	return (
		<button
			type="button"
			title="вийти"
			onClick={() => handleLogout()}
			className={`${className} logout`}
		/>
	);
};

export default Logout;
