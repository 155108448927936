import React, {useEffect, useState} from 'react';
import {TMixinCategoryCountry} from '../categoryCountyMixin/types';
import {Service} from '../categoryCountyMixin/service';
import {RECORDS_PER_PAGE} from '../consts/common';
import { getLanguage } from '../store/common/selectors.common';
import { useSelector } from 'react-redux';

export const useCategoryCountry = () => {
	const [countries, setCountries] = useState<TMixinCategoryCountry[]>([]);
	const [categories, setCategories] = useState<TMixinCategoryCountry[]>([]);
	const [promise, setPromise] = useState<Promise<any> | null>();
	const language = useSelector(getLanguage);

	useEffect(() => {
		const categoryService = new Service('category');

		const countriesService = new Service('country');
		setPromise(Promise.all([
			categoryService.getAll(language, {
				page: 1,
				per_page: 100,
			}, true).then((data) => {
				setCategories(data.entities);
			}),
			countriesService.getAll(language, {
				page: 1,
				per_page: 100,
			}, true).then((data) => {
				setCountries(data.entities);
			}),
		]));

	}, [language]);

	return {
		promise,
		countries,
		categories,
	};
};
