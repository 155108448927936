import React, { useState, ReactNode } from 'react';
import Loader from '../loader';
import { isPromise } from '../../helpers/typeChecks';

interface propsButton {
	onClick: () => any;
	className?: string;
	children: ReactNode;
	disabled?: boolean;
	isDisabled?: boolean;
	length?: 'full-length';
}

const Button = ({
	onClick,
	className,
	children,
	disabled,
	isDisabled,
	length,
}: propsButton) => {
	const [promise, setPromise] = useState<boolean | Promise<any>>(false);

	return (
		<button
			type="button"
			onClick={() => {
				const result = onClick();
				if (isPromise(result)) {
					setPromise(result);
				}
			}}
			className={`button button--${length} ${className}`}
			disabled={isDisabled}
		>
			{children}
		</button>
	);
};

export default Button;
