import {IStorage} from '../interfaces/IStorage';

class LocalStorage implements IStorage {
	delete(key: string): void {
		localStorage.removeItem(key);
	}

	get(key: string): string {
		return localStorage.getItem(key) || '';
	}

	set(key: string, value: string): void {
		localStorage.setItem(key, value);
	}
}

export const localStore = new LocalStorage();
