import React from 'react';
import {useSelector} from 'react-redux';
import {TRouteProps} from '../requiredAuth';
import {getAccessToken} from '../../store/user/user.selectors';
import {DOCUMENT_ROUTE} from '../../consts/routes';
import {Navigate} from 'react-router-dom';



const RequireNonAuth = (props: TRouteProps): JSX.Element => {
	const token = useSelector(getAccessToken);
	if (token) {
		return <Navigate to={DOCUMENT_ROUTE} />;
	}

	return props.children;
};

export default RequireNonAuth;
