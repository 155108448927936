import React from 'react';
import { IFormFieldProps } from '../../interfaces/IFormFieldProps';
import InputLabel from '../inputLabel';
import InputError from '../inputError';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormItem from '../FormItem';
import './index.scss';

const Datepicker = (props: IFormFieldProps<undefined>): JSX.Element => {
	const {
		value,
		setField,
		name,
		error,
		label,
		labelVariant,
		touched
	} = props;
	return (
		<FormItem>
			<div>
				{label &&
					<InputLabel labelVariant={labelVariant} label={label} />
				}
				<DatePicker
					selected={value}
					dateFormat="dd/MM/yyyy"
					onChange={(value) => {
						setField(name, value, true)
					}
						}
					popperPlacement={'bottom-end'}
					onChangeRaw={e => e.preventDefault()}
				/>
			</div>
			{error && touched &&
				<InputError error={error} condition={!!(error || touched)} />
			}
		</FormItem>
	);
};

export default Datepicker;
