import {TDate} from '../interfaces/TDate';

export class DateEntity {
	private readonly _date: Date;
	private readonly timezone: string;
	private readonly timezoneType: number;
	constructor(date?: TDate | Date) {
		if (!date) {
			this._date = new Date();
			this.timezone = '';
			this.timezoneType = -1;
		}

		if (date instanceof Date) {
			this._date = date;
			this.timezone = '';
			this.timezoneType = -1;
		} else {
			this._date = date?.date ? DateEntity.convertUtcToLocale(date.date) : new Date();
			this.timezone = date?.timezone || '';
			this.timezoneType = date?.timezone_type || -1;
		}
	}

	get date() {
		return this._date;
	}

	get toString() {
		return `${this.day}/${this.month}/${this.year} ${this.time}`;
	}

	get day() {
		const day = this.date.getDate();
		return DateEntity.addZeroToNumber(day);
	}

	get time() {
		const hours = this.date.getHours();
		const minutes = this.date.getMinutes();
		return `${DateEntity.addZeroToNumber(hours)}:${DateEntity.addZeroToNumber(minutes)}`;
	}

	get month() {
		const month = this.date.getMonth() + 1;
		return DateEntity.addZeroToNumber(month);
	}

	get year() {
		return this.date.getFullYear();
	}

	get dateInMilliseconds() {
		return this.date.getTime();
	}

	static addZeroToNumber(number: number): string {
		return (number > 9 ? number : '0' + number).toString();
	}

	static convertUtcToLocale(date: string) {
		return new Date(date.split('.')[0].replaceAll('-', '/')  + ' UTC');
	}
}
