import {ApiService} from '../services/ApiService';
import {getLanguage, getSiteId} from '../store/common/selectors.common';
import store from '../store/store.main';
import {HttpMethods} from '../enums/HttpMethods';
import {TCreateVacancy, TEditVacancy} from './types';
import {addObjectToFormData} from '../helpers/formData/FormData';
import {TPagination} from '../interfaces/TPagination';
import {createQueryParams} from '../helpers/createQueryParams';
import { workspace } from '../consts/workspace';

export class VacancyRepository {
	private readonly routeName: string = 'vacancy';
	private readonly apiService: ApiService;
	workspace: string;
	constructor() {
		this.apiService = new ApiService();
		this.workspace =  workspace[getSiteId(store.getState()) - 1]
	}

	async create(language:string, data: TCreateVacancy) {
		const formData = addObjectToFormData(data);
		const q = await this.apiService.request(`/admin/vacancy/create`, {
			method: HttpMethods.POST,
			data: formData,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});
		return q
	}

	async createTranslation(language:string, id: string, data: TCreateVacancy) {
		const formData = addObjectToFormData(data);
		const q = await this.apiService.request(`/admin/vacancy/${id}/translation/create`, {
			method: HttpMethods.POST,
			data: formData,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});
		return q
	}

	async getById(id: string) {
		const response = await this.apiService.request(`/admin/vacancy/translation/${id}/get`, {
			method: HttpMethods.GET,
			headers: {
				'X-Workspace': `${this.workspace}`,
			}
		});

		return response.data;
	}

	async delete(id: string) {
		await this.apiService.request(`/admin/vacancy/${id}/delete`, {
			method: HttpMethods.DELETE,
			headers: {
				'X-Workspace': `${this.workspace}`
			}
		});
	}

	async edit(id: number, data: TEditVacancy) {
		const formData = addObjectToFormData(data);
		const language = getLanguage(store.getState());

		await this.apiService.request(`/admin/vacancy/translation/${id}/update`, {
			method: HttpMethods.POST,
			data: formData,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});
	}

	async getAll(language='ua',pagination: TPagination) {
		const response = await this.apiService.request(`/admin/vacancy/all` + createQueryParams(pagination), {
			method: HttpMethods.GET,
			headers: {
				'X-Workspace': `${this.workspace}`,
				'Accept-Language': `${language}`,
			}
		});

		return response.data;
	}
}
