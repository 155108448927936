import React, { FC } from 'react';

interface propsEyeIcon {
	className?: string,
	onClick: () => void,
	show: boolean,
}

const EyeIcon = ({ onClick, className, show }: propsEyeIcon) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={className}
		>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M23.8475 11.5331C23.6331 11.2398 18.5245 4.35156 11.9999 4.35156C5.47529 4.35156 0.366469 11.2398 0.152297 11.5328C-0.0507657 11.8111 -0.0507657 12.1885 0.152297 12.4667C0.366469 12.76 5.47529 19.6483 11.9999 19.6483C18.5245 19.6483 23.6331 12.76 23.8475 12.467C24.0508 12.1888 24.0508 11.8111 23.8475 11.5331ZM11.9999 18.0659C7.19383 18.0659 3.03127 13.494 1.79907 11.9994C3.02968 10.5035 7.18351 5.93397 11.9999 5.93397C16.8057 5.93397 20.968 10.505 22.2007 12.0005C20.9701 13.4963 16.8163 18.0659 11.9999 18.0659Z" fill="#007BFF" />
				<path d="M11.9997 7.25293C9.38211 7.25293 7.25244 9.38261 7.25244 12.0002C7.25244 14.6178 9.38211 16.7475 11.9997 16.7475C14.6173 16.7475 16.747 14.6178 16.747 12.0002C16.747 9.38261 14.6173 7.25293 11.9997 7.25293ZM11.9997 15.165C10.2546 15.165 8.8349 13.7453 8.8349 12.0002C8.8349 10.2551 10.2546 8.83539 11.9997 8.83539C13.7448 8.83539 15.1645 10.2551 15.1645 12.0002C15.1645 13.7453 13.7449 15.165 11.9997 15.165Z" fill="#007BFF" />
				{!show && (
					<path d="M4.41406 4L19.9998 19.5858" stroke="#007BFF" strokeWidth="1.5" strokeLinecap="round" />
				)}
			</svg>
		</button>
	);
};

export default EyeIcon;
