import { authRepository, AuthRepository } from './auth.repository';
import store from '../store/store.main';
import { setAccessToken, setRefreshToken } from '../store/user/user.actions';
import { setToast } from '../helpers/setToast';
import { DateEntity } from '../entities/Date';
import { getDefaultExpireTime } from '../helpers/getDefaultExpireTime';

export class AuthService {
	private readonly authRepository: AuthRepository;
	constructor() {
		this.authRepository = authRepository;
	}

	async login(email: string, password: string) {
		const response = await this.authRepository
			.login(email, password)
			.catch((error) => {
				setToast(error?.message, 'error');
				throw error;
			});
		store.dispatch(setAccessToken(response.token));
		store.dispatch(setRefreshToken(response.refresh_token));
		setToast('Залогінено', 'info');
	}

	async sendResetPasswordCode(email: string) {
		const response = await this.authRepository
			.sendResetPasswordCode(email)
			.catch((error) => {
				setToast(error.message, 'error');
				throw error;
			});
		setToast('Код відправлено на вказану пошту', 'info');
		return {
			message: response.message,
			date: response.codeExpires
				? new DateEntity(response.codeExpires)
				: getDefaultExpireTime(),
		};
	}

	async resetPassword(
		email: string,
		code: string,
		password: string,
		repeatPassword: string
	) {
		await this.authRepository
			.resetPasswordCode(email, code, password, repeatPassword)
			.catch((error) => {
				setToast(error.message, 'error');
				throw error;
			});
		setToast('Пароль успішно відновлено', 'info');
	}
}

export const authService = new AuthService();
