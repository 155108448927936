import React from 'react';
import { NavLink } from 'react-router-dom';
import navigations from '../helpers/navigations';
import { getSiteNavigation } from '../helpers/navigations';
import { getSiteId } from '../store/common/selectors.common';
import store from '../store/store.main';

interface propsNavigation {
	className?: string;
}

const Navigation = ({ className }: propsNavigation) => {
	const siteId = getSiteId(store.getState());

	return (
		<nav className={`${className} navigation`}>
			{getSiteNavigation(siteId).map((navigation, index) => (
				<NavLink
					key={index}
					to={navigation.path}
					className="navigation__button"
				>
					{navigation.title}
				</NavLink>
			))}
		</nav>
	);
};

export default Navigation;
