import React from 'react';

interface propsDocumentIcon {
	className?: string,
}

const DocumentIcon = ({ className }: propsDocumentIcon): JSX.Element => {
	return (
		<svg className={className} width="46" height="57" viewBox="0 0 46 57" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.351562" y="7.49609" width="37.0082" height="48.6529" stroke="black" strokeWidth="0.703125" />
			<path d="M4.75391 51.7543V0.5H31.6379L45.0004 13.9964V51.7543H4.75391Z" fill="white" />
			<path d="M45.0004 13.9964V51.7543H4.75391V0.5H31.6379M45.0004 13.9964L31.6379 0.5M45.0004 13.9964H31.6379V0.5" stroke="black" strokeWidth="0.703125" />
			<path d="M16.4785 19.168H39.7708" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M10.1406 19.168H13.9434" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M16.4785 25.4941H39.7708" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M10.1406 25.4941H13.9434" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M16.4785 31.8223H39.7708" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M10.1406 31.8223H13.9434" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M16.4785 38.1504H39.7708" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M10.1406 38.1504H13.9434" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M16.4785 44.4785H39.7708" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M10.1406 44.4785H13.9434" stroke="black" strokeWidth="0.703125" strokeLinecap="round" />
			<path d="M10.1406 14.1051V8.41016H15.8449V14.1051H10.1406Z" stroke="black" strokeWidth="0.703125" />
		</svg>

	);
};

export default DocumentIcon;
