import axios, { AxiosError, AxiosInstance } from 'axios';
import { convertApiError } from '../helpers/convertApiError';
import { getAccessToken } from '../store/user/user.selectors';
import store from '../store/store.main';
import { addObjectToFormData } from '../helpers/formData/FormData';
import { HttpMethods } from '../enums/HttpMethods';
import { setToast } from '../helpers/setToast';
import { UPLOAD_FILE_ERROR } from '../consts/errorMessages';
import { getLanguage, getSiteId } from '../store/common/selectors.common';
import { setAccessToken, setRefreshToken } from '../store/user/user.actions';
import { workspace } from '../consts/workspace';

export class ApiService {
	private readonly _request: AxiosInstance;
	language: string;
	workspace: string;
	constructor(language = 'ua') {
		this.language = language
		this.workspace =  workspace[getSiteId(store.getState()) - 1]
		this._request = axios.create({
			baseURL: `${process.env.REACT_APP_API_URL}/api`,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'Access-Control-Allow-Origin': '*'
			},
		});

		this._request.interceptors.request.use(
			(config) => {
				const token = getAccessToken(store.getState());

				if (!config.headers) {
					config.headers = {};
				}

				if (token) {
					config.headers['Authorization'] = `Bearer ${token}`;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);
		this._request.interceptors.response.use(
			(response) => {
				return response;
			},
			(error: AxiosError) => {

				if (error.response?.status == 401 || error.response?.status == 403) {
					store.dispatch(setRefreshToken(''));
					store.dispatch(setAccessToken(''));
				}
				const convertedError = convertApiError(error);
				return Promise.reject(convertedError);
			}
		);
	}

	get request() {
		return this._request;
	}

	async saveFile(file: File) {
		const formData = addObjectToFormData({
			file,
		});
		const response = await this.request('/upload-image', {
			method: HttpMethods.POST,
			data: formData,
		}).catch((error) => {
			setToast(error.message || UPLOAD_FILE_ERROR, 'error');
			throw error;
		});
		return response.data;
	}

	async linkDocument(file : any) {
		const siteId = getSiteId(store.getState());
		const language = getLanguage(store.getState());

		let formData = new FormData();
		formData.set('file', file);
		formData.set('name', file.name);
		formData.set('isEnabled', '1');	
		formData.set('type', 'organizational_structure');
		let data = {
			file: file!,
			name: file.name,
			isEnabled: 1, 
			type: 'organizational_structure'
		}
		await this.request(
			'/admin/document/create',
			{
				method: HttpMethods.POST,
				headers: {
					'X-Workspace': `${this.workspace}`,
					'Accept-Language': `ua`,
				},
				data: formData,
			}
		)
			.then(() => {
				setToast("Документ успішно прив'язаний", 'info');
			})
			.catch((error) => {
				setToast(error.message || 'Помилка при заванаженні документа', 'error');
				throw error;
			});
	}

	async getSites() {
		const response = await this.request('/sites/get', {
			method: HttpMethods.GET,
		}).catch((error) => {
			setToast(error.message, 'error');
			throw error;
		});
		return response.data;
	}
}

export const apiService = new ApiService();
