import { TLangConfig } from '../interfaces/TLang';
import { TTableEl } from '../components/tableInput';

export const PAGINATION_ITEMS_PER_VIEW = 4;
export const RECORDS_PER_PAGE = 10;
export const FIRST_PAGE_NUMBER = 1;
export const INITIAL_MAX_PAGE = 0;
export const LANG_CONFIG: TLangConfig[] = [
	{
		name: 'ua',
		label: 'УКР',
	},
	{
		name: 'ru',
		label: 'РУС',
	},
	{
		name: 'en',
		label: 'ENG',
	},
];

export const DEFAULT_PROJECT_TABLE: TTableEl[] = [
	{
		key: ' ',
		id: '1',
		value: ' ',
	},
];
