export function isUndefined(value: any) {
	return value === undefined;
}

export function isNull(value: any) {
	return value === null;
}

export function isNumber(value: any) {
	return typeof value === 'number';
}

export function isString(value: any) {
	return typeof value === 'string';
}

export function isBoolean(value: any) {
	return typeof value === 'boolean';
}

export function isObject(value: any) {
	return value === Object(value);
}

export function isArray(value: any) {
	return Array.isArray(value);
}

export function isDate(value: any) {
	return value instanceof Date;
}

export function isFile(value: any) {
	return value instanceof File;
}

export function isAsyncFunction(value: any) {
	return value?.constructor?.name === 'AsyncFunction';
}

export function isPromise(value: any) {
	return Boolean(value && typeof value.then === 'function');
}
